import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchEmployees = createAsyncThunk("employees/fetch", async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/employees`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const createEmployee = createAsyncThunk(
  "employees/create",
  async (employee) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/employees`, employee);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "employees/update",
  async (employee) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/employees/${employee.id}`,
        employee
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "employees/delete",
  async (employeeId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/employees/${employeeId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
