import { createSlice } from "@reduxjs/toolkit";
import {
  createMood,
  createPriority,
  deletePriority,
  fetchCheckinsHistory,
  fetchPriorities,
  fetchQuestions,
  fetchUserCheckinsHistory,
  updatePriority,
} from "./checkinsThunk";

const checkinsSlice = createSlice({
  name: "checkins",
  initialState: {
    data: [],
    priorities: [],
    questions: [],
    mood: null,
    // history: [],
    userHistory: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(createMood.fulfilled, (state, action) => {
      state.mood = action.payload;
    });
    builder.addCase(fetchPriorities.fulfilled, (state, action) => {
      state.priorities = action.payload;
    });
    builder.addCase(createPriority.fulfilled, (state, action) => {
      state.priorities = [...state.priorities, action?.payload];
    });
    builder.addCase(updatePriority.fulfilled, (state, action) => {
      const updatedPriority = state.priorities.map((priority) => {
        if (priority.id === action?.payload?.id) {
          return action?.payload;
        }
        return priority;
      });
      state.priorities = updatedPriority;
    });
    builder.addCase(deletePriority.fulfilled, (state, action) => {
      const updatedPriority = state.priorities.filter((priority) => {
        return priority?.id !== action.meta.arg;
      });
      state.priorities = updatedPriority;
    });
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      state.questions = action.payload;
    });
    // builder.addCase(fetchCheckinsHistory.fulfilled, (state, action) => {
    //   state.history = action.payload;
    // });
    builder.addCase(fetchUserCheckinsHistory.fulfilled, (state, action) => {
      state.userHistory = action.payload;
    });
  },
});

export const checkinsReducer = checkinsSlice.reducer;
