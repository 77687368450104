export const supportCaseTypes = [
  { value: 1, label: "Part time" },
  { value: 2, label: "2 Hours a day" },
  { value: 3, label: "10% of time" },
];

export const seniorityTypes = [
  { value: 1, label: "Junior" },
  { value: 2, label: "Medior" },
  { value: 3, label: "Senior" },
];

export const projectNotificationTypes = {
  levels: [
    { value: 1, label: "Task" },
    { value: 2, label: "Project" },
  ],
  times: [
    { value: 1, label: "After 1 day of the last update" },
    { value: 2, label: "After 3 days of the last update" },
    { value: 3, label: "Weekly" },
  ],
  types: [
    { value: 1, label: "Email + App notification" },
    { value: 2, label: "App notification" },
    { value: 3, label: "Turn off the notifications" },
  ],
};
