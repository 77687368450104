import { Table } from "../ui";

const EmployeeList = ({ employees }) => {
  const tableData = {
    rows: employees,
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
      {
        id: 3,
        label: "Position",
        slug: "position",
      },
      {
        id: 4,
        label: "Superiors",
        slug: "manager",
      },
    ],
  };
  return (
    <Table
      rows={tableData?.rows}
      columns={tableData?.columns}
      hasActions={true}
      isAdmin={false}
    />
  );
};

export default EmployeeList;
