import "./styles.scss";

const ProgressBar = ({ progress }) => {
  const progressColor = () => {
    if (progress < 30) {
      return "red";
    }
    if (progress >= 30 && progress < 70) {
      return "orange";
    }
    return "green";
  };

  return (
    <div className="progress-wrapper">
      <div className="progress-bar">
        <span
          className={`progress-scale ${progressColor()}`}
          style={{ width: `${progress ? progress : 0}%` }}
        ></span>
      </div>
      <p>{progress ? progress : 0} %</p>
    </div>
  );
};

export default ProgressBar;
