import { useEffect, useState } from "react";

import { CloseIcon } from "../../../../icons";

import { statuses } from "./options";

import moment from "moment/moment";

import { Button, Input } from "../../../ui";

import {
  fetchUsers,
  fetchTaskFeedbacks,
  createAnswerFeedback,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";

import "./feedback.scss";

const FeedbackDetails = ({ selectedFeedback, handleActiveView }) => {
  const [feedback, setFeedback] = useState("");
  const [doCreateAnswerFeedback] = useThunk(createAnswerFeedback);

  const handleChange = (event) => {
    const { value } = event.target ? event.target : event;
    setFeedback(value);
  };

  const saveFeedback = () => {
    if (!feedback) {
      return;
    }
    doCreateAnswerFeedback({
      feedbackId: selectedFeedback?.id,
      data: { answer: feedback },
    });
    setFeedback("");
  };

  return (
    <div className="career-wrapper">
      <div className="career-header feedback-form-header">
        <div className="header-title">
          <p onClick={() => handleActiveView("feedback-list")}>Feedbacks</p>
          <h2>
            Client Feedback Request
            <div className="status-wrapper status-details">
              <span className="status blue">Pending</span>
            </div>
          </h2>
        </div>
      </div>
      <div className="carrer-feedback-grid">
        <div className="employee-section">
          <h3 className="details-item">Employee Details</h3>
          <div className="details-item flex-block">
            <label>Employee</label>
            <span>{selectedFeedback?.receiver?.name}</span>
          </div>
        </div>

        <div className="feedback-section">
          <div className="feedback-wrapper">
            <h3>Client Feedback</h3>
            <form>
              <div className="carrer-form-group">
                <Input
                  type="textarea"
                  name="content"
                  value={feedback}
                  placeholder="Enter text"
                  handleChange={handleChange}
                />
              </div>
            </form>
            <div className="feedback-action">
              <Button handleClick={saveFeedback}>Save</Button>
            </div>
          </div>
        </div>

        <div className="task-section">
          <div className="task-section-wrapper">
            <h3 className="details-item">Feedback Details</h3>
            <div className="details-item flex-block">
              <label>Feedback question</label>
              <p>{selectedFeedback?.question}</p>
            </div>
            <div className="details-item flex-block">
              <label>Created at</label>
              <p>{moment(selectedFeedback?.createdAt).format("DD-MM-YYYY")}</p>
            </div>
          </div>
        </div>

        {/* <div className="task-feedbacks-section">
          <div className="feedback-wrapper">
            <h3 className="details-item">Task Feedbacks</h3>
            {feedbacks?.map((feedback, index) => (
              <div className="details-item flex-block" key={index}>
                <label className="feedback-date-time">
                  {moment(feedback?.createdAt).format("DD/MM/YYYY")}
                  <span>{moment(feedback?.createdAt).format("HH:mm")}</span>
                </label>
                <div className="feedback-content">
                  <p>{feedback?.content}</p>
                  <div className="feedback-user">
                    <img
                      src={`${process.env.REACT_APP_BASE_API_URL}/images/${
                        getUserData(feedback?.userId)?.image
                      }`}
                      alt="user"
                    />
                    <p>
                      {`${getUserData(feedback?.userId)?.first_name} ${
                        getUserData(feedback?.userId)?.last_name
                      }`}
                      <span>{getUserData(1)?.position}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FeedbackDetails;
