import React from "react";
import "./styles.scss";

const Button = ({
  children,
  variant = "",
  buttonStyles = {},
  disabled = false,
  className = "",
  handleClick,
}) => {
  return (
    <button
      className={`${variant} custom-button ${className}`}
      style={buttonStyles}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
