import React, { useEffect, useRef } from "react";
import { useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import "./ChatInput.css";
import db from "../../../firebase";
import { useStateValue } from "../../../StateProvider";
import { serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import usePushNotifications from "../../../usePushNotifications";
import http from "../../../utils/http";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import createImagePlugin from "@draft-js-plugins/image";
import SendIcon from "../../../icons/SendIcon";

function ChatInput({ channelName, channelId }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const messageRef = useRef();
  const defaultValues = {
    someText: "",
    someDraft: EditorState.createEmpty(),
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });
  const imagePlugin = createImagePlugin();
  // const {
  //   userConsent,
  //   pushNotificationSupported,
  //   userSubscription,
  //   onClickAskUserPermission,
  //   onClickSusbribeToPushNotification,
  //   onClickSendSubscriptionToPushServer,
  //   pushServerSubscriptionId,
  //   onClickSendNotification,
  //   error,
  //   loading
  // } = usePushNotifications();
  const [{ subscription }] = useStateValue();
  const [input, setInput] = useState("");
  const [{ user }] = useStateValue();
  const history = useNavigate();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const onSubmit = ({ someText, someDraft }) => {
    const _someDraft = draftToHtml(convertToRaw(someDraft.getCurrentContent()));
    sendMessage(_someDraft);
  };

  const onError = (data) => console.log("err: ", data);

  const sendMessage = (_someDraft) => {
    let userImage = "";
    if (localUserData.image) {
      userImage = localUserData.image;
    }
    if (!_someDraft) return false;

    if (channelId) {
      //console.log(user);
      let privateM = false;
      let notify = "all";
      let room_id = channelId;
      if (channelId.includes(user.uid)) {
        privateM = true;
        notify = channelId.replace(user.uid, "");
        room_id = user.uid;
      }

      //console.log(privateM);
      //console.log(userImage);
      db.collection("rooms")
        .doc(channelId)
        .collection("messages")
        .add({
          message: _someDraft,
          timestamp: serverTimestamp(),
          user: user.displayName
            ? user.displayName
            : user.first_name + " " + user.last_name,
          userId: user.uid,
          userImage: userImage,
          channelId: channelId,
          private_message: privateM,
          notify: notify,
        });
      const formData = new FormData();
      formData.append("new_message", 1);
      formData.append("chat_uuid", channelId);
      formData.append("user_id", user?.id);
      formData.append("user_name", user?.first_name + " " + user?.last_name);
      formData.append("receiver", notify);
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      fetch("https://api.ugofy.com/chat/new/message", requestOptions);
    }

    reset(defaultValues);
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };
  const ifEnterPressed = ({ callback, event }) => {
    if (event.shiftKey !== true && event.keyCode === 13) {
      callback(event);
    }
  };

  return (
    <div className="chatInput">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Controller
          name="someText"
          control={control}
          render={({ field }) => {
            return (
              <input
                {...field}
                onChange={(e) => {
                  const params = e.target.value.toUpperCase();
                  field.onChange(params);
                }}
              />
            );
          }}
        />

        <Controller
          name="someDraft"
          control={control}
          render={({ field }) => {
            return (
              <Editor
                editorState={field.value}
                wrapperClassName="editor-wrapper"
                editorClassName="message-editor"
                toolbarClassName="message-toolbar"
                plugins={[imagePlugin]}
                toolbar={{
                  //options: ["inline", "fontSize", "image", "emoji"],
                  options: ["inline", "image", "emoji"],
                  image: {
                    previewImage: true,
                    uploadCallback: (file) => {
                      return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          const dataURL = reader.result;
                          const truncatedDataURL =
                            dataURL.substring(10, 30) + "..."; // set the maximum length of the truncated string
                          resolve({
                            data: { link: dataURL },
                            link: { url: truncatedDataURL },
                          });
                        };
                        reader.onerror = (error) => {
                          reject(error);
                        };
                      });
                    },
                    alt: { present: true, mandatory: false },
                  },
                }}
                onEditorStateChange={field.onChange}
                handleReturn={(e) =>
                  ifEnterPressed({
                    callback: handleSubmit(onSubmit, onError),
                    event: e,
                  })
                }
              />
            );
          }}
        />

        <button type="submit">
          <SendIcon />
        </button>
      </form>
    </div>
  );
}

export default ChatInput;
