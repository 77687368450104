const CopyIcon = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V18.2857H16V9.14286H6.85714V0H0ZM9.14286 0V6.85714H16L9.14286 0Z"
        fill="#5F6368"
      />
    </svg>
  );
};

export default CopyIcon;
