import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchCareer = createAsyncThunk("career/fetch", async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/employee-tree`);
    return response.data;
  } catch (error) {
    throw error;
  }
});
