import React from "react";
import "./styles.scss";

const CardFilter = ({
  title = "",
  icon = null,
  count = 0,
  backgroundColor = "",
  borderColor = "",
  textColor = "#fff",
  customClass = "",
  variant = "",
  onClick = () => {},
}) => {
  return (
    <div
      className={`card-filter ${variant} ${customClass}`}
      style={{ backgroundColor, borderColor, color: textColor }}
      onClick={(e) => onClick(e)}
    >
      <p className="title" style={{ color: textColor }}>
        {title}
      </p>

      <div className="card-bottom">
        <div className="icon-wrapper">{icon}</div>
        <span className="count">{count}</span>
      </div>
    </div>
  );
};

export default CardFilter;
