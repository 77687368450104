import { useState, useEffect } from "react";
import { Modal, Input } from "../../../ui";
import SelectList from "../../../SelectList/SelectList";

import { CloseIcon, PlusIcon, CalendarIcon } from "../../../../icons";

import { createTask, updateTask } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

import { useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

import "./styles.scss";

const ProjectTaskForm = ({
  handleCloseTaskModal,
  taskFormType,
  showTaskModal,
  projectPrivacy = 1,
  selectedUsers = [],
}) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const [taskData, setTaskData] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [existingAttachments, setExistingAttachments] = useState([]);

  const [doCreateTask] = useThunk(createTask);
  const [doUpdateTask] = useThunk(updateTask);

  const singleTaskState = useSelector((state) => state.tasks)?.singleTask;

  useEffect(() => {
    if (!!singleTaskState) {
      if (taskFormType === "edit") {
        setTaskData({
          ...taskData,
          id: singleTaskState.id,
          title: singleTaskState.title,
          description: singleTaskState.description,
          type: singleTaskState.type,
          privacy: singleTaskState.privacy,
          results: singleTaskState.results,
          assets: singleTaskState.assets,
          resources: singleTaskState.resources,
          notes: singleTaskState.notes,
          startDate: singleTaskState?.settings?.startDate || "",
          endDate: singleTaskState?.settings?.endDate || "",
          userId: singleTaskState?.user || "",
        });
        if (Array.isArray(singleTaskState.resources)) {
          setExistingAttachments([...singleTaskState.resources]);
        }
      } else {
        setTaskData(null);
      }
    }
  }, [singleTaskState, taskFormType, selectedUsers]);

  const handleChange = (event, componentName) => {
    if (componentName === "userId") {
      setTaskData({ ...taskData, [componentName]: event?.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setTaskData({ ...taskData, [name]: value });
    return;
  };

  const saveTask = () => {
    if (taskFormType === "edit") {
      doUpdateTask({
        ...taskData,
        type: 2,
        privacy: projectPrivacy,
        settings: {
          startDate: taskData?.startDate,
          endDate: taskData?.endDate,
        },
      });
    } else {
      doCreateTask({
        ...taskData,
        type: 2,
        privacy: projectPrivacy,
      });
    }
    handleCloseTaskModal();
  };

  const createAttachment = () => {
    const formData = new FormData();
    formData.append("resource", attachment[0]);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/create`,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      setExistingAttachments([...existingAttachments, data.data]);
    });
  };

  const changeHandler = (event) => {
    setAttachment(event.target.files);
  };

  useEffect(() => {
    if (!attachment.length) {
      return;
    }
    createAttachment();
  }, [attachment]);

  useEffect(() => {
    const fileCollection = existingAttachments.map((file) => file?.id);
    setTaskData({ ...taskData, resources: fileCollection });
  }, [existingAttachments]);

  const handleRemoveFile = (id) => {
    const requestOptions = {
      method: "DELETE",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/${id}`,
      requestOptions
    );
    const reducedFiles = existingAttachments.filter((file) => file?.id !== id);
    setExistingAttachments(reducedFiles);
  };
  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const handleStartDate = (date) => {
    setTaskData({ ...taskData, startDate: moment(date).format("DD.MM.yyyy") });
  };

  const handleEndDate = (date) => {
    setTaskData({ ...taskData, endDate: moment(date).format("DD.MM.yyyy") });
  };

  return (
    <Modal
      title="New task"
      show={showTaskModal}
      handleClose={handleCloseTaskModal}
      confirmButtonLabel="Submit"
      handleConfirm={saveTask}
    >
      <Input
        label="Task Title"
        placeholder="Enter name"
        name="title"
        value={taskData?.title || ""}
        handleChange={handleChange}
      />
      <Input
        label="Task Description"
        placeholder="Enter description"
        name="description"
        value={taskData?.description || ""}
        handleChange={handleChange}
      />
      <div className="form-group">
        <label>User</label>
        <Select
          name="userId"
          placeholder="Select user"
          options={selectedUsers}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(event) => handleChange(event, "userId")}
          value={selectedUsers.find((item) => item?.value === taskData?.userId)}
        />
      </div>
      <div className="input-holder">
        <div className="date-picker-holder career-form-group">
          <label>Task Start</label>
          <DatePicker
            name="startDate"
            onChange={handleStartDate}
            dateFormat="dd.MM.yyyy"
            dateFormatCalendar={"MMM yyyy"}
            placeholderText={"Enter start date"}
            minDate={subMonths(new Date(), 256)}
            maxDate={addMonths(new Date(), 256)}
            value={taskData?.startDate}
            show
            showMonthDropdown
            showYearDropdown
          />
          <CalendarIcon />
        </div>
        <div className="date-picker-holder career-form-group">
          <label>Task End</label>
          <DatePicker
            name="endDate"
            onChange={handleEndDate}
            dateFormat="dd.MM.yyyy"
            dateFormatCalendar={"MMM yyyy"}
            placeholderText="Enter end date"
            minDate={subMonths(new Date(), 256)}
            maxDate={addMonths(new Date(), 256)}
            value={taskData?.endDate}
            showMonthDropdown
            showYearDropdown
          />

          <CalendarIcon />
        </div>
      </div>
      <SelectList
        label="What is your key result?"
        addLabel="Add another key result"
        placeholder="Enter text"
        name="results"
        onChange={handleChange}
        preselected={
          taskFormType === "edit" ? singleTaskState?.results || [] : []
        }
      />

      <SelectList
        label="Assets"
        addLabel="Add another asset"
        placeholder="Enter link"
        name="assets"
        onChange={handleChange}
        preselected={
          taskFormType === "edit" ? singleTaskState?.assets || [] : []
        }
      />
      <label>Resources</label>
      {!!existingAttachments.length && (
        <ul className="selected-files">
          {existingAttachments.map((file, index) => (
            <li key={index}>
              <a
                href={`${process.env.REACT_APP_BASE_API_URL}${file?.path}`}
                target="_blank"
                rel="noreferrer"
                download
              >
                {file?.title}
              </a>

              <span onClick={() => handleRemoveFile(file?.id)}>
                <CloseIcon />
              </span>
            </li>
          ))}
        </ul>
      )}
      <div className="file-select">
        {!existingAttachments.length && (
          <div className="file-select-empty">
            Add resource (max flie size 20MB)
          </div>
        )}
        <label>
          <PlusIcon />
          Add another resource
        </label>
        <input type="file" name="image" multiple onChange={changeHandler} />
      </div>

      <Input
        label="Notes"
        placeholder="Enter notes"
        name="notes"
        value={taskData?.notes || ""}
        handleChange={handleChange}
      />
    </Modal>
  );
};

export default ProjectTaskForm;
