export const dummyConferences = [
  /* 
  types: 
      1 - Online
      2 - In Peerson

  attendingReason:
      1 - Attendant
      2 - Speaker

  meetingTypes: 
    1 - One on one
    2 - something else
   */
  {
    title: "Local Conference",
    description: "Conference topic",
    type: 1, //online
    date: "17.03.2024",
    attendingReason: 1, //attendant
  },
  {
    title: "International conference",
    description: "Conference topic",
    type: 2, //in person
    date: "19.05.2024",
    attendingReason: 1, //attendant
  },
  {
    title: "Speaker No. 1",
    description: "Conference topic",
    type: 1, //online
    date: "14.07.2024",
    attendingReason: 2, //speaker
  },
  {
    title: "Speaker No. 1",
    description: "Conference topic",
    type: 2,
    date: "17.03.2024",
    attendingReason: 2,
  },
  {
    title: "Speaker No. 2",
    description: "Conference topic",
    type: 1,
    date: "19.06.2024",
    attendingReason: 2,
  },
  {
    title: "Speaker No. 3",
    description: "Conference topic",
    type: 2,
    date: "18.09.2024",
    attendingReason: 2,
  },
  {
    title: "Speaker No. 4",
    description: "Conference topic",
    type: 1,
    date: "17.03.2024",
    attendingReason: 2,
  },
  {
    title: "Speaker No. 1",
    description: "Conference topic",
    type: 2,
    date: "17.03.2024",
    attendingReason: 2,
  },
];

export const dummuMeetings = [
  {
    id: 1,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 1, //One on one
  },
  {
    id: 2,
    meetWidth: {
      userId: 168,
      name: "Matko Markovic",
      position: "Junior PHP Dev",
    },
    type: 2, // In person
    scheduled: "07.07.2024",
    meetingType: 1, //One on one
  },
  {
    id: 3,
    meetWidth: {
      userId: 118,
      name: "Aleksandar Vasic",
      position: "Medior React Dev",
    },
    type: 1, // online
    scheduled: "10.07.2024",
    meetingType: 1, //One on one
  },
  {
    id: 4,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 1, //One on one
  },
  {
    id: 5,
    meetWidth: {
      userId: 172,
      name: "Pavle Pavlovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 1, //One on one
  },
  {
    id: 6,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 2, //One on one
  },
  {
    id: 7,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 2, //One on one
  },
  {
    id: 8,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 2, //One on one
  },
];
