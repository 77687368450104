import { useEffect, useState, useRef } from "react";
import PerformanceTimeline from "./PerformanceTimeline";
import { Filter, Timeline, TimelineList } from "../../../ui";
import { performanceReviewStatuses } from "../../../../data/statuses";
import {
  dummyTimeline,
  dummyReviewTimelineList,
  dummyTimeOnPosition,
} from "./dummyData";
import "./styles.scss";

const PerformanceReview = ({ isExpanded }) => {
  const contentRef = useRef(null);

  const [height, setHeight] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  const handleFilterChange = (e) => {
    setSelectedFilter(parseInt(e.target.value));
  };

  const handleResetFilters = () => {
    setSelectedFilter(null);
  };

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view performance-review">
        <div className="timelines">
          <Timeline
            title="Performance review timeline"
            legendItems={performanceReviewStatuses}
            points={dummyTimeline}
          />
          <div className="side-timelines">
            <div className="reviews-list">
              <div className="reviews-list-header">
                <p className="reviews-list-title">
                  List of past and upcoming reviews
                </p>
                <Filter
                  filters={performanceReviewStatuses.map(
                    (status) => status.label
                  )}
                  checked={selectedFilter}
                  onRadioChange={handleFilterChange}
                  onReset={handleResetFilters}
                />
              </div>
              <TimelineList data={dummyReviewTimelineList} />
            </div>
            <hr />
            <div className="reviews-list">
              <div className="reviews-list-header">
                <p className="reviews-list-title">Time on each position</p>
              </div>
              <TimelineList data={dummyTimeOnPosition} />
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default PerformanceReview;
