import { Outlet } from "react-router-dom";
import "./styles.scss";

const Projects = () => {
  return (
    <div className="projects-wrapper scrollable-content">
      <Outlet />
    </div>
  );
};

export default Projects;
