import { useEffect, useState, useRef } from "react";
import InitiativeReview from "./InitiativeReview";
import CompensationReview from "./CompensationReview";

const InitiativeAndCompensation = ({ isExpanded }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view">
        <InitiativeReview />
        <hr />
        <CompensationReview />
      </div>
    </div>
  );
};

export default InitiativeAndCompensation;
