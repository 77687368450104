import { createSlice } from "@reduxjs/toolkit";
import { fetchTeams, createTeam, updateTeam, deleteTeam } from "./teamsThunks";

const teamsSlice = createSlice({
  name: "teams",
  initialState: {
    teams: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetTeamsMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      state.teams = action.payload;
    });
    builder.addCase(createTeam.fulfilled, (state, action) => {
      state.teams.teamData = [...state.teams.teamData, action.payload.data];
      state.message = {
        type: "success",
        title: "Success",
        message: "Team successfully created",
      };
    });
    builder.addCase(updateTeam.fulfilled, (state, action) => {
      const updatedState = state?.teams?.teamData?.map((team) => {
        return team.id === action.payload.data.id ? action.payload.data : team;
      });
      state.teams.teamData = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Team successfully updated",
      };
    });
    builder.addCase(deleteTeam.fulfilled, (state, action) => {
      const filteredTeams = state?.teams?.teamData?.filter(
        (team) => team.id !== action.meta.arg
      );
      state.teams.teamData = filteredTeams;
      state.message = {
        type: "success",
        title: "Success",
        message: "Team successfully deleted",
      };
    });
  },
});
export const { resetTeamsMessage } = teamsSlice.actions;

export const teamsReducer = teamsSlice.reducer;
