const FatArrow = ({ fill = "#14B4BA", style }) => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M4.03806 0.622844L6.26967 2.85445L0.231611 8.89251L2.46322 11.1241L8.50128 5.08607L10.7329 7.31768L10.9748 0.313949L4.03806 0.622844Z"
        fill={fill}
      />
    </svg>
  );
};

export default FatArrow;
