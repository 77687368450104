import { createSlice } from "@reduxjs/toolkit";

const localActiveView = JSON.parse(localStorage.getItem("activePageView"));
let localUserData = JSON.parse(localStorage.getItem("authUserData"));
const pageViewsSlice = createSlice({
  name: "pageViews",
  initialState: {
    activeView: {
      page: localActiveView?.page || "Your-career",
      view: localActiveView?.view || "vision",
      subview: "",
      isAdmin: localUserData?.role === 1,
    },
    activeModal: {
      modal: "",
      data: null,
    },
    data: null,
    searchTerm: "",
  },
  reducers: {
    setActiveView: (state, action) => {
      state.activeView = action.payload;
      localStorage.setItem("activePageView", JSON.stringify(action.payload));
    },
    setActiveModal: (state, action) => {
      state.activeModal = action.payload;
    },
    setActiveViewData: (state, action) => {
      state.data = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const {
  setActiveView,
  setActiveModal,
  setActiveViewData,
  setSearchTerm,
} = pageViewsSlice.actions;

export const pageViewsReducer = pageViewsSlice.reducer;
