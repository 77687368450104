import { FatArrow, ThumbsUpOrDown } from "../../icons";

const DailyPulsePopup = ({
  handleMoodClick,
  renderListDays,
  colorGood,
  colorBad,
  pulse,
}) => {
  const neutralColor = "#FFFF";
  const size = "16";
  return (
    <div className="daily-pulse-popup">
      <div className="mobile-collapsable">
        <div className="wrapper mb-2">
          <div className="moods-picker">
            <p className="text-white">Daily Pulse</p>
            <div className="moods">
              <div className="mood-picker">
                <div className="thumbs" onClick={() => handleMoodClick(1)}>
                  <ThumbsUpOrDown
                    variant="up"
                    fill={pulse === 1 ? colorGood : neutralColor}
                    size={size}
                  />
                </div>
                <div className="thumbs" onClick={() => handleMoodClick(0)}>
                  <ThumbsUpOrDown
                    variant="down"
                    fill={pulse === 0 ? colorBad : neutralColor}
                    size={size}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-white">
            {"W"}
            {"64"} <FatArrow fill={colorGood} />
          </div>
        </div>

        <div className="wrapper">
          <div>
            <p className="text-white">How do you feel today?</p>
          </div>
          <div className="list-days text-white">{renderListDays()}</div>
        </div>
      </div>
    </div>
  );
};

export default DailyPulsePopup;
