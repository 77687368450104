import React from "react";

const TIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5047 0.83225C15.8062 0.83225 20.104 5.13004 20.104 10.4316C20.104 15.7332 15.8062 20.031 10.5047 20.031C5.20306 20.031 0.905273 15.7332 0.905273 10.4316C0.905273 5.13004 5.20306 0.83225 10.5047 0.83225Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M9.31296 17.8323V7.96027H5.96896V5.99228H15.025V7.96027H11.681V17.8323H9.31296Z"
      fill="#14B4BA"
    />
  </svg>
);

export default TIcon;
