import { useState, useEffect } from "react";
import Select from "react-select";

import { Form, Input, Modal } from "../../../ui";

import { useSelector } from "react-redux";
import { fetchUsers, fetchProjects } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

const TeamsModal = ({ showModal, handleModalClose, handleSave }) => {
  const [team, setTeam] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [doFetchUsers] = useThunk(fetchUsers);
  const [doFetchProjects] = useThunk(fetchProjects);
  const formData = useSelector((state) => state.form)?.data;
  const formType = useSelector((state) => state.form)?.type;
  const usersState = useSelector((state) => state.users)?.data;
  const projectsState = useSelector((state) => state.projects)?.projects;

  useEffect(() => {
    if (formData) {
      setTeam(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (formData) {
      setTeam({
        ...formData,
        members: formData?.members?.map((member) => ({
          value: member.id,
          label: `${member?.first_name} ${member?.last_name}`,
        })),
        lead: {
          value: formData?.lead?.id,
          label: `${formData?.lead?.first_name} ${formData?.lead?.last_name}`,
        },
        projects: formData?.projects?.map((project) => ({
          value: project.id,
          label: project.name,
        })),
      });
    }
  }, [formData]);

  useEffect(() => {
    doFetchUsers({});
    doFetchProjects();
  }, [doFetchUsers, doFetchProjects]);

  useEffect(() => {
    if (!!team) {
      if (team?.members?.some((member) => member?.id)) {
        setSelectedMembers(
          team?.members?.map((member) => ({
            value: member.id,
            label: `${member.first_name} ${member.last_name}`,
            isFixed: true,
          }))
        );
      } else {
        setSelectedMembers(team?.members);
      }
    }
  }, [team]);

  const teamTypes = [
    { value: 1, label: "Client", isFixed: true },
    { value: 2, label: "Backend", isFixed: true },
    { value: 3, label: "Feature", isFixed: true },
    { value: 4, label: "Product", isFixed: true },
  ];

  const handleChange = (event, customName) => {
    if (customName) {
      setTeam({ ...team, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setTeam({ ...team, [name]: value });
  };

  const handleMultiselectChange = (collection, name, isFullData) => {
    if (isFullData) {
      setTeam({
        ...team,
        [name]: collection || null,
      });
      return;
    }
    setTeam({
      ...team,
      [name]: collection ? collection?.map((item) => item.value) : null,
    });
  };

  const modalTitle = formType === "create" ? "Add Team" : "Edit Team";

  return (
    <Modal
      show={showModal}
      handleClose={handleModalClose}
      title={modalTitle}
      handleConfirm={() =>
        handleSave({
          ...team,
          members: team?.members?.map((member) => member.value),
          lead: team?.lead?.value ? team?.lead?.value : team?.lead,
          projects: team?.projects?.map((project) =>
            !!project.value ? project.value : project
          ),
        })
      }
      confirmButtonLabel="Save Changes"
      rejectButtonLabel="Close"
    >
      <Form>
        <div className="form-group">
          <label>Name</label>
          <Input
            type="input"
            placeholder="Enter name"
            value={team?.name || ""}
            handleChange={handleChange}
            name="name"
          />
        </div>
        {formType === "edit" && !team ? (
          <></>
        ) : (
          <div className="form-group">
            <label>Team Type</label>
            <Select
              name="type"
              placeholder="Select Type"
              options={teamTypes}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(event) => handleChange(event, "type")}
              defaultValue={teamTypes?.find(
                (item) => item?.value === team?.type
              )}
            />
          </div>
        )}
        <div className="form-group">
          <label>Members</label>
          <Select
            value={team?.members?.map((member) => ({
              value: member.value,
              label: member.label,
              isFixed: true,
            }))}
            isMulti
            name="members"
            options={usersState?.optionData?.map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) =>
              handleMultiselectChange(event, "members", true)
            }
          />
        </div>

        <div className="form-group">
          <label>Team Lead</label>
          {formType === "edit" && !team ? (
            <></>
          ) : (
            <Select
              name="lead"
              placeholder="Select Lead"
              options={selectedMembers}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(event) => handleChange(event, "lead")}
              defaultValue={team?.members?.find(
                (member) => member.value === team?.lead?.value
              )}
            />
          )}
        </div>
        <div className="form-group">
          <label>Projects</label>
          <Select
            value={team?.projects?.map((project) => ({
              value: project.value,
              label: project.label,
              isFixed: true,
            }))}
            isMulti
            name="projects"
            options={projectsState?.optionData?.map((project) => ({
              value: project?.id,
              label: project?.name,
              isFixed: true,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) =>
              handleMultiselectChange(event, "projects", true)
            }
          />
        </div>
      </Form>
    </Modal>
  );
};

export default TeamsModal;
