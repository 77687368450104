import { useState, useEffect } from "react";
import SelectList from "../../../SelectList/SelectList";

import { CloseIcon, PlusIcon, CalendarIcon } from "../../../../icons";

import { createTask, updateTask } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

import { useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

import "./styles.scss";
import { Button, Input } from "../../../ui";

const CareerTaskForm = ({ handleCloseTaskModal, taskFormType }) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const [taskData, setTaskData] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [existingAttachments, setExistingAttachments] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [doCreateTask] = useThunk(createTask);
  const [doUpdateTask] = useThunk(updateTask);

  const singleTaskState = useSelector((state) => state.tasks)?.singleTask;

  useEffect(() => {
    if (taskFormType === "edit" && singleTaskState) {
      setTaskData({
        ...taskData,
        id: singleTaskState.id,
        title: singleTaskState.title,
        description: singleTaskState.description,
        type: singleTaskState.type,
        privacy: singleTaskState.privacy,
        results: singleTaskState.results,
        assets: singleTaskState.assets,
        resources: singleTaskState.resources,
        notes: singleTaskState.notes,
        startDate: singleTaskState?.settings?.startDate || "",
        endDate: singleTaskState?.settings?.endDate || "",
      });
      if (Array.isArray(singleTaskState.resources)) {
        setExistingAttachments([...singleTaskState.resources]);
      }
    }
  }, [singleTaskState]);

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setTaskData({ ...taskData, [name]: value });
    return;
  };

  const saveTask = () => {
    if (taskFormType === "edit") {
      doUpdateTask({
        ...taskData,
        userId: localUserData?.id,
        type: 1,
        privacy: 1,
      });
    } else {
      doCreateTask({
        ...taskData,
        userId: localUserData?.id,
        type: 1,
        privacy: 1,
      });
    }
    handleCloseTaskModal();
  };

  const createAttachment = () => {
    const formData = new FormData();
    formData.append("resource", attachment[0]);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/create`,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      setExistingAttachments([...existingAttachments, data.data]);
    });
  };

  const changeHandler = (event) => {
    setAttachment(event.target.files);
  };

  useEffect(() => {
    if (!attachment.length) {
      return;
    }
    createAttachment();
  }, [attachment]);

  useEffect(() => {
    const fileCollection = existingAttachments.map((file) => file?.id);
    setTaskData({ ...taskData, resources: fileCollection });
  }, [existingAttachments]);

  const handleRemoveFile = (id) => {
    const requestOptions = {
      method: "DELETE",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/${id}`,
      requestOptions
    );
    const reducedFiles = existingAttachments.filter((file) => file?.id !== id);
    setExistingAttachments(reducedFiles);
  };
  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const handleStartDate = (date) => {
    setTaskData({ ...taskData, startDate: moment(date).format("DD.MM.yyyy") });
  };

  const handleEndDate = (date) => {
    setTaskData({ ...taskData, endDate: moment(date).format("DD.MM.yyyy") });
  };
  return (
    <>
      <h3 className="title-action">Add Task To Goal</h3>
      <form>
        <div className="form-group">
          <Input
            type="input"
            label="Task Title"
            placeholder="Enter name"
            name="title"
            value={taskData?.title || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group">
          <Input
            type="input"
            label="Task Description"
            placeholder="Enter description"
            name="description"
            value={taskData?.description || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="input-holder">
          <div className="form-group date-picker-holder career-form-group">
            <label>Task Start</label>
            <DatePicker
              name="startDate"
              selected={startDate}
              onChange={handleStartDate}
              dateFormat="dd.MM.yyyy"
              dateFormatCalendar={"MMM yyyy"}
              placeholderText={"Enter start date"}
              minDate={subMonths(new Date(), 256)}
              maxDate={addMonths(new Date(), 256)}
              value={taskData?.startDate}
              showMonthDropdown
              showYearDropdown
            />
            <CalendarIcon />
          </div>
          <div className="form-group date-picker-holder career-form-group">
            <label>Task End</label>
            <DatePicker
              name="endDate"
              selected={endDate}
              onChange={handleEndDate}
              dateFormat="dd.MM.yyyy"
              dateFormatCalendar={"MMM yyyy"}
              placeholderText="Enter end date"
              minDate={subMonths(new Date(), 256)}
              maxDate={addMonths(new Date(), 256)}
              value={taskData?.endDate}
              showMonthDropdown
              showYearDropdown
            />

            <CalendarIcon />
          </div>
        </div>
        {/* <div className="input-holder">
            <div className="form-group">
              <label>Type</label>
              <DropdownSelect
                name="type"
                background="dark"
                options={types}
                handleSelect={handleChange}
                type="primary"
                preselected={types.find(
                  (type) => type.value === parseInt(singleTaskState?.type)
                )}
              />
            </div>

            <div className="form-group">
              <label>Privacy settings</label>
              <DropdownSelect
                name="privacy"
                background="dark"
                options={privacy}
                handleSelect={handleChange}
                type="primary"
                preselected={privacy.find(
                  (item) => item.value === singleTaskState?.privacy
                )}
              />
            </div>
          </div> */}
        <SelectList
          label="What is your key result?"
          addLabel="Add another key result"
          placeholder="Enter text"
          name="results"
          onChange={handleChange}
          preselected={
            taskFormType === "edit" ? singleTaskState?.results || [] : []
          }
        />

        <SelectList
          label="Assets"
          addLabel="Add another asset"
          placeholder="Enter link"
          name="assets"
          onChange={handleChange}
          preselected={
            taskFormType === "edit" ? singleTaskState?.assets || [] : []
          }
        />
        <label>Resources</label>
        {!!existingAttachments.length && (
          <ul className="selected-files">
            {existingAttachments.map((file, index) => (
              <li key={index}>
                <a
                  href={`${process.env.REACT_APP_BASE_API_URL}${file?.path}`}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  {file?.title}
                </a>

                <span onClick={() => handleRemoveFile(file?.id)}>
                  <CloseIcon />
                </span>
              </li>
            ))}
          </ul>
        )}
        <div className="file-select">
          {!existingAttachments.length && (
            <div className="file-select-empty">
              Add resource (max flie size 20MB)
            </div>
          )}
          <label>
            <PlusIcon />
            Add another resource
          </label>
          <input type="file" name="image" multiple onChange={changeHandler} />
        </div>

        <div className="form-group">
          <Input
            type="input"
            label="Notes"
            placeholder="Enter description"
            name="notes"
            value={taskData?.notes || ""}
            handleChange={handleChange}
          />
        </div>
      </form>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Button variant="secondary" handleClick={handleCloseTaskModal}>
          Cancel
        </Button>
        <Button handleClick={saveTask}>Save Task</Button>
      </div>
    </>
  );
};

export default CareerTaskForm;
