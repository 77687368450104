import React, { useState } from "react";
import "./Login.scss";
import { db, auth, provider } from "../../firebase";
import { useStateValue } from "../../StateProvider";
import { actionTypes } from "../../reducer";
import { setDoc, doc, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { ArrowIcon, Logotip } from "../../icons";
import { Modal, Button, Input } from "../ui";
import { setActiveView } from "../../store/pageViews/pageViewsSlice";

function Login({ screenWidth }) {
  const [state, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const signIn = () => {
    auth
      .signInWithPopup(provider)
      .then((result) => {
        let user = result.user;

        setDoc(doc(db, "users", result.user.uid), {
          idd: user.uid,
          name: user.displayName,
          email: user.email,
          createdAt: Timestamp.fromDate(new Date()),
          isOnline: true,
          cid: Math.random(),
        });
        let full_name = user.displayName.split(" ");
        const formData = new FormData();
        formData.append("first_name", full_name[0]);
        formData.append("last_name", full_name[1]);
        formData.append("password", user.uid);
        formData.append("uid", user.uid);
        formData.append("email", user.email);
        formData.append("active", 1);

        const requestOptions = {
          method: "POST",
          body: formData,
        };

        fetch(`${process.env.REACT_APP_BASE_API_URL}/users`, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            user.id = json.id;
            user.birthday = json.birthday;
            user.position = json.position;
            user.team = json.team;
            user.phone = json.phone;
            user.image = json.image;
            localStorage.setItem("authUser", JSON.stringify(user));
            localStorage.setItem("authUserData", JSON.stringify(json));
            localStorage.setItem("last_active_date", json.last_login);
            dispatch({
              type: actionTypes.SET_USER,
              user: user,
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      })
      .catch((error) => console.log(error));
  };

  const signInPass = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        let user = userCredential.user;
        setDoc(doc(db, "users", user.uid), {
          idd: user.uid,
          name: user.displayName,
          email: user.email,
          createdAt: Timestamp.fromDate(new Date()),
          isOnline: true,
          cid: Math.random(),
        });
        const requestData = {
          password: password,
          email: user.email,
          uid: user.uid,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        };
        fetch(
          `${process.env.REACT_APP_BASE_API_URL}/users/login`,
          requestOptions
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonData) {
            return jsonData;
          })
          .then(function (result) {
            localStorage.setItem("authUser", JSON.stringify(user));
            localStorage.setItem("authUserData", JSON.stringify(result));
            localStorage.setItem("last_active_date", result.last_login);
            localStorage.setItem(
              "activePageView",
              JSON.stringify({ page: "your-career", view: "vision" })
            );
            dispatch(setActiveView({ page: "your-career", view: "vision" }));
            navigate("/your-career");

            dispatch({
              type: actionTypes.SET_USER,
              user: user,
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
    if (name === "password") {
      setPassword(value);
    }
  };

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email);
    //console.log("Password reset email sent");
  };

  return (
    <div className="login">
      {screenWidth > 840 && (
        <div className="login-background">
          <img src="/logo/logo-contrast.png" alt="logo" />
          <Logotip />
        </div>
      )}
      <div className="login-container">
        <div className="Auth-form-container">
          {screenWidth <= 840 && <img src="/logo/logo-large.png" alt="logo" />}
          <h1>Login to your account</h1>
          <form className="Auth-form">
            <div className="Auth-form-content">
              <div className="form-group mt-3">
                <Input
                  type="email"
                  placeholder="Email address"
                  name="email"
                  id="email"
                  handleChange={handleChange}
                  value={email}
                />
              </div>
              <div className="form-group mt-3">
                <Input
                  type="password"
                  placeholder="Password"
                  name="password"
                  id="password"
                  handleChange={handleChange}
                  value={password}
                />
              </div>
              {screenWidth > 375 && (
                <div className="login-btn-wrapper">
                  {/* <p>
                    <span onClick={handleShow}>Forgot your password?</span>
                  </p> */}
                  <Button
                    buttonStyles={{
                      padding: "10px 20px",
                      fontSize: "15px",
                      marginTop: "15px",
                    }}
                    handleClick={signInPass}
                  >
                    Login
                    <ArrowIcon />
                  </Button>
                </div>
              )}

              <Modal
                show={show}
                handleClose={handleClose}
                title="Reset password"
                handleConfirm={triggerResetEmail}
                confirmButtonLabel="Send email"
              >
                <form>
                  <div className="form-group" controlId="formBasicEmail">
                    <Input
                      type="email"
                      label="Email address"
                      placeholder="Enter email"
                      handleChange={handleChange}
                      name="email"
                    />
                  </div>
                </form>
              </Modal>
            </div>
          </form>
          <div className="login-buttons">
            {/* <button className="full-width-button" onClick={signIn}>
              <GoogleIcon />
              <span>Continue with Google</span>
            </button> */}
            {screenWidth <= 375 && (
              <div className="login-btn-wrapper">
                <Button
                  buttonStyles={{
                    padding: "10px 20px",
                    fontSize: "15px",
                    marginTop: "15px",
                  }}
                  handleClick={signInPass}
                  className="btn btn-primary login-button"
                >
                  Login
                </Button>
                {/* <p>
                  <span onClick={handleShow}>Forgot your password?</span>
                </p> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
