import React, { useState } from "react";
import "./styles.scss";

const UserIcon = ({
  userImage,
  customPath,
  userName,
  iconStyle,
  iconWrapperStyle,
}) => {
  const [error, setError] = useState(false);
  const handleError = () => {
    setError(true);
  };
  return (
    <div className="user-icon" style={iconWrapperStyle}>
      {(!userImage && !customPath) || error ? (
        <div style={iconStyle} className="default-icon">
          {userName ? userName[0] : ""}
        </div>
      ) : (
        <img
          src={
            customPath
              ? customPath
              : `${process.env.REACT_APP_BASE_API_URL}${userImage}`
          }
          alt="user"
          style={iconStyle}
          onError={handleError}
        />
      )}
    </div>
  );
};

export default UserIcon;
