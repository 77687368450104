import { useEffect } from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { useToastMessage } from "../../hooks/useToastMessage";
import { resetGoalsMessage } from "../../store/goals/goalsSlice";
import { resetTaskMessage } from "../../store/tasks/tasksSlice";
import { resetFeedbackMessage } from "../../store/feedback/feedbackSlice";
import { Outlet } from "react-router-dom";

const CareerPath = () => {
  const [doShowGoalsToastMessage] = useToastMessage(resetGoalsMessage);
  const [doShowTasksToastMessage] = useToastMessage(resetTaskMessage);
  const [doShowFeedbackToastMessage] = useToastMessage(resetFeedbackMessage);

  const goalsMessageState = useSelector((state) => state.goals)?.message;
  const taskMessageState = useSelector((state) => state.tasks)?.message;
  const feedbackMessageState = useSelector((state) => state.feedback)?.message;

  useEffect(() => {
    doShowGoalsToastMessage(goalsMessageState);
  }, [goalsMessageState]);

  useEffect(() => {
    doShowTasksToastMessage(taskMessageState);
  }, [taskMessageState]);

  useEffect(() => {
    doShowFeedbackToastMessage(feedbackMessageState);
  }, [feedbackMessageState]);

  return (
    <div className="career-path-wrapper scrollable-content">
      <div className="module-content">
        <Outlet />
      </div>
    </div>
  );
};

export default CareerPath;
