import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchAchievements = createAsyncThunk(
  "achievements/fetch",
  async ({ page = 1, searchTerm = "" }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/achievements`, {
        params: {
          page,
          searchTerm,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createAchievement = createAsyncThunk(
  "achievements/create",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/achievements`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateAchievement = createAsyncThunk(
  "achievements/update",
  async (payload) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/achievements/${payload.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteAchievement = createAsyncThunk(
  "achievements/delete",
  async (id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/achievements/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
