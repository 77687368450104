import { ChatBubbleIcon } from "../../icons";
import { UserIcon, Button } from "../ui";

const UserInfo = ({ team, handleDirect }) => {
  const handleDirectClick = () => {
    const userEmail = team.lead?.email;
    const userName = `${team?.lead?.first_name} ${team?.lead?.last_name}`;
    handleDirect(userEmail, userName);
  };

  return (
    <div className="support-user">
      <div className="support-user-header">
        <div className="user-image">
          <div className="photo-holder">
            <UserIcon
              userImage={team?.lead?.image}
              userName={team?.lead?.first_name}
              iconStyle={{
                minWidth: "250px",
                minHeight: "280px",
                borderRadius: "6px",
                fontSize: "100px",
              }}
            />
          </div>
        </div>
        <h3>
          {team?.lead?.first_name} {team?.lead?.last_name}
        </h3>
        <div className="support-header-info">
          <div className="support-header-availability">
            <p className={!team?.lead?.online ? "yellow-dot" : "green-dot"}>
              {!team?.lead?.online ? "Unavailable" : "Available"}
            </p>
          </div>
          <Button
            buttonStyles={{
              border: "1px solid #0f2a3c",
              backgroundColor: "#fff",
              padding: "16px",
              color: "#0f2a3c",
            }}
            handleClick={handleDirectClick}
          >
            <ChatBubbleIcon />
            Message
          </Button>
        </div>
      </div>
      <div className="support-user-info">
        <h4>Contact Information</h4>
        <div>
          <p>Email Adress</p>
          <span>{team?.lead?.email}</span>
        </div>
        <div>
          <p>Phone Number</p>
          <span>{team?.lead?.phone}</span>
        </div>
      </div>
      <div className="support-user-projects">
        <h4>Projects</h4>
        <ul>
          <li>{team?.projects?.name}</li>
        </ul>
      </div>
    </div>
  );
};

export default UserInfo;
