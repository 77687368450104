const SingleArrow = () => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 0.999023L7 6.99902L13 0.999023" stroke="#6A6565" />
  </svg>
);

export default SingleArrow;
