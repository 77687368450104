import db from "../../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Input, Table } from "../../../ui";
import SearchIcon from "../../../../icons/SearchIcon";
import ArrowUpIcon from "../../../../icons/ArrowUpIcon";
import ArrowDownIcon from "../../../../icons/ArrowDownIcon";

import { useThunk } from "../../../../hooks/useThunk";
import { getUserChatTrends } from "../../../../store/reports/reportsThunks";

import UserChatStatsModal from "./UserChatStatsModal";
import TrendsModal from "../Communication/TrendsModal";
import usePageTitle from "../../../../hooks/usePageTitle";
import { setActiveModal } from "../../../../store/modal/modalSlice";
import { setFormData, setFormType } from "../../../../store/form/formSlice";

const Chat = () => {
  const title = usePageTitle();
  const dispatch = useDispatch();
  const [doGetUserChatTrends] = useThunk(getUserChatTrends);
  const [privateMessages, setPrivateMessages] = useState(null);
  const [allChatStats, setAllChatStats] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const chatStats = useSelector((state) => state.reports.userChatTrends);
  const singleChatTrends = useSelector((state) => state.reports.chatTrends);

  useEffect(() => {
    doGetUserChatTrends();
    db.collectionGroup("messages")
      .get()
      .then((querySnapshot) => {
        const privateMessages = querySnapshot.docs.map((doc) => ({
          uid: doc.data().userId,
          user: doc.data().user,
          message: doc.data().message,
          private: doc.data().private_message,
        }));

        setPrivateMessages(privateMessages);
      });
  }, []);

  useEffect(() => {
    if (privateMessages && chatStats) {
      let result = [];
      privateMessages.forEach(function (a) {
        if (!this[a.uid]) {
          this[a.uid] = {
            uid: a.uid,
            user: a.user,
            private_messages: 0,
            public_messages: 0,
          };
          result.push(this[a.uid]);
        }
        if (a.private === true) {
          this[a.uid].private_messages++;
        } else {
          this[a.uid].public_messages++;
        }
      }, Object.create(null));

      let combinedData = result.map((item) => ({
        ...item,
        trends: chatStats[item.uid] ? chatStats[item.uid] : {},
      }));

      setAllChatStats(combinedData);
    }
  }, [privateMessages, chatStats]);

  const handleSearchChatUsersChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleShowUser = (event, user) => {
    dispatch(setFormType("view-chat-stats"));
    dispatch(setFormData(user));
    dispatch(setActiveModal(`${title}-chatStats-modal`));
  };

  const handleShowBarModal = (user) => {
    dispatch(setFormType("view-chat-trends"));
    dispatch(setFormData(user));
    dispatch(setActiveModal(`${title}-trends-modal`));
  };

  const handleClose = () => {
    dispatch(setActiveModal(""));
    dispatch(setFormType(""));
    dispatch(setFormData(null));
  };
  const tableData = {
    rows: allChatStats?.map((user) => {
      return {
        user: (
          <span
            onClick={(event) => handleShowUser(event, user)}
            className="user-data"
          >
            <span className="default-image">{user?.user[0]}</span>
            {user?.user}
          </span>
        ),
        private_messages: user?.private_messages,
        public_messages: user?.public_messages,
        online_average: "100%",
        trends: (
          <div onClick={() => handleShowBarModal(user)} className="trend">
            {user?.trends && user?.trends?.trend === "up" ? (
              <ArrowUpIcon />
            ) : (
              <ArrowDownIcon />
            )}
          </div>
        ),
      };
    }),
    columns: [
      {
        label: "User",
        slug: "user",
      },
      {
        label: "# Direct messages",
        slug: "private_messages",
      },
      {
        label: "# Channel messages",
        slug: "public_messages",
      },
      {
        label: "Online on average",
        slug: "online_average",
      },
      {
        label: "Trends (last & this week)",
        slug: "trends",
      },
    ],
  };

  return (
    <div>
      <div className="reports-header">
        <h2>{title}</h2>
        <div className="header-actions">
          <div className="table-search">
            <Input
              placeholder="Search"
              variant="form-control light"
              handleChange={handleSearchChatUsersChange}
              icon={<SearchIcon />}
              value={searchTerm}
            />
          </div>
        </div>
      </div>

      <Table rows={tableData?.rows} columns={tableData?.columns} />

      {activeModalState === `${title}-chatStats-modal` ? (
        <UserChatStatsModal
          showUser={activeModalState === `${title}-chatStats-modal`}
          handleCloseUser={handleClose}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}
      {activeModalState === `${title}-trends-modal` ? (
        <TrendsModal
          showBarModal={activeModalState === `${title}-trends-modal`}
          handleCloseBarModal={handleClose}
          data={singleChatTrends}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Chat;
