import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../store/toastMessages/toastMessagesSlice";

export const useToastMessage = (resetReducer) => {
  const dispatch = useDispatch();
  const showMessage = useCallback(
    (message) => {
      if (!message) {
        return;
      }
      dispatch(setToastMessage(message));
      dispatch(resetReducer());
    },
    [dispatch, resetReducer]
  );
  return [showMessage];
};
