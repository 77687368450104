import React from "react";
import "./styles.scss";

const TimelineList = ({ data = [], lineColor = "" }) => {
  return (
    <ul className="timeline-list">
      {data.map((item, index) => (
        <li key={`tli-${index}`}>
          <div className="dotted-line">
            <span
              className="vertical-line"
              style={{ backgroundColor: lineColor }}
            ></span>
            <div className="dot" style={{ backgroundColor: lineColor }}></div>
          </div>
          <p className="item">{item}</p>
        </li>
      ))}
    </ul>
  );
};

export default TimelineList;
