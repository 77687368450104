import { useState, useEffect } from "react";
import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import "./styles.scss";

import { fetchUsers, createFeedback } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";
// import QuestionIcon from "../../../../icons/QuestionIcon";
import { Form, Input, Modal } from "../../../ui";

const GiveFeedbackForm = ({
  showGiveFeedbackModal,
  handleModalClose,
  isSent,
}) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const [feedbackData, setFeedbackData] = useState(null);
  const [allUsers, setAllUsers] = useState([]);

  const usersState = useSelector((state) => state.users)?.data?.optionData;
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doCreateFeedback] = useThunk(createFeedback);

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    }
    const reducedUsers = usersState?.map((user) => {
      return {
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      };
    });
    setAllUsers(reducedUsers);
  }, [usersState]);

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setFeedbackData({ ...feedbackData, [name]: value });
    return;
  };

  // const transparencyOptions = [
  //   {
  //     value: 1,
  //     label: "Recipient and their manager",
  //   },
  //   {
  //     value: 2,
  //     label: "Manager only",
  //   },
  // ];

  const handeGiveFeedback = () => {
    const payload = {
      ...feedbackData,
      senderId: localUserData?.id,
    };
    doCreateFeedback(payload);
    handleModalClose();
  };

  return (
    <Modal
      show={showGiveFeedbackModal}
      handleClose={handleModalClose}
      title="Give Feedback"
      confirmButtonLabel="Give feedback"
      handleConfirm={handeGiveFeedback}
    >
      <Form>
        <div className="form-group bottom-margin">
          <label>Who would you like to give feedback to?</label>
          <DropdownSelect
            name="receiverId"
            background="dark"
            options={allUsers}
            handleSelect={handleChange}
            type="primary"
            placeholder="Select teammates"
            isDisabled={isSent}
            // preselected={types.find(
            //   (type) => type.value === parseInt(singleTaskState?.type)
            // )}
          />
        </div>

        <div className="form-group">
          <Input
            type="input"
            label="Your feedback"
            placeholder="My feedback..."
            name="answer"
            value={feedbackData?.answer || ""}
            handleChange={handleChange}
            disabled={isSent}
          />
        </div>

        {/* <div className="form-group">
            <label>Who will see this feedback?</label>
            <DropdownSelect
              name="involveManager"
              background="dark"
              options={transparencyOptions}
              handleSelect={handleChange}
              type="primary"
              selectListPosition="top"
              isDisabled={isSent}
              // preselected={types.find(
              //   (type) => type.value === parseInt(singleTaskState?.type)
              // )}
            />
          </div> */}
        {/* <div className="feedback-note">
            <div>
              <QuestionIcon />
            </div>
            <p>
              Since the person you selected doesn't have a manager, your
              feedback will be sent directly to them.
            </p>
          </div> */}
      </Form>
    </Modal>
  );
};

export default GiveFeedbackForm;
