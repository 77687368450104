const ReportsIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.333008 13.6668V4.50016H3.24967V13.6668H0.333008ZM5.54134 13.6668V0.333496H8.45801V13.6668H5.54134ZM10.7497 13.6668V7.8335H13.6663V13.6668H10.7497Z"
      fill="white"
    />
  </svg>
);
export default ReportsIcon;
