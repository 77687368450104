import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetPositionsMessage } from "../../../../store/positions/positionsSlice";

import { Outlet } from "react-router-dom";

const Position = () => {
  const messageState = useSelector((state) => state.positions)?.message;

  const [doShowToastMessage] = useToastMessage(resetPositionsMessage);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  return <Outlet />;
};

export default Position;
