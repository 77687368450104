const MultiUsersIcon = () => (
  <svg
    width="33"
    height="24"
    viewBox="0 0 33 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46523 13.9904C6.19704 13.9904 5.93953 13.9904 5.68235 13.9904C5.6236 12.8854 6.2696 11.1436 8.0982 10.3158C7.23064 9.65889 6.78958 8.80009 6.88695 7.70868C6.95534 6.94242 7.30695 6.30966 7.89701 5.81618C9.00176 4.8923 10.6767 4.97787 11.674 6.00932C12.2799 6.63599 12.5556 7.39092 12.4735 8.25759C12.3919 9.11911 11.9654 9.79469 11.2628 10.3064C12.7933 11.0671 13.6148 12.2759 13.7021 13.9877C13.4203 13.9877 13.1599 13.9877 12.8994 13.9877C12.8656 11.9566 11.1482 10.7645 9.63882 10.7907C8.01478 10.8188 6.49257 12.1113 6.46523 13.9904ZM9.68029 9.98559C10.7845 9.9855 11.6793 9.0935 11.6839 7.98851C11.6885 6.88184 10.7882 5.9802 9.67908 5.98048C8.57044 5.98081 7.66693 6.88624 7.67428 7.9894C7.68167 9.09566 8.57615 9.98573 9.68029 9.98559Z"
      fill="#999999"
    />
    <circle cx="21" cy="12" r="11.5" fill="#D9D9D9" stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1663 17.6723C16.8276 17.6723 16.5023 17.6723 16.1774 17.6723C16.1032 16.2766 16.9192 14.0763 19.2291 13.0308C18.1332 12.201 17.5761 11.1162 17.6991 9.73753C17.7854 8.76962 18.2296 7.97036 18.9749 7.34701C20.3704 6.18001 22.4861 6.2881 23.7458 7.59098C24.5112 8.38256 24.8595 9.33615 24.7558 10.4309C24.6527 11.5191 24.114 12.3725 23.2264 13.0189C25.1597 13.9797 26.1974 15.5067 26.3076 17.6689C25.9517 17.6689 25.6228 17.6689 25.2938 17.6689C25.251 15.1034 23.0816 13.5976 21.1751 13.6306C19.1237 13.6661 17.2009 15.2987 17.1663 17.6723ZM21.2275 12.6136C22.6223 12.6135 23.7526 11.4868 23.7584 10.091C23.7642 8.69311 22.6269 7.5542 21.2259 7.55455C19.8256 7.55497 18.6843 8.69867 18.6936 10.0921C18.7029 11.4895 19.8328 12.6138 21.2275 12.6136Z"
      fill="#999999"
    />
  </svg>
);
export default MultiUsersIcon;
