import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjects,
  fetchSingleProject,
  createProject,
  updateProject,
  deleteProject,
  followProject,
  unfollowProject,
} from "./projectsThunks";

const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    projects: [],
    singleProject: null,
    isLoading: false,
    error: null,
    message: null,
  },

  reducers: {
    resetProjectMessage: (state) => {
      state.message = null;
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(fetchSingleProject.fulfilled, (state, action) => {
      state.singleProject = action.payload;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.projects.projectData = [
        ...state.projects.projectData,
        action.payload.data,
      ];
      state.message = {
        type: "success",
        title: "Success",
        message: "Project successfully created",
      };
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      const updatedState = state?.projects?.projectData?.map((project) => {
        return project.id === action.payload.data.id
          ? action.payload.data
          : project;
      });
      state.projects.projectData = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Project successfully updated",
      };
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      const filteredProjects = state?.projects?.projectData?.filter(
        (project) => project.id !== action.meta.arg
      );
      state.projects.projectData = filteredProjects;
      state.message = {
        type: "success",
        title: "Success",
        message: "Project successfully deleted",
      };
    });
    builder.addCase(followProject.fulfilled, (state, action) => {
      const updatedState = state?.projects?.projectData?.map((project) => {
        return project.id === action.payload.data.id
          ? action.payload.data
          : project;
      });
      state.projects.projectData = updatedState;
    });
    builder.addCase(unfollowProject.fulfilled, (state, action) => {
      const updatedState = state?.projects?.projectData?.map((project) => {
        return project.id === action.payload.data.id
          ? action.payload.data
          : project;
      });
      state.projects.projectData = updatedState;
    });
  },
});

export const { resetProjectMessage } = projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
