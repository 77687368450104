import { createSlice } from "@reduxjs/toolkit";
import {
  getUsersStats,
  getUsersTrends,
  getUserTrends,
  getTeamsStats,
  getTeamsTrends,
  getUserChatTrends,
  getChatTrends,
} from "./reportsThunks";

export const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    usersStats: {},
    usersTrends: {},
    userTrends: {},
    teamsStats: {},
    teamsTrends: {},
    userChatTrends: {},
    chatTrends: {},
    status: "idle",
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersStats.fulfilled, (state, action) => {
        state.usersStats = action.payload;
      })
      .addCase(getUsersTrends.fulfilled, (state, action) => {
        state.usersTrends = action.payload;
      })
      .addCase(getUserTrends.fulfilled, (state, action) => {
        state.userTrends = action.payload;
      })
      .addCase(getTeamsStats.fulfilled, (state, action) => {
        state.teamsStats = action.payload;
      })
      .addCase(getTeamsTrends.fulfilled, (state, action) => {
        state.teamsTrends = action.payload;
      })
      .addCase(getUserChatTrends.fulfilled, (state, action) => {
        state.userChatTrends = action.payload;
      })
      .addCase(getChatTrends.fulfilled, (state, action) => {
        state.chatTrends = action.payload;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.status = "loading";
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = "succeeded";
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          state.isLoading = false;
        }
      );
  },
});

export const reportsReducer = reportsSlice.reducer;
