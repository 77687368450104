import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchPositions = createAsyncThunk("positions/fetch", async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/position`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchSinglePosition = createAsyncThunk(
  "positions/fetch/single",
  async (positionId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/position/${positionId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createPosition = createAsyncThunk(
  "position/create",
  async (position) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/position/`, position);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePosition = createAsyncThunk(
  "position/update",
  async (position) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/position/${position.id}/edit`,
        position
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deletePosition = createAsyncThunk(
  "position/delete",
  async (id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/position/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPositionEmployees = createAsyncThunk(
  "positionEmployees/fetch",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/position/${id}/employees`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
