import React, { useEffect, useState, useLayoutEffect } from "react";

import "./App.scss";
import "./global.scss";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { BrowserRouter as Router } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import Login from "./components/Login/Login";
import { Toast } from "./components/ui";
import { useSelector } from "react-redux";

import { Offcanvas } from "./components/ui";
import { actionTypes } from "./reducer";
import AppRouter from "./routes/AppRouter";

function App() {
  const [{ user }, dispatch] = useStateValue();
  const [newMessage, setNewMessage] = useState("");
  const [activeSidebarOption, setActiveSidebarOption] = useState("Your-career");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);

  const toastMessagesState = useSelector(
    (state) => state.toastMessages
  )?.messages;

  useEffect(() => {
    if (!user) {
      let localUser = JSON.parse(localStorage.getItem("authUser"));
      let localUserData = JSON.parse(localStorage.getItem("authUserData"));
      let fullUserData = { ...localUser, ...localUserData };

      if (localUser) {
        dispatch({
          type: actionTypes.SET_USER,
          user: fullUserData,
        });
      }
    }
  }, [user, dispatch]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResponsiveMenuToggle = () => {
    setShowResponsiveMenu(!showResponsiveMenu);
  };

  const handleResponsiveMenuClose = () => {
    setShowResponsiveMenu(false);
  };

  return (
    <div className="App">
      <Router>
        {!user ? (
          <Login screenWidth={screenWidth} />
        ) : (
          <>
            {screenWidth > 840 ? (
              <Sidebar
                newMessage={newMessage}
                setNewMessage={setNewMessage}
                activeSidebarOption={activeSidebarOption}
              />
            ) : (
              <div className="responsive-menu-offcanvas">
                <Offcanvas
                  show={showResponsiveMenu}
                  position="start"
                  scroll={true}
                  className="responsive-sidenav-offcanvas"
                >
                  <Sidebar
                    screenWidth={screenWidth}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    activeSidebarOption={activeSidebarOption}
                    handleResponsiveMenuClose={handleResponsiveMenuClose}
                  />
                </Offcanvas>
              </div>
            )}

            <div className="app-body">
              <Header
                screenWidth={screenWidth}
                activeSidebarOption={activeSidebarOption}
                handleResponsiveMenuToggle={handleResponsiveMenuToggle}
              />
              <AppRouter screenWidth={screenWidth} />
            </div>
          </>
        )}
      </Router>
      <Toast toastList={toastMessagesState} duration={10000} />
    </div>
  );
}

export default App;
