import { Routes, Route } from "react-router-dom";
import CareerVision from "../components/CareerPath/client/CareerVision";
import HighFive from "../components/CareerPath/client/highFive/HighFive";
import CheckIns from "../components/CareerPath/modules/checkIns/CheckIns";
import ClientCareerPath from "../components/CareerPath/client/ClientCareerPath";
import GoalDetails from "../components/CareerPath/client/goalDetails/GoalDetails";
import TaskDetails from "../components/CareerPath/client/taskDetails/TaskDetails";
import PositionDetails from "../components/CareerPath/client/positionDetails/PositionDetails";

const YourCareerRoutes = () => (
  <Routes>
    <Route path="/" element={<ClientCareerPath />}>
      <Route path="home" element={<CareerVision />}></Route>
      <Route path="high-fives" element={<HighFive />} />
      <Route path="goal-details" element={<GoalDetails />} />
      <Route path="task-details" element={<TaskDetails />} />
      <Route path="position-details" element={<PositionDetails />} />
      <Route path="check-ins" element={<CheckIns />} />
    </Route>
  </Routes>
);

export default YourCareerRoutes;
