const CareerPathIcon = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9577 15.5C14.3882 15.5 13.8917 15.3299 13.4681 14.9896C13.0445 14.6493 12.7771 14.2361 12.666 13.75H8.22852C7.31185 13.75 6.55143 13.4479 5.94727 12.8438C5.3431 12.2396 5.04102 11.4792 5.04102 10.5625C5.04102 9.64583 5.3431 8.88542 5.94727 8.28125C6.55143 7.67708 7.31185 7.375 8.22852 7.375H9.83268C10.4021 7.375 10.8674 7.19444 11.2285 6.83333C11.5896 6.47222 11.7702 6.00694 11.7702 5.4375C11.7702 4.86806 11.5896 4.40278 11.2285 4.04167C10.8674 3.68056 10.4021 3.5 9.83268 3.5H5.33268C5.20768 3.98611 4.93685 4.39931 4.52018 4.73958C4.10352 5.07986 3.61046 5.25 3.04102 5.25C2.37435 5.25 1.81185 5.02083 1.35352 4.5625C0.895182 4.10417 0.666016 3.54167 0.666016 2.875C0.666016 2.20833 0.895182 1.64583 1.35352 1.1875C1.81185 0.729167 2.37435 0.5 3.04102 0.5C3.61046 0.5 4.10352 0.670139 4.52018 1.01042C4.93685 1.35069 5.20768 1.76389 5.33268 2.25H9.83268C10.7493 2.25 11.5098 2.55208 12.1139 3.15625C12.7181 3.76042 13.0202 4.52083 13.0202 5.4375C13.0202 6.35417 12.7181 7.11458 12.1139 7.71875C11.5098 8.32292 10.7493 8.625 9.83268 8.625H8.22852C7.65907 8.625 7.19379 8.80556 6.83268 9.16667C6.47157 9.52778 6.29102 9.99306 6.29102 10.5625C6.29102 11.1319 6.47157 11.5972 6.83268 11.9583C7.19379 12.3194 7.65907 12.5 8.22852 12.5H12.666C12.791 12.0139 13.0618 11.6007 13.4785 11.2604C13.8952 10.9201 14.3882 10.75 14.9577 10.75C15.6243 10.75 16.1868 10.9792 16.6452 11.4375C17.1035 11.8958 17.3327 12.4583 17.3327 13.125C17.3327 13.7917 17.1035 14.3542 16.6452 14.8125C16.1868 15.2708 15.6243 15.5 14.9577 15.5ZM3.04102 4C3.36046 4 3.62782 3.89236 3.8431 3.67708C4.05838 3.46181 4.16602 3.19444 4.16602 2.875C4.16602 2.55556 4.05838 2.28819 3.8431 2.07292C3.62782 1.85764 3.36046 1.75 3.04102 1.75C2.72157 1.75 2.45421 1.85764 2.23893 2.07292C2.02365 2.28819 1.91602 2.55556 1.91602 2.875C1.91602 3.19444 2.02365 3.46181 2.23893 3.67708C2.45421 3.89236 2.72157 4 3.04102 4Z"
      fill="#FFFFFF"
    />
  </svg>
);
export default CareerPathIcon;
