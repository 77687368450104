import { useState, useEffect } from "react";

import { useStateValue } from "../../../StateProvider";
import { Modal } from "../../ui";
import "./styles.scss";

const ChatActions = ({ selectedUser, getAllChats, groupType }) => {
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [singleChatType, setSingleChatType] = useState("single");
  const [{ user }] = useStateValue();
  useEffect(() => {
    if (!!selectedUser.type) {
      setSingleChatType("group");
    }
  }, [selectedUser]);
  const handleStarClick = (e) => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append("user_id", user?.id);
    formData.append("star", !!selectedUser.star ? 0 : 1);
    if (singleChatType === "single") {
      formData.append("chat_uuid", selectedUser.uuid);
    } else {
      formData.append("chat_id", selectedUser.id);
    }

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    if (singleChatType === "single") {
      fetch(
        `${process.env.REACT_APP_BASE_API_URL}/chat/private/star`,
        requestOptions
      ).then(() => window.location.reload());

      return;
    }
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/chat/group/star`,
      requestOptions
    ).then(() => window.location.reload());
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append("user_id", user?.id);
    if (singleChatType === "single") {
      formData.append("chat_uuid", selectedUser.uuid);
    } else {
      formData.append("chat_id", selectedUser.id);
    }
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    if (singleChatType === "single") {
      fetch(
        `${process.env.REACT_APP_BASE_API_URL}/chat/private/delete`,
        requestOptions
      ).then(() => window.location.reload());
      return;
    }
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/chat/group/delete`,
      requestOptions
    ).then(() => window.location.reload());
  };

  const optionList = [
    // {
    //   label: "Open in new window",
    // },
    // {
    //   label: "View channel details",
    // },
    // {
    //   label: "Copy",
    // },
    // {
    //   label: "Mute channel",
    // },
    // {
    //   label: "Change notifications",
    // },
    {
      label: `${
        !!selectedUser?.star ? "Unstar conversation" : "Star conversation"
      }`,
      handleClick: (e) => handleStarClick(e),
    },
    // {
    //   label: "Move to new section",
    // },
    {
      label: `${singleChatType === "group" ? `Leave` : "Delete Chat"}`,
      dangerText: true,
      handleClick: (e) => handleOpenLeaveModal(e),
    },
  ];

  const handleOpenLeaveModal = (e) => {
    e.stopPropagation();
    setShowLeaveModal(true);
  };

  const handleCloseLeaveModal = (e) => {
    e.stopPropagation();
    setShowLeaveModal(false);
  };

  const handleLeave = (e) => {
    handleDeleteClick(e);
  };
  return (
    <>
      <ul className="actions-list">
        {optionList?.map((option, index) => (
          <li
            className={option.dangerText ? "danger" : ""}
            key={index}
            onClick={option.handleClick}
          >
            {option.label}
          </li>
        ))}
      </ul>
      <Modal
        show={showLeaveModal}
        handleClose={handleCloseLeaveModal}
        handleConfirm={handleLeave}
        title={
          singleChatType === "group" ? `Leave ${groupType}` : "Delete chat"
        }
        confirmButtonLabel={singleChatType === "single" ? "Delete" : "Leave"}
        rejectButtonLabel="Close"
      >
        {singleChatType === "single"
          ? "Are you sure you want to delete chat?"
          : `Are you sure you want to leave ${groupType} ${selectedUser.name}`}
      </Modal>
    </>
  );
};

export default ChatActions;
