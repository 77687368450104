import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchRoles = createAsyncThunk(
  "roles/fetch",
  async ({ page = 1, searchTerm = "" }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/roles`, {
        params: {
          page,
          searchTerm,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createRole = createAsyncThunk("roles/create", async (payload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/roles`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateRole = createAsyncThunk("roles/update", async (payload) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/roles/${payload.id}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteRole = createAsyncThunk("roles/delete", async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/roles/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});
