import { Routes, Route } from "react-router-dom";
import Teams from "../components/Administration/modules/teams/Teams";
import Users from "../components/Administration/modules/users/Users";
import Achievements from "../components/Administration/modules/achievements/Achievements";
import Roles from "../components/Administration/modules/roles/Roles";
import Administration from "../components/Administration/Administration";

const AdministrationRoutes = () => (
  <Routes>
    <Route path="/" element={<Administration />}>
      <Route path="teams" element={<Teams />} />
      <Route path="users" element={<Users />} />
      <Route path="achievements" element={<Achievements />} />
      <Route path="roles" element={<Roles />} />
    </Route>
  </Routes>
);

export default AdministrationRoutes;
