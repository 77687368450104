import { useState, useEffect } from "react";

import { Form, Input, Modal } from "../../../ui";
import SelectList from "../../../SelectList/SelectList";
import CareerTaskForm from "./CareerTaskForm";

import {
  CloseIcon,
  DeleteIcon,
  CalendarIcon,
  PlusIcon,
} from "../../../../icons";

import { fetchSingleUserTask, createGoal, deleteTask } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";
import {
  resetSingleTask,
  setSelectedTask,
} from "../../../../store/tasks/tasksSlice";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

import "./styles.scss";

const CareerGoalForm = ({ showModal, handleCloseGoalModal }) => {
  const dispatch = useDispatch();

  const [goalData, setGoalData] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [existingAttachments, setExistingAttachments] = useState([]);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [taskFormType, setTaskFormType] = useState("");
  const [goalTasks, setGoalTasks] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [doFetchSingleUserTask] = useThunk(fetchSingleUserTask);
  const [doCreateGoal] = useThunk(createGoal);
  const [doDeleteTask] = useThunk(deleteTask);

  const createdTaskIdState = useSelector((state) => state.tasks)?.createdTaskId;
  const singleTaskState = useSelector((state) => state.tasks)?.singleTask;
  const selectedTaskState = useSelector((state) => state.tasks)?.selectedTask;

  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  useEffect(() => {
    if (createdTaskIdState) {
      doFetchSingleUserTask(createdTaskIdState);
    }
  }, [createdTaskIdState]);

  // useEffect(() => {
  //   if (singleTaskState && taskFormType === "create") {
  //     setGoalData({
  //       ...goalData,
  //       tasks: [...goalData?.tasks, singleTaskState],
  //     });
  //   }
  // }, [singleTaskState]);

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setGoalData({ ...goalData, [name]: value });
    return;
  };

  const saveGoal = () => {
    doCreateGoal({
      ...goalData,
      tasks: goalTasks.map((task) => task.id),
      user_id: localUserData?.id,
      status: 1,
      type: 1,
      privacy: 1,
    });
    dispatch(resetSingleTask());
    dispatch(setSelectedTask(null));
    handleCloseGoalModal();
  };

  const createAttachment = () => {
    const formData = new FormData();
    formData.append("resource", attachment[0]);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/create`,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      setExistingAttachments([...existingAttachments, data.data]);
    });
  };

  const changeHandler = (event) => {
    setAttachment(event.target.files);
  };

  const handleRemoveFile = (id) => {
    const requestOptions = {
      method: "DELETE",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/${id}`,
      requestOptions
    );
    const reducedFiles = existingAttachments.filter((file) => file?.id !== id);
    setExistingAttachments(reducedFiles);
  };

  useEffect(() => {
    if (!attachment.length) {
      return;
    }
    createAttachment();
  }, [attachment]);

  const handleCloseTaskModal = () => {
    setShowTaskModal(false);
  };

  useEffect(() => {
    if (!!selectedTaskState) {
      if (goalTasks.some((task) => task.id === selectedTaskState.id)) {
        const updatedTasks = goalTasks.map((task) => {
          return task.id === selectedTaskState.id ? selectedTaskState : task;
        });
        setGoalTasks(updatedTasks);
        return;
      }

      setGoalTasks([...goalTasks, selectedTaskState]);
    }
  }, [selectedTaskState]);

  const handleShowEditTask = (task) => {
    setTaskFormType("edit");
    doFetchSingleUserTask(task?.id);
    setShowTaskModal(true);
  };

  const handleRemoveTask = (taskId) => {
    doDeleteTask(taskId);
    const filteredTasks = goalTasks.filter((task) => task.id !== taskId);
    setGoalTasks(filteredTasks);
  };

  const handleAddTask = () => {
    setTaskFormType("create");
    setSelectedTask(null);
    resetSingleTask();
    setShowTaskModal(true);
  };

  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const handleStartDate = (date) => {
    setGoalData({ ...goalData, startDate: moment(date).format("DD.MM.yyyy") });
  };

  const handleEndDate = (date) => {
    setGoalData({ ...goalData, endDate: moment(date).format("DD.MM.yyyy") });
  };

  return (
    <Modal
      show={showModal}
      handleClose={handleCloseGoalModal}
      title={"Create Your Own Career Goal"}
      handleConfirm={saveGoal}
      confirmButtonLabel={showTaskModal ? "Continue" : "Submit"}
      rejectButtonLabel="Cancel"
    >
      {!showTaskModal ? (
        <>
          <Form>
            <div className="form-group">
              <Input
                type="input"
                label="Goal Title"
                placeholder="Enter name"
                name="name"
                value={goalData?.name || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="form-group">
              <Input
                type="input"
                label="Goal Description"
                placeholder="Enter description"
                name="description"
                value={goalData?.description || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="form-row">
              <div className="form-group date-picker-holder career-form-group">
                <label>Goal Start</label>
                <DatePicker
                  name="startDate"
                  selected={startDate}
                  onChange={handleStartDate}
                  dateFormat="dd.MM.yyyy"
                  dateFormatCalendar={"MMM yyyy"}
                  placeholderText={"Enter start date"}
                  minDate={subMonths(new Date(), 256)}
                  maxDate={addMonths(new Date(), 256)}
                  value={goalData?.startDate}
                  showMonthDropdown
                  showYearDropdown
                />
                <CalendarIcon />
              </div>
              <div className="form-group date-picker-holder career-form-group">
                <label>Goal End</label>
                <DatePicker
                  name="endDate"
                  selected={endDate}
                  onChange={handleEndDate}
                  dateFormat="dd.MM.yyyy"
                  dateFormatCalendar={"MMM yyyy"}
                  placeholderText="Enter end date"
                  minDate={subMonths(new Date(), 256)}
                  maxDate={addMonths(new Date(), 256)}
                  value={goalData?.endDate}
                  showMonthDropdown
                  showYearDropdown
                />

                <CalendarIcon />
              </div>
            </div>
            {/* <div className="input-holder">
                <div className="form-group">
                  <label>Type</label>
                  <DropdownSelect
                    name="type"
                    background="dark"
                    options={types}
                    handleSelect={handleChange}
                    type="primary"
                    preselected={types.find(
                      (type) => type.value === parseInt(goalData?.type)
                    )}
                  />
                </div>

                <div className="form-group">
                  <label>Privacy settings</label>
                  <DropdownSelect
                    name="privacy"
                    background="dark"
                    options={privacy}
                    handleSelect={handleChange}
                    type="primary"
                    preselected={privacy.find(
                      (item) => item.value === goalData?.privacy
                    )}
                  />
                </div>
              </div> */}
            <label>Goal Tasks</label>
            {!!goalTasks.length && (
              <ul className="selected-list">
                {goalTasks?.map((task, index) => (
                  <li key={index} onClick={() => handleShowEditTask(task)}>
                    {task.title}
                    <span onClick={() => handleRemoveTask(task.id)}>
                      <DeleteIcon />
                    </span>
                  </li>
                ))}
              </ul>
            )}
            <div className="add-task" onClick={handleAddTask}>
              <PlusIcon />
              Add task
            </div>

            <SelectList
              label="Assets"
              addLabel="Add annother asset"
              placeholder="Enter link"
              name="assets"
              onChange={handleChange}
              preselected={goalData?.assets || []}
            />

            <label>Resources</label>
            {!!existingAttachments.length && (
              <ul className="selected-files">
                {existingAttachments.map((file, index) => (
                  <li key={index}>
                    <a
                      href={`${process.env.REACT_APP_BASE_API_URL}${file?.path}`}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      {" "}
                      {file?.title}
                    </a>

                    <span onClick={() => handleRemoveFile(file?.id)}>
                      <CloseIcon />
                    </span>
                  </li>
                ))}
              </ul>
            )}
            <div className="file-select">
              {!existingAttachments.length && (
                <div className="file-select-empty">
                  Add resource (max flie size 20MB)
                </div>
              )}
              <label>
                <PlusIcon />
                Add another resource
              </label>
              <input
                type="file"
                name="image"
                multiple
                onChange={changeHandler}
              />
            </div>

            <div className="form-group">
              <Input
                type="input"
                label="Notes"
                placeholder="Enter description"
                name="notes"
                value={goalData?.notes || ""}
                handleChange={handleChange}
              />
            </div>
          </Form>

          {/* <Modal.Footer>
            <Button variant="secondary" handleClick={handleCloseGoalModal}>
              Cancel
            </Button>
            <Button handleClick={saveGoal}>
              {showTaskModal ? "Continue" : "Submit"}
            </Button>
          </Modal.Footer> */}
        </>
      ) : (
        <CareerTaskForm
          handleCloseGoalModal={handleCloseGoalModal}
          handleCloseTaskModal={handleCloseTaskModal}
          taskFormType={taskFormType}
        />
      )}
    </Modal>
  );
};

export default CareerGoalForm;
