import { UserIcon } from "../ui";
import "./styles.scss";

const UsersImagesRow = ({ usersCollection, slug, limit = 3 }) => {
  const limitedUsersCollection = usersCollection.slice(0, limit);

  return (
    <div className="users-row-wrapper">
      <ul className="users-row">
        {limitedUsersCollection?.map((user) => (
          <li className="user-icon" key={user?.id}>
            <UserIcon
              customPath={`${process.env.REACT_APP_BASE_API_URL}${user[slug]}`}
              userName={user.first_name}
              iconStyle={{ width: "28px", height: "28px", fontSize: "18px" }}
            />
          </li>
        ))}
        {usersCollection?.length !== limitedUsersCollection?.length ? (
          <li className="user-count">
            +{usersCollection?.length - limitedUsersCollection?.length}
          </li>
        ) : (
          ""
        )}
      </ul>
      <ul className="user-full-name">
        {usersCollection?.map((user) => (
          <li className="user-icon" key={user?.id}>
            {user.first_name} {user.last_name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsersImagesRow;
