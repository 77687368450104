import { createSlice } from "@reduxjs/toolkit";

// Message object should look like this
// messages: [
//   {
//     type: error,
//     title: "",
//     message: "",
//   },
//   {...}
// ]

const toastMessagesSlice = createSlice({
  name: "toastMessages",
  initialState: {
    messages: [],
  },
  reducers: {
    setToastMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    removeToastMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message, index) => index !== action.payload
      );
    },
  },
});

export const { setToastMessage, removeToastMessage } =
  toastMessagesSlice.actions;

export const toastMessagesReducer = toastMessagesSlice.reducer;
