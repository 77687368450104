import React from "react";

const ResetIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.45385 4.29988L1 2.1L4.3 1L3.45385 4.29988Z" fill="#666666" />
      <path
        d="M6.61956 0.999999C9.60192 1.06361 12 3.5014 12 6.49936C12 9.53728 9.53757 12 6.5 12C3.46243 12 1 9.53728 1 6.49936C1 4.91563 1.66923 3.48821 2.7403 2.48456"
        stroke="#666666"
      />
    </svg>
  );
};

export default ResetIcon;
