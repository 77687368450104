import React from "react";
import { AddIcon } from "../../icons";
import { Button } from "../ui";

import DropdownSelect from "../DropdownSelect/DropdownSelect";

const Header = ({
  showForm,
  onViewChange,
  handleNext,
  handlePrev,
  date,
  view,
}) => {
  const dropdownOptions = [
    {
      label: "Day",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
    {
      label: "Month",
      value: "month",
    },
    {
      label: "Year",
      value: "year",
    },
  ];

  const weekFormat = () => {
    const startOfWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay()
    );
    const endOfWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay() + 6
    );
    const startDate = startOfWeek.toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
    });
    const endDate = endOfWeek.toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
    });
    const formattedDate = `${startDate} - ${endDate}`;
    return formattedDate;
  };
  const dateFormats = {
    day: date.toLocaleString("en-US", { day: "numeric", month: "short" }),
    week: weekFormat(),
    month: date.toLocaleString("en-US", { month: "short", year: "numeric" }),
    year: date.toLocaleString("en-US", { year: "numeric" }),
  };

  return (
    <div className="col-6 input-group">
      <div className="calendar-control">
        <Button handleClick={handlePrev} variant="control-prev"></Button>
        <Button handleClick={handleNext} variant="control-next"></Button>
        <h4>{dateFormats[view]}</h4>
      </div>
      <div>
        <DropdownSelect
          options={dropdownOptions}
          handleSelect={onViewChange}
          preselected={dropdownOptions[2]}
        />
      </div>
      <Button buttonStyles={{ borderRadius: "6px" }} handleClick={showForm}>
        <AddIcon />
        Add New Meeting
      </Button>
    </div>
  );
};

export default Header;
