import { useEffect, useState } from "react";
import { Modal, UserIcon } from "../../../ui";
import { CalendarLightIcon, Avatar } from "../../../../icons";
import { Button, ProgressBar, Input } from "../../../ui";
import { taskSimpleStatuses } from "./tableData";
import { subMonths, addMonths } from "../../../../utils/textFormat";

import {
  createTaskComment,
  fetchTaskComments,
  updateTask,
  updateTaskSettings,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useSelector } from "react-redux";

const TasksUpdateModal = ({ selectedTask, showModal, handleCloseModal }) => {
  const [task, setTask] = useState(null);
  const [commentPayload, setComment] = useState({
    addedBy: JSON.parse(localStorage.getItem("authUserData"))?.id,
    comment: "",
  });
  const [dueDate, setDueDate] = useState(new Date());

  const [doUpdateTask] = useThunk(updateTask);
  const [doUpdateTaskSettings] = useThunk(updateTaskSettings);
  const [doFetchComments] = useThunk(fetchTaskComments);
  const [doCreateComment] = useThunk(createTaskComment);

  const commentsState = useSelector((state) => state?.tasks)
    ?.selectedTaskComments?.data;

  useEffect(() => {
    if (!selectedTask) {
      return;
    }
    setTask(selectedTask);
  }, [selectedTask]);

  useEffect(() => {
    !!task && doFetchComments(task?.id);
  }, [task]);

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    if (name === "progress" && (value < 0 || value > 100)) {
      return;
    }
    if (name === "comment") {
      setComment({ ...commentPayload, comment: value });
      return;
    }
    setTask({ ...task, [name]: value });
    return;
  };

  const handleStatusChange = (status) => {
    setTask({ ...task, status });
  };
  const handleDueDateChange = (endDate) => {
    setTask({
      ...task,
      endDate: moment(endDate).format("DD.MM.yyyy"),
      // settings: {
      //   ...task.settings,
      //   endDate: moment(endDate, "DD.MM.yyyy").format("DD.MM.yyyy"),
      // },
    });
  };

  const handleSave = () => {
    doUpdateTask(task);
    const payload = {
      taskId: task?.id,
      data: {
        mentorId: task?.settings?.mentor?.id,
        frequency: task?.settings?.frequency,
        startDate: task?.settings?.startDate,
        endDate: task?.settings?.endDate,
      },
    };
    doUpdateTaskSettings(payload);
    !!commentPayload?.comment &&
      doCreateComment({ taskId: task?.id, comment: commentPayload });

    handleCloseModal();
  };

  const footerContent = <Button handleClick={handleSave}>Submit</Button>;
  return (
    <Modal
      show={showModal}
      handleClose={handleCloseModal}
      title="Task Update"
      footerContent={footerContent}
      handleConfirm={handleSave}
      className="tasks-modal"
    >
      <>
        <div className="modal-top">
          <div className="modal-flex">
            <h4>{task?.title}</h4>
            <p>{task?.description}</p>
          </div>
          <ProgressBar progress={task?.progress} />
        </div>

        <div className="modal-date">
          <div className="modal-date-element date-picker-holder">
            <CalendarLightIcon />
            <DatePicker
              selected={dueDate}
              onChange={handleDueDateChange}
              dateFormat="dd.MM.yyyy"
              minDate={subMonths(new Date(), 256)}
              maxDate={addMonths(new Date(), 256)}
              dateFormatCalendar={"MMM yyyy"}
              placeholderText="Enter due date"
              showMonthDropdown
              showYearDropdown
              value={task?.endDate || task?.settings?.endDate || task?.dueDate}
            />
          </div>
          <Avatar />
        </div>

        <div className="modal-task-options">
          <div className="option-wrapper">
            <label>What is the status of your task?</label>
            <ul>
              {taskSimpleStatuses?.map((status, index) => (
                <li
                  key={index}
                  className={`${status.id === task?.status ? "active" : ""}`}
                  onClick={() => handleStatusChange(status.id)}
                >
                  {status?.label}
                </li>
              ))}
            </ul>
          </div>
          <div className="option-wrapper">
            <label>What is the progress?</label>
            <div className="input-wrapper">
              <div className="custom-input">
                <label>Current</label>
                <Input
                  name="progress"
                  type="number"
                  placeholder="Enter %"
                  value={task?.progress || 0}
                  handleChange={handleChange}
                />
              </div>
              <div className="custom-input disabled-input">
                <label>Target</label>
                <Input placeholder="100%" disabled value={""} />
              </div>
            </div>
          </div>
        </div>

        <div className="modal-comments">
          {!!commentsState && !!commentsState.length !== 0 ? (
            commentsState.map((comment, index) => (
              <div className="single-comment" key={index}>
                <div className="comment-header">
                  <div className="user">
                    <UserIcon
                      userImage={comment?.user?.image}
                      userName={comment?.user?.name}
                    />
                    <p>{comment?.user?.name}</p>
                  </div>
                  <p>{comment?.created_at}</p>
                </div>
                <div className="comment-body">
                  <p>{comment?.comment}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No comments yet</p>
          )}
        </div>

        <div className="modal-textbox">
          <label>Comment</label>
          <Input
            type="textarea"
            name="comment"
            placeholder="Enter text"
            value={commentPayload?.comment || ""}
            handleChange={handleChange}
          />
        </div>
      </>
    </Modal>
  );
};

export default TasksUpdateModal;
