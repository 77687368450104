import { createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchAllHighFives = createAsyncThunk(
  "highFives/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/highfives`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleHighFive = createAsyncThunk(
  "highFives/fetch/single",
  async (highFiveId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/highfive/${highFiveId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUsersHighFive = createAsyncThunk(
  "highFives/fetch/user",
  async (payload) => {
    const filters = payload?.filters?.reduce((acc, { name, value }) => {
      if (!!value && value !== "All hashtags") {
        acc[name] = value;
      }
      return acc;
    }, {});
    try {
      const response = await axios.get(
        `${API_BASE_URL}/highfives/user/${payload.userId}`,
        {
          params: filters ? filters : "",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTopHighFivers = createAsyncThunk(
  "highFives/fetch/TopHighFivers",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/highfives/tophighfivers`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMostApprecieated = createAsyncThunk(
  "highFives/fetch/mostAppreciated",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/highfives/mostappreciated`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchHashtagList = createAsyncThunk(
  "highFives/fetch/hashtagList",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/highfives/hashtags`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createHighFive = createAsyncThunk(
  "highFives/create",
  async (payload, { dispatch }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/highfive`, payload, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      dispatch(fetchMostApprecieated());
      dispatch(fetchTopHighFivers());
      dispatch(fetchHashtagList());

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
