import React, { useEffect, useState } from "react";
import { Offcanvas, Pagination, Table } from "../ui";
import "./Support.scss";
import { useNavigate } from "react-router-dom";
import db from "../../firebase";
import { useStateValue } from "../../StateProvider";
import UserInfo from "./UserInfo.js";

function Support() {
  const [teams, setTeams] = useState(null);

  const navigate = useNavigate();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [currentPageTeams, setCurrentPageTeams] = useState(1);
  const [pageTeam, setPageTeam] = useState(1);
  const [teamPageData, setTeamPageData] = useState(null);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [activeTeam, setActiveTeam] = useState(null);
  const getTeams = fetch(
    `${process.env.REACT_APP_BASE_API_URL}/teams?page=${currentPageTeams}`
  ).then(function (response) {
    return response.json();
  });
  const [{ user }] = useStateValue();
  useEffect(async () => {
    let totalTeams = getTeams.then(function (jsonData) {
      return jsonData;
    });
    totalTeams.then(function (result) {
      //console.log(result);
      setTeams(result.teamData);
      setTeamPageData(result.pageData);
    });
  }, []);
  const selectChat = (email, name) => {
    //console.log(email);
    let otherUserUid = "";

    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (user.uid.charAt(0) > doc.data().idd.charAt(0)) {
            navigate("/chat/" + user.uid + doc.data().idd + "/" + name);
          } else {
            navigate("/chat/" + doc.data().idd + user.uid + "/" + name);
          }
        });
      })
      .catch((error) => {
        //console.log("Error getting documents: ", error);
      });
  };
  const getNewDataTeam = (page) => {
    setPageTeam(page);
    setCurrentPageTeams(page);
    //console.log(page);
    //console.log("force");
    forceUpdate();
    fetch(`${process.env.REACT_APP_BASE_API_URL}/teams?page=${page}`)
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        return jsonData;
      })
      .then(function (result) {
        //console.log("tu sam");
        setTeams(result.teamData); // "Some User token"
        setTeamPageData(result.pageData);
      });
  };

  const handleShowUserInfo = (team) => {
    setActiveTeam(team);
    setShowUserInfo(true);
  };
  const handleHideUserInfo = () => {
    setShowUserInfo(false);
  };

  const handleDirect = (email, name) => {
    selectChat(email, name);
  };

  const tableData = {
    columns: [
      {
        id: 1,
        label: "Name",
        slug: "name",
      },
      {
        id: 2,
        label: "Team lead",
        slug: "lead",
      },
    ],
    rows: teams?.map((team, index) => ({
      id: index,
      name: team.name,
      lead: (
        <span
          onClick={() => handleShowUserInfo(team)}
          className={team.lead?.email === user.email ? "current-user" : ""}
          style={{ cursor: "pointer" }}
        >
          {team.lead?.first_name} {team.lead?.last_name}
        </span>
      ),
    })),
  };

  return (
    <div className="table-wrapper" style={{ maxHeight: "calc(100vh - 90px)" }}>
      <h2>Companys teams</h2>
      <Table columns={tableData.columns} rows={tableData.rows} />
      <Offcanvas
        show={showUserInfo}
        position="end"
        scroll={true}
        backdrop={true}
        onHide={handleHideUserInfo}
        className="user-info-offcanvas"
      >
        <UserInfo team={activeTeam} handleDirect={handleDirect} />
      </Offcanvas>

      <Pagination
        currentPage={pageTeam}
        total={teamPageData?.total}
        onPageChange={getNewDataTeam}
        limit={teamPageData?.perPage}
        position="center"
      />
    </div>
  );
}

export default Support;
