import { createSlice } from "@reduxjs/toolkit";
import { fetchTagsCategories } from "./tagsThunks";

const tagsSlice = createSlice({
  name: "tags",
  initialState: {
    tagsCategories: [],
  },

  extraReducers(builder) {
    builder.addCase(fetchTagsCategories.fulfilled, (state, action) => {
      state.tagsCategories = action.payload;
    });
  },
});

export const { setSurveyCreator, resetSurveyMessage } = tagsSlice.actions;

export const tagsReducer = tagsSlice.reducer;
