const ArrowUpIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.04922 16.5501L0.449219 14.9501L12.5242 2.8751H5.72422V0.600098H16.3992V11.2751H14.1242V4.4751L2.04922 16.5501Z"
      fill="#007A5A"
    />
  </svg>
);
export default ArrowUpIcon;
