import { useSelector, useDispatch } from "react-redux";
import { setSearchTerm } from "../../store/pageViews/pageViewsSlice";

import { Button, Input } from "../ui";
import { SearchIcon, AddIcon } from "../../icons";

import "./styles.scss";
import { Outlet, useNavigate } from "react-router-dom";
import usePageTitle from "../../hooks/usePageTitle";

const Survey = () => {
  const title = usePageTitle();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const pageViewState = useSelector((state) => state.pageViews)?.activeView;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;

  const handleSearch = (event) => {
    dispatch(setSearchTerm(event.target.value));
  };

  const handleNewSurveyClick = () => {
    navigate("/survey/new-survey");
  };

  const pageData = {
    actionLabel: `Add ${title?.slice(0, -1)}`,
  };

  return (
    <div className="surveys-wrapper">
      {pageViewState?.subview !== "survey-overview" &&
      pageViewState?.subview !== "survey-questions" ? (
        <div className="surveys-header">
          <h2>{title}</h2>
          <div className="header-actions">
            <div className="table-search">
              <Input
                icon={<SearchIcon />}
                placeholder="Search"
                variant="form-control light"
                value={searchTerm}
                handleChange={handleSearch}
              />
            </div>
            {!!pageData && localUserData?.role === 1 && (
              <Button handleClick={handleNewSurveyClick}>
                <AddIcon />
                <span>New Survey</span>
              </Button>
            )}
          </div>
        </div>
      ) : (
        " "
      )}
      <div className="module-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Survey;
