import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StarBorderOutlineIcon from "@material-ui/icons/StarBorderOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import db, { auth } from "../../firebase";
import Message from "../Message/Message";
import SidebarOption from "../Sidebar/SidebarOption/SidebarOption";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signOut,
} from "firebase/auth";
import { useStateValue } from "../../StateProvider";
import { omit } from "lodash";
import moment from "moment";
import { Button } from "../ui";

function Profile() {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [currentUser, setCurrentUser] = useState(false);
  const [changedUser, setChangedUser] = useState(false);
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  let eighteenYearsAgo = new Date();
  let eighteenYearsAgos = eighteenYearsAgo.setFullYear(
    eighteenYearsAgo.getFullYear() - 18
  );
  const limitDate = moment(eighteenYearsAgos).format("YYYY-MM-DD");
  const getUser = fetch(
    `${process.env.REACT_APP_BASE_API_URL}/users/` + localUserData.id
  ).then(function (response) {
    return response.json();
  });
  useEffect(async () => {
    let locUser = getUser.then(function (jsonData) {
      return jsonData;
    });
    locUser.then(function (result) {
      setCurrentUser(result); // "Some User token"
    });
  }, []);
  const [errors, setErrors] = useState({});
  const validate = (event, name, value) => {
    //A function to validate each input values

    switch (name) {
      case "first_name":
        if (value.length <= 1) {
          // we will set the error state

          setErrors({
            ...errors,
            username: "First name at least have 1 letter",
          });
        } else {
          // set the error state empty or remove the error for username input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "first_name");
          setErrors(newObj);
        }
        break;
      case "last_name":
        if (value.length <= 1) {
          // we will set the error state

          setErrors({
            ...errors,
            username: "Last name at least have 1 letter",
          });
        } else {
          // set the error state empty or remove the error for username input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "last_name");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "password":
        if (
          !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
        ) {
          setErrors({
            ...errors,
            password:
              "Password should contains at least 8 characters and containing uppercase,lowercase and numbers",
          });
        } else {
          let newObj = omit(errors, "password");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    // validate(e,name,value);
    const newVal = type === "checkbox" ? checked : value;
    setChangedUser({ ...changedUser, [name]: newVal });
  };
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };
  const saveUser = (event, user) => {
    const formData = new FormData();
    formData.append(
      "first_name",
      changedUser.first_name ? changedUser.first_name : ""
    );
    formData.append(
      "last_name",
      changedUser.last_name ? changedUser.last_name : ""
    );
    formData.append("active", 1);
    formData.append(
      "birthday",
      changedUser.birthday ? changedUser.birthday : ""
    );
    formData.append("phone", changedUser.phone ? changedUser.phone : "");

    if (isFilePicked) {
      formData.append("image", selectedFile);
    }

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/users/` + user.id,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        //console.log(json);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    setTimeout(() => {
      //console.log("force");
      forceUpdate();
      fetch(`${process.env.REACT_APP_BASE_API_URL}/users/` + user.id)
        .then(function (response) {
          return response.json();
        })
        .then(function (jsonData) {
          return jsonData;
        })
        .then(function (result) {
          setCurrentUser(result); // "Some User token"
          //console.log(user);
          //console.log("poslednji");
        });
    }, 1000);
  };
  return (
    <div className="container rounded bg-white mt-5 mb-5">
      <div className="row">
        <div className="col-md-3 border-right">
          <div className="d-flex flex-column align-items-center text-center p-3 py-5">
            <img
              className="rounded-circle mt-5"
              width="150px"
              src={
                `${process.env.REACT_APP_BASE_API_URL}/images/` +
                  currentUser.image ?? currentUser.photoURL
              }
            />
            <span className="font-weight-bold">
              {user?.first_name} {user?.last_name}
            </span>
            <span className="text-black-50">{user?.email}</span>
            <span> </span>
          </div>
        </div>
        <div className="col-md-5 border-right">
          <div className="p-3 py-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="text-right">Profile Settings</h4>
            </div>
            <div className="row mt-2">
              <div className="col-md-6">
                <label className="labels">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder="first name"
                  defaultValue={currentUser?.first_name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label className="labels">Surname</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={currentUser?.last_name}
                  placeholder="last_name"
                  name="last_name"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label className="labels">Phone</label>
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  placeholder="enter phone number"
                  defaultValue={
                    currentUser.phone != "null" ? currentUser.phone : ""
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12">
                <label className="labels">Birthday</label>
                <input
                  className="form-control"
                  type="date"
                  name="birthday"
                  max={limitDate}
                  placeholder="Enter birthday"
                  defaultValue={currentUser?.birthday}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12">
                <label className="labels">
                  New Profile Image (Maximum size 2MB 2048kB)
                </label>
                <input
                  className="form-control"
                  type="file"
                  placeholder="Select a profile image to upload"
                  name="image"
                  onChange={changeHandler}
                />
              </div>
              <div className="col-md-12">
                <label className="labels">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="enter email"
                  defaultValue={currentUser.email}
                  disabled
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12">
                <label className="labels">Team</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="enter email"
                  defaultValue={currentUser?.team?.name}
                  disabled
                />
              </div>
              <div className="col-md-12">
                <label className="labels">Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="position"
                  disabled
                  defaultValue={currentUser.position}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-5 text-center">
              <Button handleClick={(event) => saveUser(event, currentUser)}>
                Save Profile
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
