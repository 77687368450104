import { useEffect, useState, useRef } from "react";
import SurveysReview from "./SurveysReview";
import "./styles.scss";
import GoalsReview from "./GoalsReview";

const GoalsAndSurveys = ({ isExpanded }) => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view">
        <GoalsReview localUserData={localUserData} />
        <hr />
        <SurveysReview localUserData={localUserData} />
      </div>
    </div>
  );
};

export default GoalsAndSurveys;
