const SandClockIcon = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66683 15.666H10.3335V13.166C10.3335 12.2493 10.0071 11.4646 9.35433 10.8118C8.70155 10.1591 7.91683 9.83268 7.00016 9.83268C6.0835 9.83268 5.29877 10.1591 4.646 10.8118C3.99322 11.4646 3.66683 12.2493 3.66683 13.166V15.666ZM7.00016 8.16602C7.91683 8.16602 8.70155 7.83963 9.35433 7.18685C10.0071 6.53407 10.3335 5.74935 10.3335 4.83268V2.33268H3.66683V4.83268C3.66683 5.74935 3.99322 6.53407 4.646 7.18685C5.29877 7.83963 6.0835 8.16602 7.00016 8.16602ZM0.333496 17.3327V15.666H2.00016V13.166C2.00016 12.3188 2.19808 11.5237 2.59391 10.7806C2.98975 10.0375 3.54183 9.44379 4.25016 8.99935C3.54183 8.5549 2.98975 7.96115 2.59391 7.2181C2.19808 6.47504 2.00016 5.6799 2.00016 4.83268V2.33268H0.333496V0.666016H13.6668V2.33268H12.0002V4.83268C12.0002 5.6799 11.8022 6.47504 11.4064 7.2181C11.0106 7.96115 10.4585 8.5549 9.75016 8.99935C10.4585 9.44379 11.0106 10.0375 11.4064 10.7806C11.8022 11.5237 12.0002 12.3188 12.0002 13.166V15.666H13.6668V17.3327H0.333496Z"
      fill="#00B082"
    />
  </svg>
);
export default SandClockIcon;
