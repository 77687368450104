const DotsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="10" r="2" transform="rotate(90 16 10)" fill="#666666" />
    <circle cx="10" cy="10" r="2" transform="rotate(90 10 10)" fill="#666666" />
    <circle cx="4" cy="10" r="2" transform="rotate(90 4 10)" fill="#666666" />
  </svg>
);
export default DotsIcon;
