import { Routes, Route } from "react-router-dom";

import ClientSurveyTable from "../components/Survey/ClientSurvey/ClientSurveyTable";
import ClientSurvey from "../components/Survey/ClientSurvey/ClientSurvey";

const SurveyRoutes = () => (
  <Routes>
    <Route path="/" element={<ClientSurveyTable />} />
    <Route path="questions" element={<ClientSurvey />} />
  </Routes>
);

export default SurveyRoutes;
