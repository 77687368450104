import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchTasks = createAsyncThunk("tasks/fetch", async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tasks`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchSingleTask = createAsyncThunk(
  "tasks/fetch/single",
  async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/tasks/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleUserTask = createAsyncThunk(
  "tasks/fetch/user/single",
  async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/tasks/user/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUsersTask = createAsyncThunk(
  "tasks/fetch/user",
  async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/tasks-user/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchProjectsTasks = createAsyncThunk(
  "tasks/fetch/projects",
  async ({
    page = 1,
    searchTerm = "",
    status = null,
    sort = "asc",
    startDate,
    endDate,
  }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/tasks-project`, {
        params: {
          page,
          searchTerm,
          status,
          sort,
          from: startDate,
          to: endDate,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createTask = createAsyncThunk("task/create", async (task) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/tasks/create`, task, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateTask = createAsyncThunk("task/update", async (task) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/tasks/${task.id}`, task);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteTask = createAsyncThunk("task/delete", async (taskId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/tasks/${taskId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateTaskStatus = createAsyncThunk(
  "task/delete",
  async (taskId) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/tasks/${taskId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createTaskSettings = createAsyncThunk(
  "task/create/settings",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/tasks/${payload.taskId}/settings`,
        payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateTaskSettings = createAsyncThunk(
  "task/update/settings",
  async (payload) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/tasks/${payload.taskId}/settings`,
        payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createTaskStatus = createAsyncThunk(
  "task/create/status",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/tasks/${payload.taskId}/status`,
        payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createTaskComment = createAsyncThunk(
  "task/create/comment",
  async (payload) => {
    try {
      const { taskId, comment } = payload;
      const response = await axios.post(
        `${API_BASE_URL}/tasks/${taskId}/set-comment`,
        comment
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTaskComments = createAsyncThunk(
  "task/fetch/comments",
  async (taskId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/tasks/${taskId}/comments`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
