import React from "react";
import "./UserHeader.css";

import { PhoneIcon, QuestionIcon } from "../../../icons";
import { UserIcon } from "../../ui";

function UserHeader({ activeUser, handleUserInfo, handleGroupUsers }) {
  const handleClick = () => {
    if (activeUser.type) {
      handleGroupUsers();
      return;
    }
    handleUserInfo();
  };
  return (
    <div className="chat-user-header grid-item">
      <div className="header-user" onClick={handleClick}>
        <UserIcon userImage={activeUser?.photo} userName={activeUser?.name} />
        <div className="header-user-text">
          <h4>{activeUser?.name}</h4>
        </div>
      </div>
      {/* <div className="user-contact">
        <button>
          <PhoneIcon />
        </button>
        <button>
          <QuestionIcon />
        </button>
      </div> */}
    </div>
  );
}

export default UserHeader;
