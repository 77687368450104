export const careerPathViews = [
  { id: 1, label: "Organisation Hierarchy", slug: "organisation" },
  { id: 2, label: "Position", slug: "position/table" },
  { id: 3, label: "Employees", slug: "employees" },
  { id: 4, label: "Feedbacks", slug: "feedbacks" },
];

export const careerPathClientViews = [
  { id: 1, label: "Home", slug: "home" },
  { id: 2, label: "High Fives", slug: "high-fives" },
  { id: 3, label: "Check-ins", slug: "check-ins" },
];

export const administrationViews = [
  { id: 1, label: "Teams", slug: "teams" },
  { id: 2, label: "Users", slug: "users" },
  { id: 3, label: "Achievements", slug: "achievements" },
  { id: 4, label: "Roles", slug: "roles" },
];

export const projectsViews = [
  { id: 1, label: "My Tasks Overview", slug: "my-tasks-overview" },
  { id: 2, label: "Projects", slug: "projects-overview" },
];

export const surveyViews = [
  { id: 1, label: "Ongoing surveys", slug: "ongoing" },
  { id: 2, label: "Completed", slug: "completed" },
  { id: 3, label: "Drafts", slug: "drafts" },
  { id: 4, label: "Archived", slug: "archived" },
  { id: 5, label: "My surveys", slug: "user-surveys" },
];

export const reportsViews = [
  { id: 1, label: "Chat", slug: "chat" },
  { id: 2, label: "Communication", slug: "communication" },
];
