const DoubleArrow = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.65 0.475195L15.25 2.10019L10.3 7.02519L15.25 11.9752L13.65 13.5752L7.1 7.02519L13.65 0.475195ZM7.25 0.475194L8.825 2.10019L3.875 7.02519L8.825 11.9752L7.25 13.5752L0.7 7.02519L7.25 0.475194Z"
      fill="white"
    />
  </svg>
);

export default DoubleArrow;
