import { Routes, Route } from "react-router-dom";
import Organisation from "../components/CareerPath/modules/organisation/Organisation";
import Position from "../components/CareerPath/modules/position/Position";
import PositionTable from "../components/CareerPath/modules/position/PositionTable";
import PositionForm from "../components/CareerPath/modules/position/PositionForm";
import Employees from "../components/CareerPath/modules/employees/Employees";
import Feedback from "../components/CareerPath/modules/feedback/Feedback";
import CareerPath from "../components/CareerPath";

const CareerPathRoutes = () => (
  <Routes>
    <Route path="/" element={<CareerPath />}>
      <Route path="organisation" element={<Organisation />} />
      <Route path="position" element={<Position />}>
        <Route path="table" element={<PositionTable />} />
        <Route path="form" element={<PositionForm />} />
      </Route>
      <Route path="employees" element={<Employees />} />
      <Route path="feedbacks" element={<Feedback />} />
    </Route>
  </Routes>
);

export default CareerPathRoutes;
