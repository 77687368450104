import React from "react";

const FilterIcon = ({ height = "13", width = "16", iconFill = "#666666" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="3"
        height="1"
        rx="0.5"
        transform="matrix(-1 0 0 1 16 6)"
        fill="#666666"
      />
      <rect
        width="11"
        height="1"
        rx="0.5"
        transform="matrix(-1 0 0 1 11 6)"
        fill="#666666"
      />
      <path
        d="M11.1471 6.53125C11.1471 7.26785 11.7019 7.8125 12.3235 7.8125C12.9452 7.8125 13.5 7.26785 13.5 6.53125C13.5 5.79465 12.9452 5.25 12.3235 5.25C11.7019 5.25 11.1471 5.79465 11.1471 6.53125Z"
        stroke="#666666"
      />
      <rect
        width="3"
        height="1"
        rx="0.5"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 0 3)"
        fill="#666666"
      />
      <rect
        width="11"
        height="1"
        rx="0.5"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 5 3)"
        fill="#666666"
      />
      <path
        d="M4.85294 2.46875C4.85294 1.73215 4.29809 1.1875 3.67647 1.1875C3.05485 1.1875 2.5 1.73215 2.5 2.46875C2.5 3.20535 3.05485 3.75 3.67647 3.75C4.29809 3.75 4.85294 3.20535 4.85294 2.46875Z"
        stroke="#666666"
      />
      <rect
        width="7"
        height="1"
        rx="0.5"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 0 11)"
        fill="#666666"
      />
      <rect
        width="7"
        height="1"
        rx="0.5"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 9 11)"
        fill="#666666"
      />
      <path
        d="M8.85294 10.7813C8.85294 10.0446 8.29809 9.5 7.67647 9.5C7.05485 9.5 6.5 10.0446 6.5 10.7813C6.5 11.5179 7.05485 12.0625 7.67647 12.0625C8.29809 12.0625 8.85294 11.5179 8.85294 10.7813Z"
        stroke="#666666"
      />
    </svg>
  );
};

export default FilterIcon;
