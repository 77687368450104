import { Table } from "../../../ui";

const EmployeesTable = ({ employees }) => {
  const tableData = {
    columns: [
      {
        label: "#",
        slug: "id",
      },
      {
        label: "Employee",
        slug: "employee",
      },
      {
        label: "Level",
        slug: "level",
      },
      {
        label: "Experience (in years)",
        slug: "experience",
      },
      {
        label: "Time in position (in years)",
        slug: "timeInPosition",
      },
    ],
    rows: employees.map((employee) => ({
      id: employee.id,
      employee: `${employee.firstName} ${employee.lastName}`,
      level: employee.levelOfSeniority,
      experience: employee.experienceYears,
      timeInPosition: employee.timeInPositionYears,
    })),
  };

  return (
    <div className="career-content">
      <Table rows={tableData.rows} columns={tableData.columns} />
    </div>
  );
};

export default EmployeesTable;
