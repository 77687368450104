import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useState } from "react";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBavYnPAW1J3vpCOoUNMp7Zqv_swTJvJRM",
  authDomain: "hr-app-2022.firebaseapp.com",
  databaseURL:
    "https://hr-app-2022-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hr-app-2022",
  storageBucket: "hr-app-2022.appspot.com",
  messagingSenderId: "1099326159423",
  appId: "1:1099326159423:web:afee81ada6ec08e48bd68a",
  measurementId: "G-4K6H7Z5V7K",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BM2dYDd-K6cqpMVxcNKRr3osNJXqS1pMy5zkFx-VpJOaz-DckdmfJeUsBlaL-YF9zCuDCegf2sGHeLRYWHi2JFE",
  })
    .then((currentToken) => {
      if (currentToken) {
        //console.log('current token for client: ', currentToken);

        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        //console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      //console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { auth, provider, db, messaging };
export default db;
