const ReviewIcon = () => (
  <svg
    width="25"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.838 1.00145C16.07 1.00145 19.301 1.00041 22.5335 1.00145C23.9119 1.00196 24.8175 1.88905 24.8186 3.27206C24.8263 7.45257 24.8248 11.6336 24.8201 15.8146C24.8181 17.2064 23.9449 18.0883 22.5511 18.0956C20.3289 18.1074 18.1063 18.1054 15.8832 18.0919C15.6061 18.0894 15.4549 18.1797 15.3083 18.4078C14.6746 19.3986 14.0234 20.377 13.3747 21.358C13.0165 21.9004 12.6605 21.8993 12.3013 21.3565C11.6459 20.3651 10.9874 19.3764 10.3455 18.3763C10.2123 18.1699 10.0715 18.0925 9.82684 18.094C7.60422 18.1038 5.3816 18.1038 3.15898 18.0976C1.99168 18.094 1.17426 17.4938 0.916752 16.4555C0.863083 16.2378 0.856891 16.004 0.855859 15.778C0.852246 11.6331 0.875469 7.48766 0.841409 3.34327C0.829024 1.87718 1.96072 0.971516 3.21472 0.987513C6.42196 1.02931 9.62971 1.00145 12.838 1.00145ZM12.8385 20.3156C13.4743 19.3485 14.0765 18.4527 14.6555 17.5398C14.863 17.2126 15.1055 17.0712 15.5065 17.0727C17.8488 17.0908 20.1927 17.0831 22.5356 17.081C23.3499 17.0805 23.803 16.6248 23.803 15.8089C23.804 11.6393 23.804 7.46908 23.803 3.29993C23.803 2.48096 23.353 2.02064 22.5382 2.02064C16.0736 2.01909 9.60856 2.01909 3.14402 2.02064C2.31989 2.02064 1.87196 2.47322 1.87196 3.29219C1.87093 7.46134 1.87093 11.632 1.87196 15.8007C1.87196 16.6387 2.31886 17.08 3.16724 17.081C5.23401 17.0825 7.30079 17.0707 9.36756 17.0841C11.0117 17.0965 10.522 16.8488 11.4431 18.1988C11.9081 18.8799 12.3514 19.5756 12.8385 20.3156Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.34095 13.53C5.45913 12.823 5.57472 12.0396 5.72954 11.265C5.77288 11.0467 5.7027 10.9332 5.56492 10.8016C5.05351 10.3114 4.55449 9.80822 4.03948 9.32159C3.72056 9.02073 3.58742 8.66621 3.7185 8.24976C3.85628 7.81008 4.2041 7.62327 4.64325 7.56496C5.35643 7.47052 6.07013 7.3766 6.77815 7.25585C6.90251 7.23469 7.04701 7.10464 7.10893 6.98647C7.43714 6.3605 7.75038 5.72679 8.05175 5.08741C8.24372 4.68076 8.53323 4.42222 8.99096 4.42532C9.45076 4.42842 9.73304 4.69934 9.92346 5.10186C10.2217 5.72937 10.5407 6.3476 10.8379 6.97563C10.9313 7.17225 11.0567 7.25327 11.275 7.27907C11.9887 7.36422 12.7008 7.47001 13.4114 7.57889C13.8217 7.64185 14.1365 7.85653 14.2526 8.2673C14.3666 8.66827 14.2479 9.01764 13.9388 9.31127C13.4161 9.80616 12.898 10.3078 12.3969 10.8248C12.3014 10.9234 12.2359 11.1143 12.2545 11.2485C12.3479 11.9245 12.4696 12.5975 12.5889 13.2699C12.6694 13.7199 12.6513 14.141 12.2421 14.4367C11.839 14.7282 11.4381 14.6462 11.0288 14.4201C10.4313 14.0883 9.82541 13.7704 9.21183 13.4716C9.0885 13.4118 8.8862 13.4102 8.7639 13.4696C8.08581 13.8014 7.42785 14.174 6.7508 14.5079C6.03968 14.8578 5.32083 14.3882 5.34095 13.53ZM8.98322 5.51883C8.6354 6.22478 8.29946 6.87345 7.99396 7.53606C7.7798 7.99947 7.43766 8.22086 6.93864 8.27865C6.23217 8.36071 5.52983 8.4794 4.88115 8.57435C5.36727 9.09298 5.83791 9.63586 6.35395 10.1328C6.73377 10.4971 6.84626 10.895 6.74718 11.3977C6.61095 12.0881 6.50619 12.7843 6.3746 13.5552C7.06713 13.1925 7.70084 12.8854 8.30875 12.5345C8.77474 12.2656 9.19738 12.2682 9.66131 12.5319C10.2728 12.8797 10.9045 13.1904 11.5913 13.5516C11.5057 13.0485 11.4298 12.615 11.3596 12.181C11.0897 10.5111 10.9643 10.7768 12.158 9.61625C12.4975 9.28598 12.8206 8.9392 13.1715 8.57848C12.4144 8.47166 11.692 8.36432 10.9674 8.2704C10.5149 8.21157 10.1949 8.00308 9.99726 7.57631C9.68453 6.90442 9.34291 6.24491 8.98322 5.51883Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9271 7.71256C19.7192 7.71256 20.5124 7.70998 21.3051 7.71308C21.7164 7.71463 21.9584 7.90402 21.9651 8.21881C21.9734 8.52998 21.7298 8.73744 21.3221 8.73847C19.7007 8.74311 18.0798 8.74311 16.4578 8.73847C16.0336 8.73795 15.8055 8.55372 15.8009 8.23584C15.7947 7.90918 16.0434 7.7136 16.4764 7.71153C17.2933 7.71102 18.1102 7.71256 18.9271 7.71256Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8635 11.3273C18.0466 11.3273 17.2297 11.3325 16.4138 11.3258C16.0371 11.3227 15.8229 11.1472 15.8018 10.8546C15.7796 10.561 16.0067 10.3112 16.3421 10.3097C18.0347 10.3025 19.7289 10.3025 21.4221 10.3092C21.7472 10.3102 21.965 10.5347 21.9655 10.819C21.9665 11.0977 21.7487 11.3186 21.4216 11.3222C20.569 11.333 19.716 11.3258 18.8635 11.3258V11.3273Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8702 13.9167C18.0771 13.9167 17.2844 13.9192 16.4923 13.9161C16.0387 13.9146 15.8044 13.7407 15.8013 13.4145C15.7982 13.0889 16.0444 12.9011 16.4814 12.8995C18.0905 12.899 19.699 12.8985 21.3086 12.8995C21.7224 12.9011 21.9614 13.0874 21.965 13.4047C21.9691 13.7175 21.7297 13.9136 21.3189 13.9156C20.5035 13.9197 19.6856 13.9167 18.8702 13.9167Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7773 6.15499C17.3221 6.15499 16.8649 6.16376 16.4087 6.15241C16.0217 6.1426 15.7972 5.94031 15.8013 5.63275C15.8044 5.32673 16.0372 5.12392 16.417 5.12134C17.3417 5.11515 18.267 5.11515 19.1912 5.12134C19.5654 5.12392 19.8053 5.33912 19.8038 5.641C19.8012 5.93928 19.5643 6.14467 19.183 6.15241C18.7149 6.16273 18.2464 6.15499 17.7773 6.15499Z"
      fill="white"
    />
  </svg>
);
export default ReviewIcon;
