import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchSupportCases = createAsyncThunk(
  "supportCases/fetch",
  async ({ page = 1, searchTerm = "" }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/cases`, {
        params: {
          page,
          searchTerm,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createSupportCase = createAsyncThunk(
  "supportCases/create",
  async (payload) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/cases`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateSupportCase = createAsyncThunk(
  "supportCases/update",
  async (payload) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/cases/${payload.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteSupportCase = createAsyncThunk(
  "supportCases/delete",
  async (id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/cases/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
