import { useEffect } from "react";
import { Button, Input } from "../ui";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { setSearchTerm } from "../../store/pageViews/pageViewsSlice";

import { SearchIcon, AddIcon } from "../../icons";
import { useLocation, Outlet } from "react-router-dom";
import usePageTitle from "../../hooks/usePageTitle";
import { setFormData, setFormType } from "../../store/form/formSlice";
import { setActiveModal } from "../../store/modal/modalSlice";

const Administration = () => {
  const title = usePageTitle();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const location = useLocation();
  const dispatch = useDispatch();
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const handleSearch = (event) => {
    dispatch(setSearchTerm(event.target.value));
  };

  const pageData = {
    actionLabel: `Add ${title?.slice(0, -1)}`,
    isAdmin: localUserData?.role === 1,
  };

  const handleShowAdd = () => {
    dispatch(setFormData(null));
    dispatch(setActiveModal(`${title}-modal`));
    dispatch(setFormType("create"));
  };

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, [location, dispatch]);

  return (
    <div className="administration-wrapper">
      <div className="administration-header">
        <h2>{title}</h2>
        <div className="header-actions">
          <div className="table-search">
            <Input
              placeholder="Search"
              variant="form-control light"
              value={searchTerm}
              handleChange={handleSearch}
              icon={<SearchIcon />}
            />
          </div>

          {pageData?.isAdmin && (
            <Button handleClick={handleShowAdd}>
              <AddIcon />
              <span>{pageData?.actionLabel}</span>
            </Button>
          )}
        </div>
      </div>
      <div className="module-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Administration;
