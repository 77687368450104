import { CalendarLightIcon } from "../../../../icons";
import { taskStatuses } from "../../../../data/statuses";

export const taskSimpleStatuses = [
  { id: 1, label: "On Track", color: "green" },
  { id: 2, label: "Locked", color: "purple" },
  { id: 3, label: "Feature Creep", color: "blue" },
];

export const tableColumns = [
  {
    id: 1,
    label: "Task",
    slug: "title",
  },
  {
    id: 2,
    label: "Project",
    slug: "projectTitle",
  },
  {
    id: 3,
    label: "Description",
    slug: "description",
  },
  {
    id: 4,
    label: "Status",
    slug: "status",
    colorOptions: taskStatuses,
  },
  {
    id: 5,
    label: "Due Date",
    slug: "dueDate",
    icon: <CalendarLightIcon />,
  },
  {
    id: 6,
    label: "Progress",
    slug: "progressComponent",
  },
];
