import { useState, useEffect } from "react";
import { Checkbox, OutsideClick } from "../../ui";
import { DatePickerIcon } from "../../../icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

const CustomRecurring = ({ onChange = () => {}, preselected }) => {
  const [showRepeat, setShowRepeat] = useState(false);
  const [customRecurring, setCustomRecurring] = useState({
    interval: 1,
    period: 1,
    days: [],
    ends: {
      never: true,
    },
  });

  useEffect(() => {
    if (preselected) {
      setCustomRecurring(preselected);
    }
  }, [preselected]);

  const days = [
    { id: 1, label: "Sunday" },
    { id: 2, label: "Monday" },
    { id: 3, label: "Tuesday" },
    { id: 4, label: "Wednesday" },
    { id: 5, label: "Thursday" },
    { id: 6, label: "Friday" },
    { id: 7, label: "Saturday" },
  ];

  const repeat = [
    { id: 1, label: "Week" },
    { id: 2, label: "Month" },
    { id: 3, label: "Year" },
  ];

  const handleSelectDay = (selectedDay) => {
    setCustomRecurring((prevState) => {
      const days = prevState.days.includes(selectedDay)
        ? prevState.days.filter((day) => day !== selectedDay)
        : [...prevState.days, selectedDay];
      return { ...prevState, days };
    });
  };

  const handleIncrementInput = (inputId) => {
    const input = document.getElementById(inputId);
    input.stepUp();
    setCustomRecurring({ ...customRecurring, interval: parseInt(input.value) });
  };

  const handleDecrementInput = (inputId) => {
    const input = document.getElementById(inputId);
    input.stepDown();
    setCustomRecurring({ ...customRecurring, interval: parseInt(input.value) });
  };

  const handleDateSelect = (date) => {
    setCustomRecurring((prevState) => ({
      ...prevState,
      ends: { ...prevState.ends, on: date },
    }));
  };

  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const onRadioChange = (end) => {
    let ends;
    if (end === 1) {
      ends = { never: true };
    } else if (end === 2) {
      ends = { on: new Date() };
    } else {
      ends = { after: 1 };
    }
    setCustomRecurring((prevState) => ({
      ...prevState,
      ends,
    }));
  };

  const handleSelectInterval = (interval) => {
    setCustomRecurring((prevState) => ({
      ...prevState,
      period: interval,
    }));
    setShowRepeat(false);
  };

  useEffect(() => {
    onChange(customRecurring);
  }, [customRecurring]);

  return (
    <div className="custom-recurring">
      <h3>Custom recurrence</h3>
      <div className="custom-recurring-top">
        <div className="custom-recurring-top-left">
          <p>Repeat every</p>
          <div className="flex items-center arrow-inputs">
            <input
              type="number"
              id="recurring-count"
              min="0"
              value={customRecurring?.interval}
              onChange={(e) =>
                setCustomRecurring({
                  ...customRecurring,
                  interval: parseInt(e.target.value),
                })
              }
            />
            <div className="input-arrows">
              <button
                className="arrow up"
                onClick={() => handleIncrementInput("recurring-count")}
              ></button>
              <button
                className="arrow down"
                onClick={() => handleDecrementInput("recurring-count")}
              ></button>
            </div>
          </div>
          <OutsideClick
            style={{ position: "relative", zIndex: "3" }}
            onOutsideClick={() => setShowRepeat(false)}
          >
            <div className="recurring-repeat-interval">
              <div
                className={`repeat-selected ${!!showRepeat ? "active" : ""}`}
                onClick={() => setShowRepeat(!showRepeat)}
              >
                {
                  repeat?.find((item) => item?.id === customRecurring?.period)
                    ?.label
                }
              </div>
              {showRepeat ? (
                <ul>
                  {repeat?.map((item) => (
                    <li
                      key={`interval-${item?.id}`}
                      onClick={() => handleSelectInterval(item?.id)}
                    >
                      {item?.label}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </div>
          </OutsideClick>
        </div>

        <div className="custom-recurring-top-right">
          <p>Repeat on</p>
          <ul className="custom-days">
            {days?.map((day) => (
              <li
                key={`day-${day?.id}`}
                onClick={() => handleSelectDay(day?.label)}
                className={
                  customRecurring?.days?.includes(day?.label) ? "active" : ""
                }
              >
                {day.label[0]}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="custom-recurring-bottom">
        <p>Ends: </p>
        <div className="custom-recurring-radio-list">
          <div className="custom-recurring-radio">
            <Checkbox
              type="radio"
              checked={!!customRecurring?.ends?.never}
              onChange={() => onRadioChange(1)}
            />
            <label>Never</label>
          </div>
          <div className="custom-recurring-radio">
            <Checkbox
              type="radio"
              checked={!!customRecurring?.ends?.on}
              onChange={() => onRadioChange(2)}
            />
            <label>On</label>
            <div className="date-picker-holder form-group form-group-half">
              <div className="input-icon-wrapper">
                <DatePickerIcon />
                <DatePicker
                  selected={customRecurring?.ends?.on}
                  onChange={handleDateSelect}
                  timeInputLabel="Time:"
                  dateFormat="dd.MM.yyyy."
                  placeholderText="Select date"
                  minDate={subMonths(new Date(), 256)}
                  maxDate={addMonths(new Date(), 256)}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
          <div className="custom-recurring-radio">
            <Checkbox
              type="radio"
              checked={!!customRecurring?.ends?.after}
              onChange={() => onRadioChange(3)}
            />
            <label>After</label>
            <div className="flex items-center arrow-inputs">
              <input
                type="number"
                id="occurrences-count"
                min="0"
                value={customRecurring?.ends?.after || ""}
                onChange={(e) =>
                  setCustomRecurring((prevState) => ({
                    ...prevState,
                    ends: { ...prevState.ends, after: e.target.value },
                  }))
                }
              />
              <div className="input-arrows">
                <button
                  className="arrow up"
                  onClick={() => handleIncrementInput("occurrences-count")}
                ></button>
                <button
                  className="arrow down"
                  onClick={() => handleDecrementInput("occurrences-count")}
                ></button>
              </div>
            </div>
            <span>occurrences</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomRecurring;
