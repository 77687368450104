const AdministrationIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 4.6875V1.75C0.5 1.40625 0.622396 1.11198 0.867187 0.867188C1.11198 0.622396 1.40625 0.5 1.75 0.5H15.0833C15.4271 0.5 15.7214 0.622396 15.9661 0.867188C16.2109 1.11198 16.3333 1.40625 16.3333 1.75V4.6875H0.5ZM4.875 5.9375V15.4792H1.75C1.40625 15.4792 1.11198 15.3568 0.867187 15.112C0.622396 14.8672 0.5 14.5729 0.5 14.2292V5.9375H4.875ZM11.9583 5.9375H16.3333V14.2292C16.3333 14.5729 16.2109 14.8672 15.9661 15.112C15.7214 15.3568 15.4271 15.4792 15.0833 15.4792H11.9583V5.9375ZM10.7083 5.9375V15.4792H6.125V5.9375H10.7083Z"
      fill="white"
    />
  </svg>
);
export default AdministrationIcon;
