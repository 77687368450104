import { useLocation } from "react-router-dom";
import titles from "../routes/titles";

const usePageTitle = () => {
  const location = useLocation();
  const route = location.pathname;

  const matchedTitle = Object.keys(titles).reduce((acc, path) => {
    if (route.startsWith(path) && path.length > acc.length) {
      return path;
    }
    return acc;
  }, "");

  return titles[matchedTitle] || "Default Title";
};

export default usePageTitle;
