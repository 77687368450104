import React, { useState, useEffect } from "react";
import Checkbox from "../Checkbox";
import OutsideClick from "../OutsideClick";
import "./styles.scss";
import { Button } from "../../ui";
import { CloseIcon } from "../../../icons";

const Dropdown = ({
  options = [],
  placeholder = "",
  className,
  value,
  onChange = () => {},
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isOptionsHidden, setIsOptionsHidden] = useState(true);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleOptionClick = (selectedValue) => {
    setSelectedOption(parseInt(selectedValue));
  };

  const handleOutsideClick = () => {
    setIsOptionsHidden(true);
  };

  const handleClearClick = () => {
    setSelectedOption("");
    setIsOptionsHidden(true);
  };

  useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption]);

  return (
    <OutsideClick onOutsideClick={handleOutsideClick}>
      <div
        className={`dropdown-wrapper ${className}`}
        data-options-hidden={isOptionsHidden}
      >
        <div className="dropdown-input flex">
          <input
            value={
              options.find((option) => option?.value === selectedOption)
                ?.label || selectedOption
            }
            placeholder={placeholder}
            onClick={() => setIsOptionsHidden(!isOptionsHidden)}
            onChange={onChange}
          />
          <div className="btn-container">
            <span className="vertical-line" />
            <Button variant="control" handleClick={handleClearClick}>
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div className="dropdown-select">
          <div className={`options ${isOptionsHidden ? "hidden" : ""}`}>
            {options.map((option, index) => {
              return (
                <Checkbox
                  key={`rad-${index}`}
                  type="radio"
                  value={option?.value || option}
                  onChange={(e) => handleOptionClick(e.target.value)}
                  checked={option.value === selectedOption}
                >
                  {option?.label || option}
                </Checkbox>
              );
            })}
          </div>
        </div>
      </div>
    </OutsideClick>
  );
};

export default Dropdown;
