import { useEffect, useState } from "react";
import ReactFlow, { useNodesState, useEdgesState } from "reactflow";
import CustomNode from "./CustomNode";

const nodeTypes = { customNode: CustomNode };

const edgeOptions = {
  type: "step",
  style: {
    stroke: "#eae8e8",
  },
};

const EmployeeTree = ({ employees }) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [actionCount, setActionCount] = useState(0);
  const [currentId, setCurrentId] = useState(null);
  const [currentY, setCurrentY] = useState(100);

  const subordinatesCount = (employeeId) => {
    return employees.filter((employee) => employee?.manager === employeeId)
      ?.length;
  };

  const handleToggleExpand = (id, yPos) => {
    setActionCount((prevCount) => prevCount + 1);
    setCurrentId(id);
    setCurrentY(yPos);
  };

  const collapse = () => {
    const reducedNodes = nodes.filter((node) => node.position.y <= currentY);
    setNodes(reducedNodes);
  };

  const expand = () => {
    const subordinates = employees.filter(
      (employee) => employee?.manager === currentId
    );
    const expandedEmployees = subordinates.map((item, index) => {
      return {
        id: item?.id.toString(),
        type: "customNode",
        position: { x: 100 + 200 * index, y: currentY + 200 },
        data: {
          ...item,
          count: subordinatesCount(item?.id),
          handleToggleExpand: handleToggleExpand,
        },
      };
    });
    setNodes((prevNodes) => [...prevNodes, ...expandedEmployees]);
  };

  useEffect(() => {
    if (!!actionCount) {
      if (nodes.some((node) => node.data.manager === currentId)) {
        collapse();
      } else if (
        !nodes.some((node) => node.data.manager === currentId) ||
        nodes.some((node) => node.position.y > currentY)
      ) {
        collapse();
        expand();
      } else {
        expand();
      }
    }
  }, [actionCount]);

  useEffect(() => {
    if (!employees || !employees.length) {
      return;
    }
    const topManagers = employees?.filter(
      (employee) => employee?.manager === null
    );

    const subordinates = employees.filter(
      (employee) => employee?.manager === topManagers[0]?.id
    );

    const employeeNodes = topManagers?.map((employee, index) => {
      return {
        id: employee?.id.toString(),
        type: "customNode",
        position: { x: 300 + 200 * index, y: 100 },
        data: {
          ...employee,
          count: subordinatesCount(employee?.id),
          handleToggleExpand: handleToggleExpand,
        },
      };
    });

    const firstRowEmployees = subordinates?.map((employee, index) => {
      return {
        id: employee?.id.toString(),
        type: "customNode",
        position: { x: 100 + 200 * index, y: 300 },
        data: {
          ...employee,
          count: subordinatesCount(employee?.id),
          handleToggleExpand: handleToggleExpand,
        },
      };
    });
    setNodes((prevNodes) => [
      ...prevNodes,
      ...employeeNodes,
      ...firstRowEmployees,
    ]);

    const allInitialNodes = [...subordinates];

    const newEdge = allInitialNodes.map((employee) => {
      return {
        id: `e${topManagers[0]?.id}-${employee?.id}`,
        source: topManagers[0]?.id.toString(),
        target: employee?.id.toString(),
      };
    });
    const newEdges = [...edges, ...newEdge];
    setEdges(newEdges);
  }, []);

  useEffect(() => {
    if (!!nodes.length && !nodes?.some((node) => node?.data?.manager)) {
      setEdges([]);
      return;
    }
    const nodesEdges = nodes.map((employee) => {
      if (employee?.data?.manager) {
        return {
          id: `e${employee?.data?.manager}-${employee?.id}`,
          source: employee?.data?.manager.toString(),
          target: employee?.id.toString(),
        };
      }
    });
    setEdges(nodesEdges);
  }, [nodes]);

  return (
    <div style={{ width: "100%", height: "700px" }}>
      {!!nodes.length ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          defaultEdgeOptions={edgeOptions}
          nodeTypes={nodeTypes}
        ></ReactFlow>
      ) : (
        ""
      )}
    </div>
  );
};

export default EmployeeTree;
