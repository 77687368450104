import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllHighFives,
  fetchSingleHighFive,
  fetchUsersHighFive,
  fetchTopHighFivers,
  fetchMostApprecieated,
  fetchHashtagList,
  createHighFive,
} from "./highFivesThunks";

const highFivesSlice = createSlice({
  name: "highFives",
  initialState: {
    allHighFives: [],
    usersHighFives: {
      highFives: [],
    },
    topHighFivers: [],
    mostApprecieated: [],
    hashtagList: [],
    singleHighFive: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetHighFiveMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAllHighFives.fulfilled, (state, action) => {
      state.allHighFives = action.payload;
    });
    builder.addCase(fetchSingleHighFive.fulfilled, (state, action) => {
      state.singleHighFive = action.payload;
    });
    builder.addCase(fetchUsersHighFive.fulfilled, (state, action) => {
      state.usersHighFives = action.payload;
    });
    builder.addCase(fetchTopHighFivers.fulfilled, (state, action) => {
      state.topHighFivers = action.payload;
    });
    builder.addCase(fetchMostApprecieated.fulfilled, (state, action) => {
      state.mostApprecieated = action.payload;
    });
    builder.addCase(fetchHashtagList.fulfilled, (state, action) => {
      state.hashtagList = action.payload;
    });
    builder.addCase(createHighFive.fulfilled, (state, action) => {
      state.usersHighFives.highFives = [
        action.payload,
        ...state.usersHighFives?.highFives,
      ];
      state.usersHighFives.given += 1;
      state.message = {
        type: "success",
        title: "Sent",
        message: "High Five sent successfully",
      };
    });
  },
});

export const { resetHighFiveMessage } = highFivesSlice.actions;

export const highFivesReducer = highFivesSlice.reducer;
