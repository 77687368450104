import React, { useEffect, useState } from "react";
import { useThunk } from "../../../../hooks/useThunk";
import { fetchUsersGoals } from "../../../../store";
import { useSelector } from "react-redux";
import { Button, Filter, Status, Table } from "../../../ui";
import { SingleArrow, ClockIcon } from "../../../../icons";
import { convertTextToDate, shortenText } from "../../../../utils/textFormat";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const GoalsReview = ({ localUserData }) => {
  const navigate = useNavigate();

  const [doFetchGoals] = useThunk(fetchUsersGoals);

  const goalsState = useSelector((state) => state?.goals)?.usersGoals;

  const [usersGoals, setUsersGoals] = useState([]);
  const [goals, setGoals] = useState([]);
  const [reducedGoals, setReducedGoals] = useState({
    completed: [],
    pending: [],
    overdue: [],
  });
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    doFetchGoals(localUserData?.id);
  }, []);

  useEffect(() => {
    if (!!goalsState) {
      setUsersGoals(goalsState);
    }
  }, [goalsState]);

  useEffect(() => {
    goalsReducer();
    setGoals(usersGoals);
  }, [usersGoals]);

  useEffect(() => {
    selectedFilter !== null
      ? setGoals(reducedGoals[Object.keys(reducedGoals)[selectedFilter]])
      : setGoals(usersGoals);
  }, [selectedFilter]);

  const goalsReducer = () => {
    const mappedGoals = {
      completed: [],
      pending: [],
      overdue: [],
    };
    usersGoals.forEach((goal) => {
      if (
        convertTextToDate(goal?.settings?.endDate) > new Date() &&
        goal?.tasks.some((task) => task?.status !== 100)
      ) {
        mappedGoals.pending = [...mappedGoals.pending, goal];
      } else if (
        convertTextToDate(goal?.settings?.endDate) < new Date() &&
        goal?.tasks.some((task) => task?.status !== 100)
      ) {
        mappedGoals.overdue = [...mappedGoals.overdue, goal];
      } else if (!goal?.tasks.some((task) => task?.status !== 100)) {
        mappedGoals.completed = [...mappedGoals.completed, goal];
      }
    });
    setReducedGoals(mappedGoals);
  };

  const goalFinder = (id) => {
    if (reducedGoals.completed.find((goal) => goal?.id === id))
      return <Status status={{ color: "green", label: "Completed" }} />;
    else if (reducedGoals.overdue.find((goal) => goal?.id === id))
      return <Status status={{ color: "red", label: "Overdue" }} />;
    else if (reducedGoals.pending.find((goal) => goal?.id === id))
      return <Status status={{ color: "blue", label: "Pending" }} />;
  };

  const handleShowAllClick = () => {
    navigate("/your-career/home");
  };

  const highlightWithRanges = [
    {
      "highlighted-dates completed": reducedGoals?.completed.map((goal) =>
        convertTextToDate(goal?.settings?.endDate)
      ),
    },
    {
      "highlighted-dates overdue": reducedGoals?.overdue.map((goal) =>
        convertTextToDate(goal?.settings?.endDate)
      ),
    },
    {
      "highlighted-dates underdue": reducedGoals?.pending.map((goal) =>
        convertTextToDate(goal?.settings?.endDate)
      ),
    },
  ];

  const handleFilterChange = (e) => {
    setSelectedFilter(parseInt(e.target.value));
  };

  const handleResetFilter = () => {
    setSelectedFilter(null);
  };

  const goalsTableData = {
    rows: goals.map((goal) => {
      return {
        goalList: (
          <div className="goal-list">
            <p className="goal-title">{shortenText(goal?.name, 60)}</p>
            <p className="goal-description">
              {shortenText(goal?.description, 60)}
            </p>
          </div>
        ),
        deadline: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <ClockIcon />
            {goal?.settings?.endDate}
          </div>
        ),
        progress: goalFinder(goal?.id),
        actions: (
          <div className="actions">
            {/* TODO: ubaciti onClick funkciju kada bude moglo da se pristupi goal-u direktno preko linka */}
            <span className="vertical-line"></span>
            <SingleArrow />
          </div>
        ),
      };
    }),
    columns: [
      {
        id: 1,
        label: "Goal list",
        slug: "goalList",
      },
      {
        id: 2,
        label: "Progress",
        slug: "progress",
      },
      {
        id: 3,
        label: "Deadline",
        slug: "deadline",
      },
      {
        id: 4,
        label: "",
        slug: "actions",
      },
    ],
  };

  return (
    <>
      <div className="goals">
        <div className="goals-table">
          <div className="title-line">
            <h3 className="title">Goals</h3>
            <div className="filter-wrapper">
              <Filter
                filters={Object.keys(reducedGoals).map(
                  (key) => key[0].toUpperCase() + key.slice(1)
                )}
                checked={selectedFilter}
                onRadioChange={handleFilterChange}
                onReset={handleResetFilter}
              />
              <Button
                variant="control show-all"
                handleClick={handleShowAllClick}
              >
                Show All <SingleArrow />
              </Button>
            </div>
          </div>
          <Table rows={goalsTableData.rows} columns={goalsTableData.columns} />
        </div>
        <div className="datepicker-wrapper">
          <label>Due date</label>
          <div className="date-picker-holder">
            <DatePicker
              disabledKeyboardNavigation
              inline
              readOnly
              showMonthDropdown
              showYearDropdown
              highlightDates={highlightWithRanges}
              dayClassName={() => "custom-day"}
              weekDayClassName={() => "custom-day"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalsReview;
