import { useEffect, useState, useCallback } from "react";
import { useThunk } from "../../../../hooks/useThunk";
import usePageTitle from "../../../../hooks/usePageTitle";
import debounce from "lodash/debounce";
import ArrowUpIcon from "../../../../icons/ArrowUpIcon";
import ArrowDownIcon from "../../../../icons/ArrowDownIcon";
import SearchIcon from "../../../../icons/SearchIcon";
import { shortenText } from "../../../../utils/textFormat";
import { Table, Pagination, Button, Input } from "../../../../components/ui";

import { fetchUsers, getUsersStats, getUsersTrends } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import TrendsModal from "./TrendsModal";
import { setFormData, setFormType } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

const Communication = () => {
  const title = usePageTitle();
  const dispatch = useDispatch();
  const [doReportsUserMeetings] = useThunk(getUsersStats);
  const [doGetAllUsers] = useThunk(fetchUsers);
  const [doGetUsersTrends] = useThunk(getUsersTrends);

  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const userStats = useSelector((state) => state.reports.usersStats);
  const allUsers = useSelector((state) => state.users.data)?.userData;
  const userPageData = useSelector((state) => state.users.data)?.pageData;
  const usersTrends = useSelector((state) => state.reports.usersTrends);

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    doGetAllUsers({ page: page, searchTerm: searchTerm });
    doReportsUserMeetings();
    doGetUsersTrends();
  }, []);

  const handleSearchUserMeetingChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedDoGetAllUsers(value);
  };

  const debouncedDoGetAllUsers = useCallback(
    debounce((value) => {
      setPage(1);
      doGetAllUsers({ page: page, searchTerm: value });
    }, 500),
    [page]
  );

  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/reports/users/meetings/export`;
  };

  const handleShowBarModal = (user) => {
    dispatch(setFormType("view-communication-trends"));
    dispatch(setFormData(user));
    dispatch(setActiveModal(`${title}-trends-modal`));
  };

  const handleCloseBarModal = () => {
    dispatch(setActiveModal(""));
    dispatch(setFormType(""));
    dispatch(setFormData(null));
  };

  const getNewDataUser = (page) => {
    setPage(page);
    doGetAllUsers({ page: page });
  };

  const tableData = {
    rows: allUsers?.map((user) => {
      return {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: <span title={user.email}>{shortenText(user.email, 10)}</span>,
        low: userStats
          ? userStats[user.id]?.low
            ? userStats[user.id].low
            : 0
          : 0,
        medium: userStats
          ? userStats[user.id]?.medium
            ? userStats[user.id].medium
            : 0
          : 0,
        high: userStats
          ? userStats[user.id]?.high
            ? userStats[user.id].high
            : 0
          : 0,
        total: userStats
          ? userStats[user.id]?.total
            ? userStats[user.id].total
            : 0
          : 0,
        trend: usersTrends ? (
          typeof usersTrends[user.id] !== "undefined" &&
          usersTrends[user.id]["diff"] ? (
            <div onClick={() => handleShowBarModal(user)}>
              {usersTrends[user.id]["trend"] === "up" ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
            </div>
          ) : (
            "No meetings"
          )
        ) : (
          <span>Loading...</span>
        ),
      };
    }),

    columns: [
      {
        label: "#",
        slug: "id",
      },
      {
        label: "First Name",
        slug: "first_name",
      },
      {
        label: "Last Name",
        slug: "last_name",
      },
      {
        label: "Email",
        slug: "email",
      },
      {
        label: "# Meetings (Low-Prio)",
        slug: "low",
      },
      {
        label: "# Meetings (Med - Prio)",
        slug: "medium",
      },
      {
        label: "# Meetings (High-Prio)",
        slug: "high",
      },
      {
        label: "# Attended Meetings",
        slug: "total",
      },
      {
        label: "# Meetings (Total)",
        slug: "total",
      },
      {
        label: "Trends (last & this week)",
        slug: "trend",
      },
    ],
  };

  return (
    <div>
      <div className="reports-header">
        <h2>{title}</h2>
        <div className="header-actions">
          <div className="table-search">
            <Input
              placeholder="Search"
              variant="form-control light"
              value={searchTerm}
              handleChange={handleSearchUserMeetingChange}
              icon={<SearchIcon />}
            />
          </div>
          <Button handleClick={handleDownload}>Download</Button>
        </div>
      </div>

      <Table rows={tableData?.rows} columns={tableData?.columns} />

      <Pagination
        currentPage={page}
        total={userPageData?.total}
        onPageChange={getNewDataUser}
        limit={userPageData?.perPage}
        position="center"
      />
      {activeModalState === `${title}-trends-modal` ? (
        <TrendsModal
          showBarModal={activeModalState === `${title}-trends-modal`}
          handleCloseBarModal={handleCloseBarModal}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Communication;
