const ArrowDownIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.450391 2.05019L2.05039 0.450195L14.1254 12.5252L14.1254 5.7252L16.4004 5.7252L16.4004 16.4002L5.72539 16.4002L5.72539 14.1252L12.5254 14.1252L0.450391 2.05019Z"
      fill="#E00000"
    />
  </svg>
);
export default ArrowDownIcon;
