import { useEffect, useState } from "react";
import "./style.scss";

const Pagination = ({
  currentPage,
  total,
  onPageChange,
  limit,
  position = "center",
}) => {
  const [startPage, setStartPage] = useState(1);
  const pageCounter = Math.ceil(total / limit);
  const rangeLimiter = 10;

  useEffect(() => {
    if (currentPage > rangeLimiter) {
      setStartPage(currentPage - 9);
    } else {
      setStartPage(1);
    }
  }, [currentPage]);

  const linkPagesArray = () => {
    let pages = [];
    for (
      let i = startPage;
      i < startPage + rangeLimiter && i <= pageCounter;
      i++
    ) {
      pages.push(i);
    }

    return pages;
  };

  const handleNextPage = () => {
    if (currentPage < pageCounter) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <ul className={`_pagination ${position}`}>
      <li className="_page-item controls" onClick={handlePrevPage}>
        <span className="_page-link">
          <span aria-hidden="true">‹</span>
        </span>
      </li>

      {linkPagesArray().map((page) => {
        return (
          <li
            key={page}
            className={`_page-item ${currentPage === page ? "_active" : ""}`}
            onClick={() => onPageChange(page)}
          >
            <span>{page}</span>
          </li>
        );
      })}

      <li className="_page-item controls" onClick={handleNextPage}>
        <span className="_page-link">
          <span aria-hidden="true">›</span>
        </span>
      </li>
    </ul>
  );
};

export default Pagination;
