import React from "react";
import "./styles.scss";
import moment from "moment";
import Point from "./Point";
import { convertTextToDate } from "../../../utils/textFormat";

const Timeline = ({
  title = "",
  legendItems = [],
  timelineColors = ["#14b4ba0f", "#D6EDEF"],
  timelineBorderColor = "#D6EDEF",
  lineColor = "#96A3E4",
  points = [],
  onPointClick = () => {},
  popup = null,
}) => {
  /* legendItem = [
      {
          label: ""
          color: ""
      }, ...
  ] */

  let selectedPoint = null;
  let flexColumnReversed = true;

  const months = moment.monthsShort();

  const pointsMapper = (index) => {
    const foundPoint = points.find(
      (point) => convertTextToDate(point?.date).getMonth() === index
    );

    !!foundPoint ? (selectedPoint = foundPoint) : (selectedPoint = null);
  };

  const flexColumnReverser = () => {
    flexColumnReversed = !flexColumnReversed;
    return flexColumnReversed ? "-reversed" : "";
  };

  return (
    <div className="timeline-wrapper">
      <div className="title-legend">
        <p className="title">{title}</p>
        <div className="legend">
          {legendItems.map((item, index) => (
            <div key={`legend-${index}`} className="legend-item">
              <Point color={item.color} label={item.label} legend={true} />
            </div>
          ))}
        </div>
      </div>
      <div className="timeline">
        {months.map((month, index) => (
          <div key={`month-${index}`} className="timeline-month">
            <div
              className="month"
              style={{
                backgroundColor: timelineColors[index % 2],
                borderColor: timelineBorderColor,
              }}
            >
              {pointsMapper(index)}
              <div className="horizontal-line">
                <hr style={{ borderBlockColor: lineColor }} />
                {!!selectedPoint && (
                  <Point
                    color={legendItems[selectedPoint?.status - 1]?.color}
                    onClick={onPointClick}
                  />
                )}
              </div>
              <div
                className={`point-details flex-column${flexColumnReverser()}`}
              >
                <div className="point-title">
                  {!!selectedPoint && selectedPoint?.title}
                </div>
                <div className="point-description">
                  {!!selectedPoint && (
                    <>
                      <span
                        className="vertical-line"
                        style={{ backgroundColor: lineColor }}
                      ></span>
                      <div
                        className="dot"
                        style={{ backgroundColor: lineColor }}
                      ></div>
                    </>
                  )}
                  {!!selectedPoint && (
                    <p className="description-text">
                      {selectedPoint.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <label>{month}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
