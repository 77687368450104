import "./styles.scss";
const UserCard = () => {
  let user = JSON.parse(localStorage.getItem("authUserData"));
  return (
    <div className="user-card-wrapper">
      <div className="user-left">
        <p>{`${user?.first_name} ${user?.last_name}`} </p>
        <p>{user?.position}</p>
      </div>
      <div className="user-right">
        <div className="user-bordered">
          <p>{user?.team}</p>
        </div>
        <div>
          <p>{user?.position}</p>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
