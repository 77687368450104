import { useEffect, useState } from "react";

import DropdownSelect from "./../../../DropdownSelect/DropdownSelect";

import {
  fetchTopHighFivers,
  fetchMostApprecieated,
  fetchHashtagList,
  fetchUsers,
  createHighFive,
  fetchUsersHighFive,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";

import "./styles.scss";
import { UserIcon, Button, Input } from "../../../ui";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetHighFiveMessage } from "../../../../store/highFives/highFivesSlice";

import { useNavigate } from "react-router-dom";

const HighFive = () => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);

  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [highFiveMessage, setHighFiveMessage] = useState("");
  const [filters, setFilters] = useState([]);

  const [doFetchTopHighFivers] = useThunk(fetchTopHighFivers);
  const [doFetchMostApprecieated] = useThunk(fetchMostApprecieated);
  const [doFetchHashtagList] = useThunk(fetchHashtagList);
  const [doCreateHighFive] = useThunk(createHighFive);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doFetchUsersHighFive] = useThunk(fetchUsersHighFive);
  const [doShowToastMessage] = useToastMessage(resetHighFiveMessage);

  const topHighFiversState = useSelector(
    (state) => state?.highFives
  )?.topHighFivers;
  const mostApprecieatedState = useSelector(
    (state) => state?.highFives
  )?.mostApprecieated;
  const hashtagListState = useSelector(
    (state) => state?.highFives
  )?.hashtagList;
  const highFivesState = useSelector(
    (state) => state.highFives
  )?.usersHighFives;
  const usersState = useSelector((state) => state.users)?.data?.optionData;
  const messageState = useSelector((state) => state.highFives)?.message;

  useEffect(() => {
    doFetchTopHighFivers();
    doFetchMostApprecieated();
    doFetchHashtagList();
  }, []);

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    }
    const reducedUsers = usersState?.map((user) => {
      return {
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      };
    });

    setAllUsers(reducedUsers);
    if (reducedUsers) {
      setFilterUsers([{ value: 0, label: "Company wide" }, ...reducedUsers]);
    }
  }, [usersState]);

  useEffect(() => {
    doFetchUsersHighFive({ userId: localUserData?.id, filters });
  }, [filters]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const timePeriodOptions = [
    { value: 0, label: "All time periods" },
    { value: 7, label: "Last week" },
    { value: 30, label: "Last month" },
    { value: 90, label: "Last 3 months" },
    { value: 180, label: "Last 6 months" },
    { value: 365, label: "Last 12 months" },
  ];
  const highFiveOptions = [
    { value: 0, label: "All high fives" },
    { value: 1, label: "High fives received by" },
    { value: 2, label: "High fives given by" },
  ];

  const groupedData = highFivesState?.highFives?.reduce((groups, item) => {
    const date = item.createdAt;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});

  const groupedArray = groupedData ? Object.entries(groupedData) : [];

  const handleSelectReceiver = (event) => {
    setSelectedReceiver(event);
  };

  const handleMessageChange = (event) => {
    setHighFiveMessage(event.target.value);
  };

  const handleSendHighFive = () => {
    const payload = {
      senderId: localUserData?.id,
      receiverId: selectedReceiver,
      message: highFiveMessage,
    };
    doCreateHighFive(payload);
  };

  const hashtagOptions = [
    { value: 0, label: "All hashtags" },
    ...hashtagListState.map((hashtag, index) => {
      return {
        value: index + 1,
        label: hashtag.hashtag,
      };
    }),
  ];

  const handleFilter = (event) => {
    const { name, value } = event;
    const reducedHashtagList = [
      { hashtag: "All hashtags" },
      ...hashtagListState,
    ];
    const existingFilterIndex = filters.findIndex(
      (filter) => filter.name === name
    );

    if (existingFilterIndex !== -1) {
      const updatedFilters = [...filters];
      if (name === "hashtag") {
        updatedFilters[existingFilterIndex] = {
          name,
          value: reducedHashtagList[value].hashtag,
        };
      } else {
        updatedFilters[existingFilterIndex] = { name, value };
      }
      setFilters(updatedFilters);
    } else {
      if (name === "hashtag") {
        setFilters([
          ...filters,
          { name, value: reducedHashtagList[value].hashtag },
        ]);
      } else {
        setFilters([...filters, { name, value }]);
      }
    }
  };

  const handleBack = () => {
    navigate("/your-career/home");
  };

  return (
    <div className="career-vision-wrapper high-five-wrapper">
      <div className="career-header">
        <div className="header-title">
          <span onClick={handleBack}>Career Vision and Growth</span>
          <h2>High Five</h2>
        </div>
      </div>
      <div className="high-five-filters">
        <div className="filter-item">
          <label>People</label>
          <DropdownSelect
            options={filterUsers}
            name="people"
            placeholder="Company wide"
            background="dark"
            handleSelect={handleFilter}
          />
        </div>
        <div className="filter-item">
          <label>Time period</label>
          <DropdownSelect
            options={timePeriodOptions}
            name="timePeriod"
            placeholder="All time periods"
            background="dark"
            handleSelect={handleFilter}
          />
        </div>
        <div className="filter-item">
          <label>High fives</label>
          <DropdownSelect
            options={highFiveOptions}
            placeholder="All high fives"
            name="type"
            background="dark"
            handleSelect={handleFilter}
          />
        </div>
        <div className="filter-item">
          <label>Hashtag</label>
          <DropdownSelect
            options={hashtagOptions}
            placeholder="Select a hashtag"
            name="hashtag"
            background="dark"
            handleSelect={handleFilter}
          />
        </div>
      </div>
      <div className="high-five-content">
        <div className="high-five-messages">
          <div className="high-five-messages-head">
            <h3>Give a high five</h3>
            <p>
              Appreciate teammates by sharing the impact they had on you. Use
              hashtags to align their actions with company values. Only public
              high fives will be posted to the feed.
            </p>
            <div className="select-receiver">
              <DropdownSelect
                options={allUsers}
                placeholder="Select receiver"
                background="dark"
                value={selectedReceiver}
                handleSelect={handleSelectReceiver}
              />
            </div>
            <Input
              type="input"
              placeholder="Write a message"
              name="message"
              value={highFiveMessage}
              handleChange={handleMessageChange}
            />
            <Button
              handleClick={handleSendHighFive}
              disabled={!selectedReceiver || !highFiveMessage}
            >
              High five!
            </Button>
          </div>
          <div className="high-fives-text">
            <div>
              {groupedArray?.map(([date, items]) => (
                <div key={date}>
                  <div className="date-line">
                    <span>{date}</span>
                  </div>
                  <ul>
                    {items.map((item, index) => (
                      <li key={index}>
                        <div className="overlap-images">
                          <UserIcon
                            userImage={item?.sender?.image}
                            userName={item?.sender?.name}
                            iconStyle={{
                              width: "40px",
                              height: "40px",
                              fontSize: "30px",
                            }}
                          />
                          <UserIcon
                            userImage={item?.receiver?.image}
                            userName={item?.receiver?.name}
                            iconStyle={{
                              width: "40px",
                              height: "40px",
                              fontSize: "30px",
                            }}
                          />
                        </div>
                        <div className="single-message">
                          <span>
                            <b>{item?.sender?.name}</b> to{" "}
                            <b>{item?.receiver?.name}</b>
                          </span>
                          <p>{item?.message}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="high-five-profile">
          <div className="profile-info-wrapper">
            <div className="profile-info">
              <UserIcon
                userImg={localUserData?.image}
                userName={localUserData?.first_name}
                iconStyle={{
                  width: "100px",
                  height: "100px",
                  fontSize: "50px",
                }}
              />
              <p>{`${localUserData?.first_name} ${localUserData?.last_name}`}</p>
              <span>{localUserData?.position}</span>
            </div>
            <ul className="given-received">
              <li>
                <span>{highFivesState?.given}</span> Given
              </li>
              <li>
                <span>{highFivesState?.received}</span>Received
              </li>
            </ul>
          </div>
          <div className="high-five-lists">
            {!!hashtagListState.length && (
              <>
                <h3>Hashtag Trening</h3>
                <ul className="high-five-list">
                  {hashtagListState?.map((hashtag, index) => (
                    <li key={index}>
                      #{hashtag?.hashtag}
                      <span className="count">{hashtag?.counter}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
            <h3>Most appreciated</h3>
            <ul className="high-five-list">
              {mostApprecieatedState?.map((item, index) => (
                <li key={index}>
                  <div className="high-five-list-user">
                    <UserIcon
                      userImage={item?.image}
                      userName={item?.name}
                      iconStyle={{
                        width: "24px",
                        height: "24px",
                        fontSize: "15px",
                      }}
                    />
                    <p>{item?.name}</p>
                  </div>
                  <span className="count">{item?.highFivesReceived}</span>
                </li>
              ))}
            </ul>
            <h3>Top High fivers</h3>
            <ul className="high-five-list">
              {topHighFiversState?.map((highFiver, index) => (
                <li key={index}>
                  <div className="high-five-list-user">
                    <UserIcon
                      userImage={highFiver?.image}
                      userName={highFiver?.name}
                      iconStyle={{
                        width: "24px",
                        height: "24px",
                        fontSize: "15px",
                      }}
                    />
                    <p>{highFiver?.name}</p>
                  </div>
                  <span className="count">{highFiver?.highFivesGiven}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighFive;
