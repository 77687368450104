const CircleCheckIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11ZM5.23283 11.1889C4.81329 10.7457 4.13556 10.7481 3.71907 11.1941C3.30258 11.6402 3.30506 12.361 3.7246 12.8042L8.33292 16.9663L17.6189 7.02119C18.0354 6.57514 18.0329 5.85428 17.6134 5.4111C17.1938 4.96792 16.5161 4.97024 16.0996 5.41629L8.32187 13.7461L5.23283 11.1889Z"
      fill="white"
    />
  </svg>
);

export default CircleCheckIcon;
