const InfoIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17383 15.0176H11.1738V9.01758H9.17383V15.0176ZM10.1738 7.01758C10.4572 7.01758 10.6947 6.92174 10.8863 6.73008C11.078 6.53841 11.1738 6.30091 11.1738 6.01758C11.1738 5.73424 11.078 5.49674 10.8863 5.30508C10.6947 5.11341 10.4572 5.01758 10.1738 5.01758C9.89049 5.01758 9.653 5.11341 9.46133 5.30508C9.26966 5.49674 9.17383 5.73424 9.17383 6.01758C9.17383 6.30091 9.26966 6.53841 9.46133 6.73008C9.653 6.92174 9.89049 7.01758 10.1738 7.01758ZM10.1738 20.0176C8.79049 20.0176 7.49049 19.7551 6.27383 19.2301C5.05716 18.7051 3.99883 17.9926 3.09883 17.0926C2.19883 16.1926 1.48633 15.1342 0.961328 13.9176C0.436328 12.7009 0.173828 11.4009 0.173828 10.0176C0.173828 8.63424 0.436328 7.33424 0.961328 6.11758C1.48633 4.90091 2.19883 3.84258 3.09883 2.94258C3.99883 2.04258 5.05716 1.33008 6.27383 0.805078C7.49049 0.280078 8.79049 0.0175781 10.1738 0.0175781C11.5572 0.0175781 12.8572 0.280078 14.0738 0.805078C15.2905 1.33008 16.3488 2.04258 17.2488 2.94258C18.1488 3.84258 18.8613 4.90091 19.3863 6.11758C19.9113 7.33424 20.1738 8.63424 20.1738 10.0176C20.1738 11.4009 19.9113 12.7009 19.3863 13.9176C18.8613 15.1342 18.1488 16.1926 17.2488 17.0926C16.3488 17.9926 15.2905 18.7051 14.0738 19.2301C12.8572 19.7551 11.5572 20.0176 10.1738 20.0176ZM10.1738 18.0176C12.4072 18.0176 14.2988 17.2426 15.8488 15.6926C17.3988 14.1426 18.1738 12.2509 18.1738 10.0176C18.1738 7.78424 17.3988 5.89258 15.8488 4.34258C14.2988 2.79258 12.4072 2.01758 10.1738 2.01758C7.94049 2.01758 6.04883 2.79258 4.49883 4.34258C2.94883 5.89258 2.17383 7.78424 2.17383 10.0176C2.17383 12.2509 2.94883 14.1426 4.49883 15.6926C6.04883 17.2426 7.94049 18.0176 10.1738 18.0176Z"
      fill="#6A6565"
    />
  </svg>
);

export default InfoIcon;
