import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import "./styles.scss";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutOverview = ({ question }) => {
  const chartColorsPalette = [
    "#f07171",
    "#14b4ba",
    "#a9ce5c",
    "#f0e371",
    "#de71f0",
    "#7671f0",
    "#f0af71",
    "#f0b771",
    "#71bef0",
    "#f07195",
  ];

  const countAnswers = (question) => {
    const answerCounts = {};

    question?.choices?.forEach((choice) => {
      // answerCounts[choice] = question.answers.filter(
      //   (answer) =>
      //     answer.response.filter((response) => response === choice).length
      // ).length;

      let counter = 0;
      question.answers.forEach((answer) => {
        if (answer.response.includes(choice)) {
          counter++;
        }
      });
      answerCounts[choice] = counter;
    });

    return answerCounts;
  };

  const answerCounts = countAnswers(question);
  const data = Object.entries(answerCounts).map(([label, value], index) => ({
    label,
    value,
    color: chartColorsPalette[index],
    cutout: "50%",
  }));

  const options = {
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          // Hide label if value is zero
          if (value === 0) return "";
          return value;
        },
        color: "black",
        font: {
          weight: "bold",
          size: 20,
          family: "poppins",
        },
      },
      responsive: true,
      legend: {
        position: "right",
        labels: {
          generateLabels: function (chart) {
            // Generate legend labels with counts
            const data = chart.data.datasets[0].data;
            const labels = chart.data.labels;
            const legendItems = labels.map((label, index) => {
              return {
                text: `${label}: ${data[index]}`,
                fillStyle: chart.data.datasets[0].backgroundColor[index],
              };
            });
            return legendItems;
          },
        },
      },
    },
    cutout: data.map((item) => item.cutout),
  };
  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  };

  return (
    <div className="survey-overview-question">
      <div className="survey-overview-question-content">
        <h3>
          {question?.content}
          <span>{question?.answers?.length} responses</span>
        </h3>
        {question?.answers?.length > 0 ? (
          <div className="doughnut-wrapper">
            <Doughnut data={finalData} options={options} />
          </div>
        ) : (
          <div className="no-answers">No answers</div>
        )}
        <div className="survey-overview-required">
          <span>Required</span>
        </div>
      </div>
    </div>
  );
};

export default DoughnutOverview;
