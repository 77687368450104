import React, { useEffect } from "react";
import { Button } from "../../../ui";
import { AddIcon, DeleteIcon, EditIcon, SingleArrow } from "../../../../icons";
import { dummyConferences } from "./dummyData";
import { useThunk } from "../../../../hooks/useThunk";
import { fetchUsersHighFive } from "../../../../store";
import { useSelector } from "react-redux";

const ConferenceReview = ({ userId }) => {
  const attendant = dummyConferences.filter(
    (data) => data.attendingReason === 1
  );
  const speaker = dummyConferences.filter((data) => data.attendingReason === 2);

  const [doFetchHighFives] = useThunk(fetchUsersHighFive);

  const highFivesState = useSelector(
    (state) => state?.highFives?.usersHighFives
  )?.highFives;

  useEffect(() => {
    doFetchHighFives({ userId });
  }, []);

  useEffect(() => {
    console.log(highFivesState);
  }, [highFivesState]);

  const renderList = (list) => {
    return (
      <ul>
        {list.map((confereence, index) => (
          <li key={`conference-${index}`}>
            <div className="clmn">
              <p className="conference-title">{confereence?.title}</p>
              <p className="conference-description">
                {confereence?.description}
              </p>
            </div>
            <div className="clmn conference-type">
              {confereence.type === 1 ? "Online" : "In person"}
            </div>
            <div className="clmn conference-date">{confereence?.date}</div>
            <div className="clmn actions">
              <Button variant="action">
                <EditIcon />
              </Button>
              <Button variant="action">
                <DeleteIcon />
              </Button>
              <span className="vertical-line"></span>
              <Button variant="control">
                <SingleArrow />
              </Button>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="conference">
      <div className="conferences-lists">
        <div className="title-line">
          <h3 className="title">Conferences</h3>
          <Button variant="control">
            Add new
            <AddIcon />
          </Button>
        </div>
        {renderList(attendant)}
        <div className="title-line">
          <h3 className="title">Speaker</h3>
        </div>
        {renderList(speaker)}
      </div>
      <div className="high-fives">
        <div className="title-line">
          <h3 className="title">{`High Fives: ${highFivesState?.length}`}</h3>
        </div>
        <div className="high-fives-list">
          <ul className="high-fives-items">
            {highFivesState?.map((highFive, index) => (
              <li key={`highFive-${index}`}>
                <p>
                  <span className="acter">{highFive?.sender?.name}</span>
                  {" to "}
                  <span className="acter">{highFive?.receiver?.name}</span>
                </p>
                <p>{highFive?.message}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ConferenceReview;
