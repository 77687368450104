import React, { useEffect, useState } from "react";
import { Button, Input, Dropdown, TypeAhead, Form } from "../ui";
import { CloseIcon, AddIcon, SearchIcon, DatePickerIcon } from "../../icons";

import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "./NewMeeting.scss";

import { useThunk } from "../../hooks/useThunk";
import { useSelector } from "react-redux";
import {
  createMeeting,
  updateMeeting,
  fetchUsers,
  fetchTagsCategories,
  fetchSingleMeeting,
} from "../../store";
import SelectFromList from "../ui/SelectFromList";
import CustomRecurring from "./CustomRecurring/CustomRecurring";

import { useNavigate, useParams } from "react-router-dom";

const NewMeeting = () => {
  const navigate = useNavigate();
  const { meetingId } = useParams();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const [meeting, setMeeting] = useState({
    meeting_date: null,
  });
  const [customRecurring, setCustomRecurring] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [existingAttachments, setExistingAttachments] = useState(
    meeting?.attachments || []
  );

  const [doCreateMeeting] = useThunk(createMeeting);
  const [doUpdateMeeting] = useThunk(updateMeeting);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doFetchTagsCategories] = useThunk(fetchTagsCategories);
  const [doFetchSingleMeeting] = useThunk(fetchSingleMeeting);
  const [searchQuery, setSearchQuery] = useState("");

  const singleMeetingState = useSelector(
    (state) => state.meetings
  )?.singleMeeting;

  useEffect(() => {
    doFetchTagsCategories();
  }, []);

  useEffect(() => {
    if (meetingId) {
      doFetchSingleMeeting(meetingId);
    }
  }, [meetingId]);

  useEffect(() => {
    if (singleMeetingState) {
      const formattedMeetingDate = moment(
        singleMeetingState.meeting_date,
        "DD.MM.YYYY H:mm:ss"
      ).toDate();
      setMeeting({
        ...singleMeetingState,
        meeting_date: formattedMeetingDate,
      });
    }
  }, [singleMeetingState]);

  const usersState = useSelector((state) => state.users)?.data;
  const tagsCategoriesState = useSelector(
    (state) => state.tags
  )?.tagsCategories;

  const meetingTypes = [
    { value: 1, label: "Daily / Stand up" },
    { value: 2, label: "One on One" },
    { value: 3, label: "Stakeholder" },
    { value: 4, label: "Mid year review" },
  ];
  const meetingRecurring = [
    { value: 1, label: "Custom" },
    { value: 2, label: "Does not repeat" },
    { value: 3, label: "Daily" },
    { value: 4, label: "Every weekday (Mon - Fri)" },
    { value: 5, label: "Weekly (predetermined day)" },
  ];

  const onSave = () => {
    if (!!meetingId) {
      let payload = {
        title: meeting?.title,
        description: meeting?.description,
        organizer: localUserData.id,
        others: meeting?.others?.map((other) =>
          other.email ? other.email : other
        ),
        duration: parseInt(meeting?.duration),
        recurring: meeting?.recurring,
        type: meeting?.type,
        meeting_date: moment(
          meeting?.meeting_date,
          "DD.MM.YYYY H:mm:ss"
        ).format("DD.MM.YYYY H:mm:ss"),
        attachments: meeting?.attachments,
        tags: meeting?.tags?.map((tag) => tag?.id),
      };
      const id = meeting?.id;

      if (meeting?.recurring === 1) {
        payload = { ...payload, custom_repeat: customRecurring };
      }

      doUpdateMeeting({
        id,
        payload,
      });
    } else {
      let payload = {
        ...meeting,
        meeting_date: moment(meeting.meeting_date).format("DD.MM.YYYY H:mm:ss"),
        organizer: localUserData.id,
        duration: parseInt(meeting?.duration),
        attachments: meeting?.attachments,
        others: meeting?.others?.map((other) =>
          other.email ? other.email : other
        ),
        tags: meeting?.tags?.map((tag) => tag?.id),
      };

      if (meeting?.type === 1) {
        payload = { ...payload, custom_repeat: customRecurring };
      }

      doCreateMeeting(payload);
    }
    navigate("/meetings");
  };

  const handleChange = (event, customName) => {
    if (customName) {
      setMeeting({ ...meeting, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setMeeting({ ...meeting, [name]: value });
  };

  const handleSearch = (searchTerm) => {
    doFetchUsers({ searchTerm: searchTerm });
  };

  const setOthers = (others) => {
    setMeeting({ ...meeting, others });
  };

  const handleRecurringChange = (recurring) => {
    setMeeting({ ...meeting, recurring });
  };

  const handleTypeChange = (type) => {
    setMeeting({ ...meeting, type });
  };

  useEffect(() => {
    doFetchUsers({});
  }, []);

  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const handleDateSelect = (event) => {
    setMeeting({
      ...meeting,
      meeting_date: event,
    });
  };

  const createAttachment = () => {
    const formData = new FormData();
    formData.append("resource", attachment[0]);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/create`,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      setExistingAttachments([...existingAttachments, data.data]);
    });
  };

  const handleAddAttachment = (event) => {
    setAttachment(event.target.files);
  };

  const handleRemoveAttachment = (id) => {
    const requestOptions = {
      method: "DELETE",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/${id}`,
      requestOptions
    );
    const reducedFiles = existingAttachments.filter((file) => file?.id !== id);
    setExistingAttachments(reducedFiles);
  };

  const handleSetTags = (tags) => {
    setMeeting({ ...meeting, tags });
  };

  const handleCustomRecurring = (customRecurring) => {
    setCustomRecurring(customRecurring);
  };

  useEffect(() => {
    if (!attachment.length) {
      return;
    }
    createAttachment();
  }, [attachment]);

  useEffect(() => {
    const fileCollection = existingAttachments.map((file) => file?.id);
    setMeeting({ ...meeting, attachments: fileCollection });
  }, [existingAttachments]);

  return (
    <div className="meetings-form-wrapper">
      <div className="meetings-form-header">
        <h2>{!!meetingId ? "Edit Meeting" : "New Meeting"}</h2>
      </div>
      <div className="meetings-form-body">
        <Form>
          <div className="form-group bottom-margin">
            <label>Meeting Title</label>
            <Input
              placeholder="New Title"
              name="title"
              value={meeting?.title}
              handleChange={handleChange}
            />
          </div>
          <div className="form-group bottom-margin">
            <label>Employees</label>
            <TypeAhead
              icon={<SearchIcon />}
              slug={(option) => `${option.first_name} ${option.last_name}`}
              options={usersState?.userData || []}
              handleSearch={(searchTerm) => handleSearch(searchTerm)}
              selected={meeting?.others || []}
              setSelected={setOthers}
              placeholder="Search for an employee"
              multiple={true}
            />
          </div>

          <div className="form-row bottom-margin">
            <div className="form-group">
              <label>Date and time</label>
              <div className="date-picker-holder">
                <div className="input-icon-wrapper">
                  <DatePickerIcon />
                  <DatePicker
                    selected={meeting?.meeting_date}
                    onChange={handleDateSelect}
                    timeInputLabel="Time:"
                    showTimeInput
                    dateFormat="dd.MM.yyyy. h:mm aa"
                    placeholderText="Select date"
                    minDate={subMonths(new Date(), 256)}
                    maxDate={addMonths(new Date(), 256)}
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Duration</label>
              <Input
                type="number"
                placeholder="Duration in minutes"
                name="duration"
                value={meeting?.duration}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="meetings-text">
            Add <span>description</span> or <span>attachments</span>
            <input
              type="file"
              name="image"
              multiple
              onChange={handleAddAttachment}
            />
            {!!existingAttachments?.length && (
              <ul className="_offcanvas-files-list">
                {existingAttachments?.map((attachment, index) => (
                  <li key={index}>
                    {!!meetingId ? (
                      <a
                        href={`${process.env.REACT_APP_BASE_API_URL}${attachment.path}`}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        <span>{attachment.title}</span>
                      </a>
                    ) : (
                      <span>{attachment.title}</span>
                    )}
                    <span onClick={() => handleRemoveAttachment(attachment.id)}>
                      <CloseIcon />
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <textarea
            className="bottom-margin"
            rows={3}
            name="description"
            value={meeting?.description}
            placeholder="Description..."
            onChange={handleChange}
          />
          <div className="form-row bottom-margin">
            <div className="form-group">
              <label>Type</label>
              <Dropdown
                options={meetingTypes}
                placeholder="Type"
                onChange={handleTypeChange}
                value={meeting?.type}
              />
            </div>
            <div className="form-group">
              <label>Recurring</label>
              <Dropdown
                options={meetingRecurring}
                placeholder="Recurring"
                onChange={handleRecurringChange}
                value={meeting?.recurring}
              />
            </div>
          </div>
          {meeting?.recurring === 1 ? (
            <div className="form-group bottom-margin">
              <CustomRecurring
                onChange={handleCustomRecurring}
                preselected={meeting?.customRecurring}
              />
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <label>Talking points</label>
            <SelectFromList
              list={tagsCategoriesState}
              onChange={handleSetTags}
              preselected={meeting?.tags}
            />
          </div>
          <div className="meetings-form-footer">
            <Button
              handleClick={onSave}
              disabled={
                !meeting?.title ||
                !meeting?.duration ||
                !meeting?.recurring ||
                !meeting?.type ||
                !meeting?.meeting_date
              }
            >
              <AddIcon />
              <span>{!!meetingId ? "Edit Meeting" : "Create Meeting"}</span>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NewMeeting;
