import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchFeedbackTasks = createAsyncThunk(
  "feedback/fetch/tasks",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/tasks-without-feedback`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserFeedbacks = createAsyncThunk(
  "feedback/fetch/user",
  async (userId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/feedbacks/${userId}/pending`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTaskFeedbacks = createAsyncThunk(
  "feedback/fetch/feedbacks",
  async (taskId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/tasks/${taskId}/feedbacks`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createTaskFeedback = createAsyncThunk(
  "feedback/create/task",
  async (payload) => {
    const taskId = payload.taskId;
    const feedback = {
      content: payload.content,
      userId: payload.userId,
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/tasks/${taskId}/feedbacks`,
        feedback
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchFeedbacks = createAsyncThunk(
  "feedback/fetch",
  async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/feedbacks/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createFeedback = createAsyncThunk(
  "feedback/create",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/feedbacks/give`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createRequireFeedback = createAsyncThunk(
  "feedback/create/require",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/feedbacks/require`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createAnswerFeedback = createAsyncThunk(
  "feedback/create/answer",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/feedbacks/${payload.feedbackId}/respond`,
        payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
