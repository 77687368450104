const CalendarLightIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57715 7.83737H17.4304"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7015 11.0913H13.7092"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0042 11.0913H10.0119"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.29816 11.0913H6.30588"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7015 14.3305H13.7092"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0042 14.3305H10.0119"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.29816 14.3305H6.30588"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3694 1.66699V4.40931"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.63795 1.66699V4.40931"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5319 2.9834H6.4758C4.02856 2.9834 2.5 4.34668 2.5 6.85259V14.394C2.5 16.9393 4.02856 18.3341 6.4758 18.3341H13.5242C15.9791 18.3341 17.5 16.9629 17.5 14.457V6.85259C17.5077 4.34668 15.9868 2.9834 13.5319 2.9834Z"
      stroke="#A1A1A1"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CalendarLightIcon;
