import { OutsideClick } from "../../ui";
import ChatActions from "../ChatActions";
import data from "@emoji-mart/data";
import { init } from "emoji-mart";
import { UserIcon } from "../../ui";

import "./UsersSidebar.scss";
init({ data });

const SingleChatItem = ({
  chat,
  handleOpenChatActions,
  handleClick,
  allUsers,
  showChatActions,
  chatActionId,
  selectedUserActions,
  setShowChatActions,
  activeUser,
  getAllChats,
}) => {
  const dotColor = () => {
    const current = allUsers[chat.uid];
    if (current?.status === 0) {
      return "green";
    }
    if (current?.status === 1 || current?.status === 4) {
      return "yellow";
    }
    if (current?.online && !current?.status) {
      return "green";
    }
    if (!current?.online && !current?.status) {
      return "yellow";
    }
    return "red";
  };

  return (
    <div
      onContextMenu={(e) => handleOpenChatActions(e, chat)}
      className={"chat-item" + (activeUser?.id === chat?.id ? " active" : "")}
      key={chat.id}
      onClick={() => handleClick(chat, chat.uuid, chat.uid)}
    >
      <div className="header-user">
        <UserIcon userImage={chat.image} userName={chat?.name} />
      </div>
      <p>{chat.name}</p>
      {chat.emoji?.shortcodes && (
        <em-emoji shortcodes={chat.emoji?.shortcodes}></em-emoji>
      )}
      {showChatActions && chatActionId === chat.id && (
        <OutsideClick onOutsideClick={() => setShowChatActions(false)}>
          <ChatActions
            selectedUser={selectedUserActions}
            getAllChats={getAllChats}
          />
        </OutsideClick>
      )}
    </div>
  );
};

export default SingleChatItem;
