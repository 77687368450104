import { useEffect, useState } from "react";

import {
  fetchUsers,
  deleteUser,
  createUser,
  updateUser,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { Pagination, Table } from "../../../ui";
import UsersModal from "./UsersModal";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetUserMessage } from "../../../../store/users/usersSlice";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

const Users = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;

  const [page, setPage] = useState(1);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doDeleteUser] = useThunk(deleteUser);
  const [doCreateUser, isLoading] = useThunk(createUser);
  const [doUpdateUser] = useThunk(updateUser);
  const [doShowToastMessage] = useToastMessage(resetUserMessage);

  const usersState = useSelector((state) => state.users)?.data;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state.users)?.message;

  useEffect(() => {
    if (!isLoading) {
      doFetchUsers({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchUsers]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const tableData = {
    rows: usersState?.userData,
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "First Name",
        slug: "first_name",
      },
      {
        id: 3,
        label: "Last Name",
        slug: "last_name",
      },
      {
        id: 4,
        label: "Username",
        slug: "email",
      },
    ],
  };

  const handleEdit = (user) => {
    dispatch(setFormType("edit"));
    dispatch(setFormData(user));
    dispatch(setActiveModal("Users-modal"));
  };
  const handleDelete = (user) => {
    doDeleteUser(user.id);
  };

  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSave = (user) => {
    if (activeModalState?.type === "edit") {
      const { image, ...rest } = user;
      let updatedUser = rest;
      if (typeof user.image !== "string") {
        updatedUser = user;
      }
      if (typeof user.manager === "object") {
        doUpdateUser({ ...updatedUser, manager: updatedUser.manager.id });
      } else {
        doUpdateUser(updatedUser);
      }
      handleModalClose();
      return;
    }
    doCreateUser(user);
    const auth = getAuth();

    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then((response) => {
        return updateProfile(auth.currentUser, {
          displayName: user.first_name + " " + user.last_name,
        });
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
    handleModalClose();
  };
  return (
    <>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        hasActions={true}
      />
      <Pagination
        currentPage={page}
        total={usersState?.pageData?.total}
        onPageChange={setPage}
        limit={usersState?.pageData?.perPage}
        position="center"
      />
      {activeModalState === "Users-modal" ? (
        <UsersModal
          showModal={activeModalState === "Users-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Users;
