import { createSlice } from "@reduxjs/toolkit";
import { fetchRoles, createRole, updateRole, deleteRole } from "./rolesThunks";

const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    roles: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetRolesMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
    builder.addCase(createRole.fulfilled, (state, action) => {
      state.roles.roleData = [...state.roles.roleData, action.payload.data];
      state.message = {
        type: "success",
        title: "Success",
        message: "Role successfully created",
      };
    });
    builder.addCase(updateRole.fulfilled, (state, action) => {
      const updatedState = state?.roles?.roleData?.map((role) => {
        return role.id === action.payload.data.id ? action.payload.data : role;
      });
      state.roles.roleData = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Role successfully updated",
      };
    });
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      const filteredRoles = state?.roles?.roleData?.filter(
        (role) => role.id !== action.meta.arg
      );
      state.roles.roleData = filteredRoles;
      state.message = {
        type: "success",
        title: "Success",
        message: "Role successfully deleted",
      };
    });
  },
});

export const { resetRolesMessage } = rolesSlice.actions;

export const rolesReducer = rolesSlice.reducer;
