import { OutsideClick } from "../../ui";
import ChatActions from "../ChatActions";
import "./UsersSidebar.scss";

const GroupChatItem = ({
  chat,
  handleClick,
  handleOpenChatActions,
  showChatActions,
  chatActionId,
  setShowChatActions,
  selectedUserActions,
  activeUser,
  getAllChats,
  groupType,
}) => {
  return (
    <div
      className={"chat-item" + (activeUser?.id === chat?.id ? " active" : "")}
      onClick={() => handleClick(chat, chat.uuid)}
    >
      <div onContextMenu={(e) => handleOpenChatActions(e, chat)}>
        <p>
          {chat?.type === 1 ? <span>+</span> : <span>#</span>}
          <span>{chat?.name}</span>
        </p>
        {showChatActions && chatActionId === chat.id && (
          <OutsideClick onOutsideClick={() => setShowChatActions(false)}>
            <ChatActions
              selectedUser={selectedUserActions}
              getAllChats={getAllChats}
              groupType={groupType}
            />
          </OutsideClick>
        )}
      </div>
    </div>
  );
};

export default GroupChatItem;
