import { EditIcon, DeleteIcon } from "../../../icons";

import "./styles.scss";

const Table = ({
  title,
  columns,
  rows,
  hasActions,
  handleEdit,
  handleDelete,
  hasEdit = true,
  hasDelete = true,
  customActions = [],
  isAdmin = false,
  tableStyles = {},
  handleRowClick,
}) => {
  const rowClick = (event, row) => {
    event.stopPropagation();
    handleRowClick(row);
  };

  const handleCustomActionClick = (event, action, row) => {
    event.stopPropagation();
    action(row);
  };

  const handleEditClick = (event, row) => {
    event.stopPropagation();
    handleEdit(row);
  };

  const handleDeleteClick = (event, row) => {
    event.stopPropagation();
    handleDelete(row);
  };

  return (
    <div className="table-wrapper" style={tableStyles}>
      {!!title && <h4>{title}</h4>}
      <table>
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th key={index}>{column?.label}</th>
            ))}
            {((!!hasActions && isAdmin) || customActions.length > 0) && (
              <th></th>
            )}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr
              key={index}
              onClick={(event) => (handleRowClick ? rowClick(event, row) : {})}
              className={!!handleRowClick ? "clickable-row" : ""}
            >
              {columns?.map((column, columnIndex) => (
                <td
                  className={column?.icon && "icon-field nowrap"}
                  key={columnIndex}
                >
                  <div className="table-data-wrapper">
                    {column?.icon}
                    {!column.colorOptions && row[column.slug]}
                    {column.colorOptions && (
                      <span
                        className={`${
                          column.colorOptions.find(
                            (item) => item.value === row[column.slug]
                          )?.color
                        } nowrap`}
                      >
                        {
                          column.colorOptions.find(
                            (item) => item.value === row[column.slug]
                          )?.label
                        }
                      </span>
                    )}
                  </div>
                </td>
              ))}
              {isAdmin && !!hasActions && (
                <td className="table-actions">
                  <div className="actions-wrapper">
                    {hasEdit && handleEdit ? (
                      <span onClick={(event) => handleEditClick(event, row)}>
                        <EditIcon />
                      </span>
                    ) : (
                      ""
                    )}
                    {hasDelete ? (
                      <span onClick={(event) => handleDeleteClick(event, row)}>
                        <DeleteIcon />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              )}
              {customActions.length > 0 && (
                <td className="table-actions">
                  <div className="actions-wrapper">
                    {customActions.map((customAction, actionIndex) => (
                      <div
                        key={actionIndex}
                        onClick={(event) =>
                          handleCustomActionClick(
                            event,
                            customAction.action,
                            row
                          )
                        }
                        className="custom-action"
                      >
                        {!!customAction?.defaultLabel
                          ? row[customAction?.defaultLabel]
                          : customAction?.element}
                      </div>
                    ))}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
