import React, { useState, useEffect } from "react";
import "./styles.scss";

const Switcher = ({ name, checked, handleChange }) => {
  const [isChecked, setIsChecked] = useState(checked || false);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  const toggleSwitch = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    handleChange && handleChange(name, newCheckedState);
  };
  return (
    <div
      className={`switcher ${isChecked ? "checked" : ""}`}
      onClick={toggleSwitch}
    >
      <div className="switcher-thumb"></div>
    </div>
  );
};
export default Switcher;
