import { Routes, Route } from "react-router-dom";

import Reports from "../components/Reports/Reports";
import Communication from "../components/Reports/modules/Communication/Communication";
import Chat from "../components/Reports/modules/Chat/Chat";

const ReportsRoutes = () => (
  <Routes>
    <Route path="/" element={<Reports />}>
      <Route path="chat" element={<Chat />} />
      <Route path="communication" element={<Communication />} />
    </Route>
  </Routes>
);

export default ReportsRoutes;
