import ReportsBar from "../../ReportsBar";
import { Modal } from "../../../ui";
import { useEffect } from "react";
import { useThunk } from "../../../../hooks/useThunk";
import { getChatTrends, getUserTrends } from "../../../../store";
import { useSelector } from "react-redux";

const TrendsModal = ({ showBarModal, handleCloseBarModal }) => {
  const [doGetSingleUserChatTrends] = useThunk(getChatTrends);
  const [doGetUserTrendsWeekly] = useThunk(getUserTrends);

  const formData = useSelector((state) => state.form)?.data;
  const formType = useSelector((state) => state.form)?.type;
  const singleChatTrends = useSelector((state) => state.reports.chatTrends);
  const singleUserTrends = useSelector((state) => state.reports.userTrends);

  useEffect(() => {
    switch (formType) {
      case "view-chat-trends":
        doGetSingleUserChatTrends(formData.uid);
        break;
      case "view-communication-trends":
        doGetUserTrendsWeekly(formData.id);
        break;
      default:
        break;
    }
  }, []);

  return (
    <Modal
      show={showBarModal}
      handleClose={handleCloseBarModal}
      title="Trends (last & this week)"
      hasFooter={false}
    >
      <ReportsBar
        barData={
          formType === "view-chat-trends" ? singleChatTrends : singleUserTrends
        }
      />
    </Modal>
  );
};

export default TrendsModal;
