const AttachmentIcon = () => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58333 17.3327C3.30556 17.3327 2.22222 16.8882 1.33333 15.9993C0.444444 15.1105 0 14.0271 0 12.7493V3.99935C0 3.08268 0.326389 2.29796 0.979167 1.64518C1.63194 0.992405 2.41667 0.666016 3.33333 0.666016C4.25 0.666016 5.03472 0.992405 5.6875 1.64518C6.34028 2.29796 6.66667 3.08268 6.66667 3.99935V11.916C6.66667 12.4993 6.46528 12.9924 6.0625 13.3952C5.65972 13.798 5.16667 13.9993 4.58333 13.9993C4 13.9993 3.50694 13.798 3.10417 13.3952C2.70139 12.9924 2.5 12.4993 2.5 11.916V4.62435C2.5 4.27917 2.77982 3.99935 3.125 3.99935C3.47018 3.99935 3.75 4.27917 3.75 4.62435V11.916C3.75 12.1521 3.82986 12.35 3.98958 12.5098C4.14931 12.6695 4.34722 12.7493 4.58333 12.7493C4.81944 12.7493 5.01736 12.6695 5.17708 12.5098C5.33681 12.35 5.41667 12.1521 5.41667 11.916V3.99935C5.41667 3.41602 5.21528 2.92296 4.8125 2.52018C4.40972 2.1174 3.91667 1.91602 3.33333 1.91602C2.75 1.91602 2.25694 2.1174 1.85417 2.52018C1.45139 2.92296 1.25 3.41602 1.25 3.99935V12.7493C1.25 13.666 1.57639 14.4507 2.22917 15.1035C2.88194 15.7563 3.66667 16.0827 4.58333 16.0827C5.5 16.0827 6.28472 15.7563 6.9375 15.1035C7.59028 14.4507 7.91667 13.666 7.91667 12.7493V4.62435C7.91667 4.27917 8.19649 3.99935 8.54167 3.99935C8.88684 3.99935 9.16667 4.27917 9.16667 4.62435V12.7493C9.16667 14.0271 8.72222 15.1105 7.83333 15.9993C6.94445 16.8882 5.86111 17.3327 4.58333 17.3327Z"
        fill="#A1A1A1"
      />
    </svg>
  );
};

export default AttachmentIcon;
