const SearchIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8463 15.1807L11.8796 11.0551C12.8995 9.84271 13.4583 8.31724 13.4583 6.72916C13.4583 3.01876 10.4396 0 6.72916 0C3.01876 0 0 3.01876 0 6.72916C0 10.4396 3.01876 13.4583 6.72916 13.4583C8.1221 13.4583 9.44949 13.0382 10.5844 12.2406L14.5812 16.3975C14.7483 16.571 14.973 16.6667 15.2138 16.6667C15.4417 16.6667 15.6579 16.5798 15.822 16.4218C16.1708 16.0862 16.1819 15.5297 15.8463 15.1807ZM6.72916 1.75543C9.47173 1.75543 11.7029 3.98659 11.7029 6.72916C11.7029 9.47173 9.47173 11.7029 6.72916 11.7029C3.98659 11.7029 1.75543 9.47173 1.75543 6.72916C1.75543 3.98659 3.98659 1.75543 6.72916 1.75543Z"
      fill="#666666"
    />
  </svg>
);
export default SearchIcon;
