import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchMatrixByPosition } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { Button, ProgressBar } from "../../../ui";
import { AddIcon, EditIcon, SingleArrow } from "../../../../icons";
import MiniMatrix from "../../../CareerPath/client/MiniMatrix";
import "./styles.scss";
import { dummyPosition } from "./dummyData";

const InitiativeReview = () => {
  const careerPath = dummyPosition;

  const matrixState = useSelector((state) => state.matrices)?.singleMatrix;
  const [doFetchMatrix] = useThunk(fetchMatrixByPosition);

  useEffect(() => {
    doFetchMatrix({
      positionId: careerPath?.position_id,
      userId: careerPath?.userId,
    });
  }, []);

  const renderList = (type) => {
    return (
      <ul>
        <li>
          <div className="head column">
            {type === "current" ? "Current path" : "Career path history"}
          </div>
          <div className="head column">Goal Position</div>
          <div className="head column">Start date</div>
          <div className="head column">
            {type === "current" ? "Deadline" : "Achieved"}
          </div>
          <div className="head column">Progress</div>
          <div className="head column" />
        </li>
        {type === "current" ? (
          <li>
            <div className="column">
              <p className="name">{careerPath?.name}</p>
              <p>{careerPath?.position}</p>
            </div>
            <div className="column">{careerPath?.goalPosition?.position}</div>
            <div className="column">{careerPath?.goalPosition?.startDate}</div>
            <div className="column">{careerPath?.goalPosition?.endDate}</div>
            <div className="column">
              <ProgressBar progress={careerPath?.goalPosition?.progress} />
            </div>
            <div className="column">
              <Button variant="action">
                <EditIcon />
              </Button>
              <span className="vertical-line"></span>
              <Button variant="control">
                <SingleArrow />
              </Button>
            </div>
          </li>
        ) : (
          careerPath?.history.map((path, index) => (
            <li key={`history-${index}`}>
              <div className="column">
                <p className="name">{careerPath?.name}</p>
                <p>{path?.position}</p>
              </div>
              <div className="column">{path?.goalPosition?.position}</div>
              <div className="column">{path?.startDate}</div>
              <div className="column">{path?.achieved}</div>
              <div className="column">
                <ProgressBar progress={path?.progress} />
              </div>
              <div className="column">
                <span className="vertical-line"></span>
                <Button variant="control">
                  <SingleArrow />
                </Button>
              </div>
            </li>
          ))
        )}
      </ul>
    );
  };

  return (
    <div className="initiative-review">
      <div className="initiative">
        <div className="title-line">
          <h2 className="title">Career path initiative</h2>
          <Button variant="control">
            Add new
            <AddIcon />
          </Button>
        </div>
        <div className="current-path-list">{renderList("current")}</div>
        <div className="career-history-list">{renderList("history")}</div>
      </div>
      <div className="graph">
        {!!matrixState ? (
          <MiniMatrix user={careerPath.userId} matrix={matrixState} />
        ) : (
          <p className="no-matrix">There is no matching career path</p>
        )}
      </div>
    </div>
  );
};

export default InitiativeReview;
