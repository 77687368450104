const WorkHistoryIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_295_22378"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="25"
    >
      <rect x="0.808594" y="0.549316" width="24" height="24" fill="white" />
    </mask>
    <g mask="url(#mask0_295_22378)">
      <path
        d="M10.8086 6.54932H14.8086V4.54932H10.8086V6.54932ZM18.8086 23.5493C17.4253 23.5493 16.2461 23.0618 15.2711 22.0868C14.2961 21.1118 13.8086 19.9326 13.8086 18.5493C13.8086 17.166 14.2961 15.9868 15.2711 15.0118C16.2461 14.0368 17.4253 13.5493 18.8086 13.5493C20.1919 13.5493 21.3711 14.0368 22.3461 15.0118C23.3211 15.9868 23.8086 17.166 23.8086 18.5493C23.8086 19.9326 23.3211 21.1118 22.3461 22.0868C21.3711 23.0618 20.1919 23.5493 18.8086 23.5493ZM20.4586 20.8993L21.1586 20.1993L19.3086 18.3493V15.5493H18.3086V18.7493L20.4586 20.8993ZM12.4836 21.5493H4.80859C4.25859 21.5493 3.78776 21.3535 3.39609 20.9618C3.00443 20.5702 2.80859 20.0993 2.80859 19.5493V8.54932C2.80859 7.99932 3.00443 7.52848 3.39609 7.13682C3.78776 6.74515 4.25859 6.54932 4.80859 6.54932H8.80859V4.54932C8.80859 3.99932 9.00443 3.52848 9.39609 3.13682C9.78776 2.74515 10.2586 2.54932 10.8086 2.54932H14.8086C15.3586 2.54932 15.8294 2.74515 16.2211 3.13682C16.6128 3.52848 16.8086 3.99932 16.8086 4.54932V6.54932H20.8086C21.3586 6.54932 21.8294 6.74515 22.2211 7.13682C22.6128 7.52848 22.8086 7.99932 22.8086 8.54932V12.8243C22.2253 12.4076 21.5919 12.091 20.9086 11.8743C20.2253 11.6576 19.5253 11.5493 18.8086 11.5493C16.8586 11.5493 15.2044 12.2285 13.8461 13.5868C12.4878 14.9452 11.8086 16.5993 11.8086 18.5493C11.8086 19.0826 11.8628 19.5993 11.9711 20.0993C12.0794 20.5993 12.2503 21.0826 12.4836 21.5493Z"
        fill="white"
      />
    </g>
  </svg>
);
export default WorkHistoryIcon;
