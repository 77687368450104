import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunk } from "../../../hooks/useThunk";
import { fetchTeams, fetchUsers } from "../../../store";
import { Table } from "../../ui";
import { ClockIcon } from "../../../icons";
import "./styles.scss";

const NonRespondents = () => {
  const viewDataState = useSelector((state) => state?.pageViews)?.data;
  const teamsState = useSelector((state) => state?.teams?.teams)?.teamData;
  const usersState = useSelector((state) => state?.users?.data)?.optionData;

  const [doFetchTeams] = useThunk(fetchTeams);
  const [doFetchUsers] = useThunk(fetchUsers);

  useEffect(() => {
    doFetchUsers({});
    doFetchTeams({});
  }, []);

  const reduceData = () => {
    if (!!teamsState && !!viewDataState && !!viewDataState?.receiver) {
      const selectedTeams = teamsState.filter((team) =>
        viewDataState?.receiver.includes(team?.id)
      );
      let allMembers = [];
      selectedTeams.forEach((team) => {
        allMembers = allMembers.concat(team?.members);
      });

      let allAnswersUsers = [];
      viewDataState?.questions.forEach((question) => {
        const answersUsersId = question?.answers.map((answer) => answer?.user);
        allAnswersUsers = allAnswersUsers.concat(answersUsersId);
      });
      const nonRespondentMembers = allMembers.filter(
        (member) => !allAnswersUsers.includes(member?.id)
      );

      return nonRespondentMembers;
    }

    return [];
  };

  const nonRespondentUsers = reduceData();

  const managerName = (userId) => {
    if (!usersState) {
      return;
    }
    const manager = usersState.find((user) => user?.id === userId);

    if (!!manager) {
      return `${manager?.first_name} ${manager?.last_name}`;
    } else {
      return "No manager";
    }
  };

  const tableData = {
    columns: [
      {
        id: 1,
        label: "Name",
        slug: "name",
      },
      {
        id: 2,
        label: "Team",
        slug: "team",
      },
      {
        id: 3,
        label: "Manager",
        slug: "manager",
      },
    ],
    rows: nonRespondentUsers.map((member) => {
      return {
        name: `${member?.first_name} ${member?.last_name}`,
        team: teamsState.find((team) => team?.id === member?.team)?.name,
        manager: managerName(member?.manager),
      };
    }),
  };

  return (
    <div className="non-respondents">
      <div className="date">
        Start date: <ClockIcon />
        <span>{`${viewDataState?.startDate}`}</span>
      </div>
      <div className="date">
        End date: <ClockIcon />
        <span>{`${viewDataState?.dueDate}`}</span>
      </div>
      <div className="count">{`Non-respondents count: ${nonRespondentUsers.length}`}</div>
      <Table title="Users" rows={tableData.rows} columns={tableData.columns} />
    </div>
  );
};

export default NonRespondents;
