const PathIcon = () => (
  <svg
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.725 27.5C24.7 27.5 23.8062 27.1938 23.0437 26.5813C22.2812 25.9688 21.8 25.225 21.6 24.35H13.6125C11.9625 24.35 10.5937 23.8063 9.50625 22.7188C8.41875 21.6313 7.875 20.2625 7.875 18.6125C7.875 16.9625 8.41875 15.5938 9.50625 14.5063C10.5937 13.4188 11.9625 12.875 13.6125 12.875H16.5C17.525 12.875 18.3625 12.55 19.0125 11.9C19.6625 11.25 19.9875 10.4125 19.9875 9.3875C19.9875 8.3625 19.6625 7.525 19.0125 6.875C18.3625 6.225 17.525 5.9 16.5 5.9H8.4C8.175 6.775 7.6875 7.51875 6.9375 8.13125C6.1875 8.74375 5.3 9.05 4.275 9.05C3.075 9.05 2.0625 8.6375 1.2375 7.8125C0.4125 6.9875 0 5.975 0 4.775C0 3.575 0.4125 2.5625 1.2375 1.7375C2.0625 0.9125 3.075 0.5 4.275 0.5C5.3 0.5 6.1875 0.80625 6.9375 1.41875C7.6875 2.03125 8.175 2.775 8.4 3.65H16.5C18.15 3.65 19.5187 4.19375 20.6062 5.28125C21.6937 6.36875 22.2375 7.7375 22.2375 9.3875C22.2375 11.0375 21.6937 12.4062 20.6062 13.4938C19.5187 14.5813 18.15 15.125 16.5 15.125H13.6125C12.5875 15.125 11.75 15.45 11.1 16.1C10.45 16.75 10.125 17.5875 10.125 18.6125C10.125 19.6375 10.45 20.475 11.1 21.125C11.75 21.775 12.5875 22.1 13.6125 22.1H21.6C21.825 21.225 22.3125 20.4813 23.0625 19.8688C23.8125 19.2563 24.7 18.95 25.725 18.95C26.925 18.95 27.9375 19.3625 28.7625 20.1875C29.5875 21.0125 30 22.025 30 23.225C30 24.425 29.5875 25.4375 28.7625 26.2625C27.9375 27.0875 26.925 27.5 25.725 27.5ZM4.275 6.8C4.85 6.8 5.33125 6.60625 5.71875 6.21875C6.10625 5.83125 6.3 5.35 6.3 4.775C6.3 4.2 6.10625 3.71875 5.71875 3.33125C5.33125 2.94375 4.85 2.75 4.275 2.75C3.7 2.75 3.21875 2.94375 2.83125 3.33125C2.44375 3.71875 2.25 4.2 2.25 4.775C2.25 5.35 2.44375 5.83125 2.83125 6.21875C3.21875 6.60625 3.7 6.8 4.275 6.8Z"
      fill="#0F2A3C"
    />
  </svg>
);
export default PathIcon;
