import { configureStore } from "@reduxjs/toolkit";
import { positionsReducer } from "./positions/positionsSlice";
import { tasksReducer } from "./tasks/tasksSlice";
import { usersReducer } from "./users/usersSlice";
import { employeesReducer } from "./employees/employeesSlice";
import { matricesReducer } from "./matrices/matricesSlice";
import { feedbackReducer } from "./feedback/feedbackSlice";
import { goalsReducer } from "./goals/goalsSlice";
import { highFivesReducer } from "./highFives/highFivesSlice";
import { pageViewsReducer } from "./pageViews/pageViewsSlice";
import { projectsReducer } from "./projects/projectsSlice";
import { teamsReducer } from "./teams/teamsSlice";
import { achievementsReducer } from "./achievements/achievementsSlice";
import { recognitionsReducer } from "./recognitions/recognitionsSlice";
import { supportCasesReducer } from "./supportCases/supportCasesSlice";
import { rolesReducer } from "./roles/rolesSlice";
import { permissionsReducer } from "./permissions/permissionsSlice";
import { meetingsReducer } from "./meetings/meetingsSlice";
import { toastMessagesReducer } from "./toastMessages/toastMessagesSlice";
import { checkinsReducer } from "./checkins/checkinsSlice";
import { surveysReducer } from "./surveys/surveysSlice";
import { careerReducer } from "./career/careerSlice";
import { formReducer } from "./form/formSlice";
import { modalReducer } from "./modal/modalSlice";
import { dailypulseReducer } from "./dailypulse/dailypulseSlice";
import { reportsReducer } from "./reports/reportsSlice";
import { tagsReducer } from "./tags/tagsSlice";

export const store = configureStore({
  reducer: {
    positions: positionsReducer,
    tasks: tasksReducer,
    users: usersReducer,
    employees: employeesReducer,
    matrices: matricesReducer,
    feedback: feedbackReducer,
    goals: goalsReducer,
    highFives: highFivesReducer,
    pageViews: pageViewsReducer,
    projects: projectsReducer,
    teams: teamsReducer,
    achievements: achievementsReducer,
    recognitions: recognitionsReducer,
    supportCases: supportCasesReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    meetings: meetingsReducer,
    toastMessages: toastMessagesReducer,
    checkins: checkinsReducer,
    surveys: surveysReducer,
    career: careerReducer,
    form: formReducer,
    modal: modalReducer,
    dailyPulse: dailypulseReducer,
    reports: reportsReducer,
    tags: tagsReducer,
  },
});

export * from "./positions/positionsThunks";
export * from "./tasks/tasksThunks";
export * from "./users/usersThunks";
export * from "./employees/employeesThunks";
export * from "./matrices/matricesThunks";
export * from "./feedback/feedbackThunks";
export * from "./goals/goalsThunks";
export * from "./highFives/highFivesThunks";
export * from "./projects/projectsThunks";
export * from "./teams/teamsThunks";
export * from "./achievements/achievementsThunks";
export * from "./recognitions/recognitionsThunks";
export * from "./supportCases/supportCasesThunks";
export * from "./roles/rolesThunks";
export * from "./permissions/permissionsThunks";
export * from "./meetings/meetingsThunks";
export * from "./checkins/checkinsThunk";
export * from "./surveys/surveysThunks";
export * from "./career/careerThunks";
export * from "./dailypulse/dailypulseThunks";
export * from "./reports/reportsThunks";
export * from "./tags/tagsThunks";
