import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchDailyPulse = createAsyncThunk(
  "dailypulse/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/dailyPulse/history`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDailyPulseSingle = createAsyncThunk(
  "dailypulse/single/fetch",
  async ({ user, from, to }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/dailyPulse/history?user=${user}&from=${from}&to=${to}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createDailyPulse = createAsyncThunk(
  "dailypulse/create",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/daily-pulse`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
