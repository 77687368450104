import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const ReportsBar = ({ customBarOptions, barData }) => {
  const data = {
    labels: barData?.weekly_trends?.map((item, index) => `label-${index}`),
    datasets: [
      {
        label: "Stats",
        backgroundColor: "rgb(19,37,88)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: barData?.weekly_trends,
      },
    ],
    maintainAspectRatio: false,
  };

  const options = () => {
    if (!!customBarOptions) {
      return customBarOptions;
    }
    return {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: false,
          grid: {
            display: false,
          },
        },
      },
      barPercentage: 0.5,
      maintainAspectRatio: false,
    };
  };
  return (
    <div className="chartHeight">
      <Bar data={data} options={options()} />
    </div>
  );
};

export default ReportsBar;
