import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "form",
  initialState: {
    type: "create",
    data: null,
  },
  reducers: {
    setFormType: (state, action) => {
      state.type = action.payload;
    },
    setFormData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setFormType, setFormData } = formSlice.actions;

export const formReducer = formSlice.reducer;
