import { CalendarLightIcon } from "../../../../icons";
import { taskStatuses } from "../../../../data/statuses";

export const tableColumns = [
  {
    id: 1,
    label: "Name",
    slug: "name",
  },
  {
    id: 2,
    label: "Description",
    slug: "description",
  },
  {
    id: 3,
    label: "Status",
    slug: "status",
    colorOptions: taskStatuses,
  },
  {
    id: 4,
    label: "Due Date",
    slug: "due_date",
    icon: <CalendarLightIcon />,
  },
  {
    id: 5,
    label: "Progress",
    slug: "progressComponent",
  },
  {
    id: 6,
    label: "Assignee",
    slug: "usersComponent",
  },
];
