import React from "react";
import { CloseIcon } from "../../../icons";
import "./styles.scss";
import { Button } from "../../ui";

const Modal = ({
  show = false,
  handleClose,
  title = "",
  children,
  hasFooter = true,
  confirmButtonLabel = "Confirm",
  handleConfirm,
  rejectButtonLabel = "Cancel",
  footerContent,
  className = "",
  disabledConfirm = false,
}) => {
  const handleInsideModalClick = (e) => {
    e.stopPropagation();
  };

  if (!show) return null;

  return (
    <div className={`modal-outside ${className}`} onClick={handleClose}>
      <div className="modal-dialog">
        <div className="modal-content" onClick={handleInsideModalClick}>
          <div className="modal-header">
            <span>{title}</span>
            <div className="close-icon-wrapper" onClick={handleClose}>
              <CloseIcon fill="white" />
            </div>
          </div>
          <div className="modal-body">{children}</div>
          {hasFooter && (
            <div className="modal-footer">
              {!!footerContent ? (
                footerContent
              ) : (
                <>
                  <Button variant="secondary" handleClick={handleClose}>
                    {rejectButtonLabel}
                  </Button>
                  <Button
                    handleClick={handleConfirm}
                    disabled={disabledConfirm}
                  >
                    {confirmButtonLabel}
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
