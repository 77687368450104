import { useState, useEffect } from "react";
import { UserIcon, Switcher } from "../ui";
import EmployeeList from "./EmployeeList";
import EmployeeTree from "./EmployeeTree";
import "./styles.scss";

import { fetchCareer } from "../../store";
import { useThunk } from "../../hooks/useThunk";
import { useSelector } from "react-redux";

const Career = () => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const [view, setView] = useState("list");
  const [employeesWithManager, setEmployeesWithManager] = useState([]);

  const [doFetchCareer] = useThunk(fetchCareer);

  const employeesState = useSelector((state) => state.career)?.employees;

  useEffect(() => {
    if (!!employeesState?.length) {
      const updatedEmployees = employeesState.map((employee) => {
        if (employee.manager) {
          return {
            ...employee,
            manager: employeesState.find((emp) => emp.id === employee.manager)
              ?.name,
          };
        } else {
          return employee;
        }
      });
      setEmployeesWithManager(updatedEmployees);
    }
  }, [employeesState]);

  useEffect(() => {
    doFetchCareer();
  }, []);

  const handleSwitcherChange = () => {
    setView(view === "list" ? "tree" : "list");
  };

  return (
    <div className="career-wrapper">
      <div className="career-header">
        <h2>Employee Tree</h2>
        <div className="current-employee">
          <UserIcon
            userImage={localUserData?.image || localUserData.photoUrl}
            userName={localUserData?.first_name}
            iconStyle={{
              width: "40px",
              height: "40px",
              fontSize: "30px",
              display: "inline-flex",
            }}
          />
          You
        </div>
        <div className="career-view-switcher">
          <span>List view</span>
          <Switcher
            handleChange={handleSwitcherChange}
            name="view"
            checked={view === "tree"}
          />
          <span>Tree view</span>
        </div>
      </div>
      <div className="career-content">
        {view === "list" ? (
          <EmployeeList employees={employeesWithManager} />
        ) : (
          <EmployeeTree employees={employeesState} />
        )}
      </div>
    </div>
  );
};
export default Career;
