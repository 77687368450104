import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchSurveys = createAsyncThunk(
  "surveys/fetch",
  async ({ status = 1, user = null }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/surveys`, {
        params: {
          status,
          user,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchClientSurveys = createAsyncThunk(
  "surveys/cleint/fetch",
  async ({ status = 1, user = null }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/surveys-client`, {
        params: {
          status,
          user,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSurveysNoDrafts = createAsyncThunk(
  "surveys/no-drafts/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/surveys-no-drafts`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleSurvey = createAsyncThunk(
  "surveys/fetch/single",
  async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/surveys/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleSurveyByUser = createAsyncThunk(
  "surveys/fetch/single/user",
  async ({ surveyId, userId }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/surveys/${surveyId}/user/${userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateSurvey = createAsyncThunk(
  "surveys/update",
  async (payload) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/surveys/${payload.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createSurvey = createAsyncThunk(
  "surveys/create",
  async (payload) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/surveys`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteSurvey = createAsyncThunk("surveys/delete", async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/surveys/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const addAnswers = createAsyncThunk(
  "surveys/client/add",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/surveys/${payload.id}/add-answers`,
        { questions: payload.questions }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
