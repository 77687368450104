import { createSlice } from "@reduxjs/toolkit";
import {
  fetchPositions,
  fetchSinglePosition,
  createPosition,
  updatePosition,
  deletePosition,
  fetchPositionEmployees,
} from "./positionsThunks";

const positionsSlice = createSlice({
  name: "positions",
  initialState: {
    data: [],
    singlePosition: null,
    employees: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetPositionsMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPositions.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchSinglePosition.fulfilled, (state, action) => {
      state.singlePosition = action.payload;
    });
    builder.addCase(createPosition.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Position successfully created",
      };
    });
    builder.addCase(updatePosition.fulfilled, (state, action) => {
      const updatedState = state.data.map((position) => {
        return position.id === action.payload.id ? action.payload : position;
      });
      state.data = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Position successfully updated",
      };
    });
    builder.addCase(deletePosition.fulfilled, (state, action) => {
      const filteredPositions = state.data.filter(
        (position) => position.id !== action.meta.arg
      );
      state.data = filteredPositions;
      state.message = {
        type: "success",
        title: "Success",
        message: "Position successfully deleted",
      };
    });
    builder.addCase(fetchPositionEmployees.fulfilled, (state, action) => {
      state.employees = action.payload;
    });
  },
});

export const { resetPositionsMessage } = positionsSlice.actions;

export const positionsReducer = positionsSlice.reducer;
