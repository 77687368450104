import React, { useState, useEffect } from "react";
import { ProgressBar, Table } from "../../ui";
import { ClockIcon } from "../../../icons";
import { setActiveViewData } from "../../../store/pageViews/pageViewsSlice";
import { useSelector, useDispatch } from "react-redux";
import { fetchClientSurveys, fetchSurveys } from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { useNavigate, useLocation } from "react-router-dom";
import { isArray } from "lodash";

const ClientSurveyTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const [page, setPage] = useState(1);
  const [rowClicked, setRowClicked] = useState(false);

  const pageViewState = useSelector((state) => state.pageViews)?.activeView;
  const surveysState = useSelector((state) => state.surveys)?.surveys;
  const isLoading = useSelector((state) => state.surveys)?.isLoading;
  const activeViewDataState = useSelector((state) => state.pageViews)?.data;

  const [doFetchSurveys] = useThunk(fetchClientSurveys);

  useEffect(() => {
    if (!isLoading) {
      doFetchSurveys({ status: 1, user: localUserData?.id });
    }
  }, [isLoading]);

  useEffect(() => {
    if (!!activeViewDataState && !isArray(activeViewDataState) && rowClicked) {
      navigate("/user-surveys/questions");
    }
    setRowClicked(false);
  }, [activeViewDataState, rowClicked]);

  const reducedSurveys = surveysState?.filter((survey) => {
    let respondents = [];
    survey?.questions.forEach((questions) => {
      respondents = respondents.concat(
        questions?.answers.map((answer) => answer?.user)
      );
    });
    if (!respondents.includes(localUserData?.id)) {
      return survey;
    } else {
      return null;
    }
  });

  const tableData = {
    rows: reducedSurveys?.map((survey) => {
      return {
        ...survey,
        dueDate: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <ClockIcon /> {survey?.dueDate}
          </div>
        ),
        progress: (
          <ProgressBar progress={survey?.percentageAnswered?.toFixed(0)} />
        ),
      };
    }),
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "title",
      },
      {
        id: 4,
        label: "Ending date",
        slug: "dueDate",
      },
      {
        id: 5,
        label: "Progress",
        slug: "progress",
      },
    ],
  };

  const handleViewSurvey = (row) => {
    dispatch(
      setActiveViewData(surveysState.find((survey) => survey?.id === row?.id))
    );
    setRowClicked(true);
  };

  return (
    <>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        hasActions={true}
        isAdmin={false}
        handleRowClick={handleViewSurvey}
      />
      {/* <PaginationControl
        page={page}
        between={4}
        total={tableData.rows.length}
        limit={5}
        changePage={(page) => setPage(page)}
        ellipsis={1}
      /> */}
    </>
  );
};

export default ClientSurveyTable;
