import React, { useEffect, useState } from "react";
import { Input } from "../../ui";
import "../styles.scss";
import "./styles.scss";

const ClientQuestion = ({ question, handleAnswer }) => {
  const [userAnswer, setUserAnswer] = useState([]);

  useEffect(() => {
    handleAnswer(question?.id, userAnswer);
  }, [userAnswer]);

  const handleOptionChange = (event) => {
    setUserAnswer([event.target.value]);
  };

  const handleMultiSelectChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setUserAnswer([...userAnswer, value]);
    } else {
      setUserAnswer(userAnswer.filter((item) => item !== value));
    }
  };

  const handleShortAnswer = (event) => {
    setUserAnswer([event.target.value]);
  };

  const chosenQuestion = () => {
    if (question?.type === 1) {
      return (
        <Input
          placeholder="Answer..."
          name="answer-multiple"
          value={userAnswer || ""}
          handleChange={handleShortAnswer}
        />
      );
    }
    if (question?.type === 2) {
      return (
        <ul className="answer-list radio-list">
          {question?.choices?.map((choice) => (
            <li key={`choice-${choice}`}>
              <input
                type="radio"
                id={choice}
                name={`option-${question?.id}`}
                value={choice}
                checked={userAnswer.includes(choice)}
                onChange={handleOptionChange}
              />
              <label>{choice}</label>
            </li>
          ))}
        </ul>
      );
    }

    return (
      <ul className="answer-list checkbox-list">
        {question?.choices?.map((choice) => (
          <li key={`choice-${choice}`}>
            <input
              type="checkbox"
              id={`choice-${choice}`}
              name={`option-${question?.id}`}
              value={choice}
              checked={userAnswer.includes(choice)}
              onChange={handleMultiSelectChange}
            />
            <label>{choice}</label>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="question-wrapper client-question-wrapper">
      <div className="question-header">
        <div className="question-inputs">
          <p className="question">{question?.content}</p>
        </div>
      </div>
      <div className="question-answer">{chosenQuestion()}</div>
      {!!question.isRequired && (
        <div className="question-footer">
          <p className="question-required">Required</p>
        </div>
      )}
    </div>
  );
};

export default ClientQuestion;
