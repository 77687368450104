import { useState, useEffect } from "react";
import DropdownSelect from "../../../DropdownSelect/DropdownSelect";

import { SearchIcon } from "../../../../icons";

import { statuses } from "./options";

import moment from "moment/moment";
import { Input } from "../../../ui";

const FeedbackList = ({ handleActiveView, tasksData, feedbackUserData }) => {
  const [tasks, setTasks] = useState(tasksData);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setTasks(tasksData);
  }, [tasksData]);

  useEffect(() => {
    if (!searchTerm) {
      setTasks(tasksData);
      return;
    }
    const filteredTasks = tasksData?.filter((task) => {
      return task?.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setTasks(filteredTasks);
  }, [searchTerm]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleSelectFilter = (statusId) => {
    const selectedStatus = statuses.find((status) => status.value === statusId);
    const filteredTasks = tasksData?.filter(
      (task) => task.status === selectedStatus.value
    );
    setTasks(filteredTasks);
  };

  return (
    <div className="carrer-content">
      <div className="career-header">
        <h2>Feedbacks</h2>
        <div className="header-actions header-feedback">
          <div className="table-search">
            <Input
              placeholder="Search"
              variant="form-control light"
              handleChange={handleSearch}
              icon={<SearchIcon />}
              value={searchTerm}
            />
          </div>
          <DropdownSelect
            options={statuses}
            handleSelect={handleSelectFilter}
            type="primary"
            placeholder="Filter by"
          />
        </div>
      </div>
      <div className="career-content">
        <div className="feedback-list-wrapper">
          <h3>Task feedbacks</h3>
          {!!tasks?.length ? (
            <ul className="feedback-list">
              {tasks?.map((task, index) => (
                <li
                  onClick={() => handleActiveView("feedback-form", task)}
                  key={index}
                >
                  <b>
                    {task?.position ? `${task?.position} -` : ""}{" "}
                    {`${task?.title}`}
                  </b>
                  <span>{task?.user.name}</span>
                  {task.startDate || task.endDate ? (
                    <p>{`${
                      task.startDate
                        ? moment(task.startDate).format("MMM DD")
                        : "/"
                    } - ${
                      task.endDate ? moment(task.endDate).format("MMM DD") : "/"
                    }`}</p>
                  ) : (
                    <p>Task not started</p>
                  )}
                  <div className="status-wrapper">
                    <span
                      className={`status ${
                        statuses.find((status) => status?.label === task.status)
                          ?.color
                      }`}
                    >
                      {
                        statuses.find((status) => status.value === task?.status)
                          ?.label
                      }
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="empty-list-message">No feedbacks</div>
          )}
        </div>
        <div className="feedback-list-wrapper">
          <h3>Client feedbacks</h3>
          {!!feedbackUserData?.length ? (
            <ul className="feedback-list">
              {feedbackUserData?.map((feedback, index) => (
                <li
                  onClick={() =>
                    handleActiveView("feedback-form-client", feedback)
                  }
                  key={index}
                >
                  <div className="feedback-message">
                    <b>{feedback?.question}</b>
                  </div>
                  <span>{`${feedback?.receiver?.name}`}</span>

                  <p>{`${
                    feedback?.createdAt
                      ? moment(feedback?.createdAt).format("MMM DD")
                      : "/"
                  }`}</p>

                  <div className="status-wrapper">
                    <span className="status blue">Pending</span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="empty-list-message">No feedbacks</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackList;
