import { useState, useEffect, useRef } from "react";
import { CloseIcon } from "../../../icons";
import Checkbox from "../Checkbox";
import Input from "../Input";
import "./styles.scss";

const SelectFromList = ({
  placeholder = "Select",
  list,
  preselected = [],
  onChange = () => {},
}) => {
  const contentRefs = useRef({});
  const [currentList, setCurrentList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [opened, setOpened] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setCurrentList(list);
  }, [list]);

  useEffect(() => {
    if (!!preselected?.length) {
      setSelected(preselected);
    }
  }, [preselected]);

  const handleRemoveItem = (item) => {
    const filterSelected = selected.filter(
      (currentItem) => currentItem.id !== item.id
    );
    setSelected(filterSelected);
  };

  const handleSelect = (item) => {
    if (selected?.includes(item)) {
      handleRemoveItem(item);
      return;
    }
    setSelected([...selected, item]);
  };

  const handleSetOpened = (item) => {
    if (opened?.includes(item)) {
      const filterOpened = opened.filter((currentItem) => currentItem !== item);
      setOpened(filterOpened);
      return;
    }
    setOpened([...opened, item]);
  };

  const updateHeights = (setHeightAuto) => {
    Object.keys(contentRefs.current).forEach((item) => {
      const element = contentRefs.current[item];
      if (element) {
        if (opened?.includes(parseInt(item))) {
          requestAnimationFrame(() => {
            element.style.height = !!setHeightAuto
              ? "auto"
              : `${element.scrollHeight}px`;
          });
        } else {
          element.style.height = "0px";
        }
      }
    });
  };

  const filterListBySearchTerm = (searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return list
      ?.map((category) => {
        const filteredTags = category.tags.filter((tag) =>
          tag.name.toLowerCase()?.includes(lowerCaseSearchTerm)
        );

        if (filteredTags.length > 0) {
          return { ...category, tags: filteredTags };
        }
        return null;
      })
      .filter(Boolean);
  };

  useEffect(() => {
    const result = filterListBySearchTerm(searchTerm);
    setCurrentList(result);
    updateHeights(true);
  }, [searchTerm]);

  useEffect(() => {
    updateHeights();
  }, [opened]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  return (
    <div className="select-from-list-wrapper">
      <div className="select-from-list-input">
        <ul className="select-input">
          {selected?.map((item) => (
            <li
              onClick={() => handleRemoveItem(item)}
              key={`selected-item-${item?.id}`}
            >
              {item?.name} <CloseIcon fill="#000" width="10" />
            </li>
          ))}
        </ul>
        <Input
          placeholder={!!selected?.length ? "" : placeholder}
          value={searchTerm || ""}
          handleChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="select-list">
        <ul>
          {!!currentList?.length ? (
            currentList?.map((item) => (
              <li
                className={`select-list-group ${
                  opened?.includes(item?.id) ? "show" : "hidden"
                }`}
                key={`item-${item?.id}`}
              >
                <p onClick={() => handleSetOpened(item?.id)}>{item?.name}</p>
                <ul
                  className="checkbox-list"
                  ref={(el) => (contentRefs.current[item.id] = el)}
                >
                  {item?.tags?.map((selectItem) => (
                    <li key={`subitem-${selectItem?.id}`}>
                      <Checkbox
                        checked={selected?.includes(selectItem)}
                        type="checkbox"
                        onChange={() => handleSelect(selectItem)}
                      />
                      {selectItem?.name}
                    </li>
                  ))}
                </ul>
              </li>
            ))
          ) : (
            <li>No results</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SelectFromList;
