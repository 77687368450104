import { Routes, Route } from "react-router-dom";

import Meetings from "../components/Meetings/Meetings";
import NewMeeting from "../components/Meetings/NewMeeting";

const SurveyRoutes = () => (
  <Routes>
    <Route path="/" element={<Meetings />} />
    <Route path="form" element={<NewMeeting />} />
    <Route path="form/:meetingId" element={<NewMeeting />} />
  </Routes>
);

export default SurveyRoutes;
