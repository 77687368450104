import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSurveys,
  fetchSingleSurvey,
  fetchSingleSurveyByUser,
  updateSurvey,
  createSurvey,
  deleteSurvey,
  addAnswers,
  fetchClientSurveys,
  fetchSurveysNoDrafts,
} from "./surveysThunks";

const surveysSlice = createSlice({
  name: "surveys",
  initialState: {
    surveys: [],
    singleSurvey: null,
    surveyCreator: null,
    isLoading: false,
    message: null,
  },
  reducers: {
    setSurveyCreator: (state, action) => {
      state.surveyCreator = action.payload;
    },
    resetSurveyMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSurveys.fulfilled, (state, action) => {
      state.surveys = action.payload;
    });
    builder.addCase(fetchClientSurveys.fulfilled, (state, action) => {
      state.surveys = action.payload;
    });
    builder.addCase(fetchSurveysNoDrafts.fulfilled, (state, action) => {
      state.surveys = action?.payload;
    });
    builder.addCase(fetchSingleSurvey.fulfilled, (state, action) => {
      state.singleSurvey = action.payload.data;
    });
    builder.addCase(fetchSingleSurveyByUser.fulfilled, (state, action) => {
      state.singleSurvey = action.payload;
    });
    builder.addCase(createSurvey.fulfilled, (state, action) => {
      state.surveys = [...state.surveys, action.payload.data];
      state.isLoading = false;
      state.message = {
        type: "success",
        title: "Success",
        message: "Survey successfully created",
      };
    });
    builder.addCase(createSurvey.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSurvey.fulfilled, (state, action) => {
      const updatedState = state?.surveys?.map((survey) => {
        return survey.id === action?.payload?.data?.id
          ? action?.payload?.data
          : survey;
      });
      state.message = {
        type: "success",
        title: "Success",
        message: "Survey successfully updated.",
      };
      state.surveys = updatedState;
      state.isLoading = false;
    });
    builder.addCase(updateSurvey.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSurvey.fulfilled, (state, action) => {
      const filteredSurveys = state?.surveys?.filter(
        (survey) => survey.id !== action.meta.arg
      );
      state.surveys = filteredSurveys;
      state.message = {
        type: "success",
        title: "Success",
        message: "Survey successfully deleted.",
      };
    });
    builder.addCase(addAnswers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addAnswers.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { setSurveyCreator, resetSurveyMessage } = surveysSlice.actions;

export const surveysReducer = surveysSlice.reducer;
