import "./styles.scss";
import { Modal, Input, Form } from "../../../ui";

const PreviewFeedbackForm = ({
  showPreviewFeedbackModal,
  handleModalClose,
  selectedFeedback,
}) => {
  return (
    <Modal
      show={showPreviewFeedbackModal}
      handleClose={handleModalClose}
      title="Feedback Preview"
      hasFooter={false}
    >
      <Form>
        <div className="form-group">
          <Input
            type="input"
            label="Feedback sender"
            placeholder="I would like feedback on..."
            name="question"
            value={selectedFeedback?.sender?.name || ""}
            disabled={true}
            handleChange={() => {}}
          />
        </div>
        <div className="form-group">
          <Input
            type="input"
            label="Feedback receiver"
            placeholder="I would like feedback on..."
            name="question"
            value={selectedFeedback?.receiver?.name || ""}
            disabled={true}
            handleChange={() => {}}
          />
        </div>
        {selectedFeedback?.question && (
          <div className="form-group bottom-margin">
            <label>What is feedback about?</label>
            <p className="feedback-input-item">
              {selectedFeedback?.question || ""}
            </p>
          </div>
        )}
        <div className="form-group bottom-margin">
          <label>Feedback</label>
          <p className="feedback-input-item">
            {selectedFeedback?.answer || ""}
          </p>
        </div>
      </Form>
    </Modal>
  );
};

export default PreviewFeedbackForm;
