import {
  ReviewIcon,
  MeetingsAndConferencesIcon,
  GoalsAndSurveysIcon,
  FeedbacksAndTasksIcon,
  InitiativeAndCompensationIcon,
} from "../../icons";
import PerformanceReview from "./modules/PerformanceReview";
import MeetingsAndConferenes from "./modules/MeetingsAndConferences";
import GoalsAndSurveys from "./modules/GoalsAndSurveys";
import FeedbacksAndTasks from "./modules/FeedbacksAndTasks";
import InitiativeAndCompensation from "./modules/InitiativeAndCompensation";

export const personalPerformanceViews = [
  {
    id: 1,
    label: "Performance review",
    icon: <ReviewIcon />,
    component: (props) => <PerformanceReview {...props} />,
  },
  {
    id: 2,
    label: "Meetings, Conference & Speaker",
    icon: <MeetingsAndConferencesIcon />,
    component: (props) => <MeetingsAndConferenes {...props} />,
  },
  {
    id: 3,
    label: "Goals & Surveys",
    icon: <GoalsAndSurveysIcon />,
    component: (props) => <GoalsAndSurveys {...props} />,
  },
  {
    id: 4,
    label: "Feedback & Tasks",
    icon: <FeedbacksAndTasksIcon />,
    component: (props) => <FeedbacksAndTasks {...props} />,
  },
  {
    id: 5,
    label: "Career path initiative & Compensation review",
    icon: <InitiativeAndCompensationIcon />,
    component: (props) => <InitiativeAndCompensation {...props} />,
  },
];
