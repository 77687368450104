import "./styles.scss";
import { OutsideClick } from "..";
import { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { CloseIcon } from "../../../icons";

const TypeAhead = ({
  name = "",
  label = "",
  placeholder = "Search...",
  icon = "",
  variant = "primary",
  labelClass = "",
  className = "",
  selected,
  setSelected,
  handleSearch,
  slug,
  options,
  disabled = false,
  multiple = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (searchTerm) {
      setIsShow(true);
      handleSearch(searchTerm);
    } else {
      if (!multiple) {
        handleClose();
      }
    }
  }, [searchTerm]);

  const handleValueChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClose = () => {
    setIsShow(false);
    setSearchTerm("");
  };

  const handleRemove = (index) => {
    const updatedSelected = selected.filter((_, i) => i !== index);
    setSelected(updatedSelected);
  };

  const handleSelectedItem = (option) => {
    if (multiple) {
      setSelected([...selected, option]);
    } else {
      setSelected([option]);
      handleClose();
    }
    handleClose();
  };

  return (
    <OutsideClick onOutsideClick={handleClose}>
      <div className="typeahead-wrapper">
        {label ? <label className={labelClass}>{label}</label> : ""}
        {!!icon && icon}
        <input
          name={name}
          type="text"
          className={`${variant} ${icon ? "icon-input" : ""} ${className}`}
          placeholder={placeholder}
          onChange={handleValueChange}
          value={searchTerm}
          disabled={disabled}
        />
        {isShow && (
          <>
            <div className="typeahead-search">
              {options.length === 0 && (
                <div className="typeahead-no-data">{"No matches found."}</div>
              )}
              {options?.map((option, index) => (
                <div
                  className="typeahead-item"
                  key={index}
                  onClick={() => {
                    handleSelectedItem(option);
                  }}
                >
                  {typeof slug === "function" ? slug(option) : option[slug]}
                </div>
              ))}
            </div>
            <div className="typeahead-close" onClick={handleClose}>
              <CloseIcon fill="black" />
            </div>
          </>
        )}
      </div>

      {selected && (
        <div className="typeahead-selected">
          {selected.map((itemSelected, index) => (
            <div key={index} className="typeahead-selected-item">
              <p>
                {typeof slug === "function"
                  ? slug(itemSelected)
                  : itemSelected[slug]}
              </p>
              <div
                className="typeahead-selected-remove"
                onClick={() => handleRemove(index)}
              >
                <CloseIcon fill="black" />
              </div>
            </div>
          ))}
        </div>
      )}
    </OutsideClick>
  );
};

export default TypeAhead;
