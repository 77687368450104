import { useState, useEffect } from "react";
import DropdownSelect from "../../../DropdownSelect/DropdownSelect";

import {
  fetchUsers,
  startCareerGrowth,
  createTaskSettings,
  updateTaskSettings,
  updateTask,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { CalendarIcon } from "../../../../icons";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

import "./styles.scss";
import { setActiveViewData } from "../../../../store/pageViews/pageViewsSlice";
import { setSelectedGoal } from "../../../../store/goals/goalsSlice";
import {
  resetSingleTask,
  setSelectedTask,
} from "../../../../store/tasks/tasksSlice";

import { Button } from "../../../ui";
import { taskStatusesSelection } from "../../../../data/statuses";
import { useNavigate } from "react-router-dom";

const TaskDetails = ({ clientTaskType }) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usersState = useSelector((state) => state.users)?.data?.optionData;
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doStartCareerGrowth] = useThunk(startCareerGrowth);
  const [doCreateTaskSettings] = useThunk(createTaskSettings);
  const [doUpdateTaskSettings] = useThunk(updateTaskSettings);
  const [doUpdateTask] = useThunk(updateTask);

  const singleTaskState = useSelector((state) => state.tasks)?.singleTask;
  const singlePositionState = useSelector(
    (state) => state.positions
  )?.singlePosition;
  const matrixState = useSelector((state) => state.matrices)?.singleMatrix;
  const pageDataState = useSelector((state) => state.pageViews)?.data;

  const [allUsers, setAllUsers] = useState([]);
  const [taskData, setTaskData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(singleTaskState?.status);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setTaskData({
      ...taskData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    }
    const reducedUsers = usersState?.map((user) => {
      return {
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      };
    });
    setAllUsers(reducedUsers);
  }, [usersState]);

  useEffect(() => {
    if (singleTaskState?.settings?.startDate) {
      setTaskData(singleTaskState?.settings);
    }

    if (!selectedStatus) {
      setSelectedStatus(singleTaskState?.status);
    }
  }, [singleTaskState]);

  const handleStatusChange = (statusId) => {
    const payload = {
      ...singleTaskState,
      userId: localUserData?.id,
      status: statusId,
    };
    setSelectedStatus(statusId);
    doUpdateTask(payload);
  };

  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const handleStartDate = (date) => {
    setTaskData({ ...taskData, startDate: moment(date).format("DD.MM.yyyy") });
  };

  const handleEndDate = (date) => {
    setTaskData({ ...taskData, endDate: moment(date).format("DD.MM.yyyy") });
  };

  const handleBack = () => {
    navigate("/your-career/home");
    dispatch(setActiveViewData({}));
    dispatch(setSelectedGoal(null));
    dispatch(resetSingleTask());
    dispatch(setSelectedTask(null));
  };

  const handleStartGrowth = () => {
    const settings = {
      mentorId: taskData?.mentorId || null,
      frequency: taskData?.frequency || null,
      startDate: taskData?.startDate || null,
      endDate: taskData?.endDate || null,
    };

    const payload = {
      userId: localUserData.id,
      careerPathId: matrixState.career_path.id,
      positionId: singlePositionState.id,
      settings,
    };
    doStartCareerGrowth(payload);
    handleBack();
  };

  const handleSaveTask = () => {
    const payload = {
      data: taskData,
      taskId: singleTaskState?.id,
    };
    if (!singleTaskState?.settings?.startDate) {
      doCreateTaskSettings(payload);
      navigate("/your-career/home");
      return;
    }
    payload.data = {
      ...payload.data,
      mentorId: singleTaskState?.settings?.mentor?.id || taskData.mentorId,
    };
    doUpdateTaskSettings(payload);
    navigate("/your-career/home");
    dispatch(setSelectedGoal(null));
    dispatch(resetSingleTask());
  };

  const handleCompleteTask = () => {
    const payload = {
      ...singleTaskState,
      userId: localUserData?.id,
      status: 100,
    };
    setSelectedStatus(100);
    doUpdateTask(payload);
  };

  const preselectedMentor = {
    label: `${singleTaskState?.settings?.mentor?.first_name} ${singleTaskState?.settings?.mentor?.last_name}`,
    value: singleTaskState?.settings?.mentor?.id,
  };

  const progressReminderOptions = [
    { value: 1, label: "Every day" },
    { value: 2, label: "Every 3 days" },
    { value: 3, label: "Every 5 days" },
  ];

  const handleRequestFeedback = () => {
    const payload = {
      ...singleTaskState,
      feedbackRequested: 1,
      userId: localUserData?.id,
    };
    doUpdateTask(payload);
  };

  return (
    <div className="career-vision-wrapper">
      <div className="career-header">
        <div className="header-title">
          <span onClick={handleBack}>Career Vision and Growth</span>
          <h2>{singleTaskState?.title}</h2>
        </div>
        {pageDataState?.type === "readonly" ? (
          <div className="header-actions">
            <Button handleClick={handleStartGrowth}>Start Career Growth</Button>
          </div>
        ) : (
          <div className="header-actions">
            {singleTaskState?.settings?.startDate &&
              singleTaskState?.status !== 100 && (
                <Button
                  variant="primary"
                  handleClick={handleCompleteTask}
                  className="button-icon"
                >
                  Complete
                </Button>
              )}
            <Button handleClick={handleRequestFeedback}>
              Request feedback
            </Button>
          </div>
        )}
      </div>
      <div className="career-position-content">
        <div className="position-details">
          <div className="details-header">
            <h3>Task Details</h3>
          </div>
          <div className="details-block">
            <label>Task title</label>
            <p>{singleTaskState?.title}</p>
          </div>
          <div className="details-block">
            <label>Task description</label>
            <p>{singleTaskState?.description}</p>
          </div>
          <div className="details-block">
            <label>Task Type</label>
            <p>Business</p>
          </div>
          <div className="details-block">
            <label>Assets</label>
            <ul>
              {singleTaskState?.assets?.map((asset, index) => (
                <li className="clickable-list-item" key={index}>
                  {asset}
                </li>
              ))}
            </ul>
          </div>
          <div className="details-block">
            <label>Resources</label>
            {!!singleTaskState?.resources?.length ? (
              <ul className="details-resources">
                {singleTaskState?.resources?.map((resource, index) => (
                  <li key={index}>
                    <a
                      href={`${process.env.REACT_APP_BASE_API_URL}${resource.path}`}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      {resource.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No resources</p>
            )}
          </div>
          <div className="details-block">
            <label>Notes</label>
            <p>{singleTaskState?.notes}</p>
          </div>
        </div>
        {pageDataState?.type !== "readonly" && (
          <div className="task-settings">
            <div className="task-settings-wrapper">
              <h3>Task Settings</h3>
              {/* <p className="task-settings-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
              </p> */}
              <div className="form-group">
                <label>Mentor</label>
                <DropdownSelect
                  options={allUsers}
                  handleSelect={handleChange}
                  type="primary"
                  name="mentorId"
                  preselected={preselectedMentor}
                />
              </div>
              <div className="form-group date-picker-holder">
                <label>Task Start</label>
                <DatePicker
                  name="startDate"
                  selected={startDate}
                  onChange={handleStartDate}
                  dateFormat="dd.MM.yyyy"
                  dateFormatCalendar={"MMM yyyy"}
                  placeholderText="Enter start date"
                  minDate={subMonths(new Date(), 256)}
                  maxDate={addMonths(new Date(), 256)}
                  value={taskData?.startDate || ""}
                  showMonthDropdown
                  showYearDropdown
                />
                <CalendarIcon />
              </div>
              <div className="form-group date-picker-holder">
                <label>Task End</label>
                <DatePicker
                  name="endDate"
                  selected={endDate}
                  onChange={handleEndDate}
                  dateFormat="dd.MM.yyyy"
                  dateFormatCalendar={"MMM yyyy"}
                  placeholderText="Enter end date"
                  minDate={subMonths(new Date(), 256)}
                  maxDate={addMonths(new Date(), 256)}
                  value={taskData?.endDate || ""}
                  showMonthDropdown
                  showYearDropdown
                />
                <CalendarIcon />
              </div>

              <div className="form-group">
                <label>
                  How often would you like to be reminded about progress?
                </label>
                <DropdownSelect
                  options={progressReminderOptions}
                  handleSelect={handleChange}
                  type="primary"
                  name="frequency"
                  preselected={progressReminderOptions.find(
                    (item) =>
                      item.value === singleTaskState?.settings?.frequency
                  )}
                />
              </div>
              <div className="task-settings-footer">
                <Button
                  handleClick={handleSaveTask}
                  disabled={!taskData?.startDate || !taskData?.endDate}
                >
                  Save
                </Button>
              </div>
            </div>
            <div className="task-status-wrapper">
              <h3>Task Status</h3>
              <p className="task-settings-desc">
                What is the status of your task?
              </p>
              <ul>
                {taskStatusesSelection.map((status, index) => (
                  <li
                    className={`${
                      status.id === selectedStatus ? "active" : ""
                    }`}
                    onClick={() => handleStatusChange(status.id)}
                    key={index}
                  >
                    {status.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskDetails;
