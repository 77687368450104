import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchTeams = createAsyncThunk(
  "teams/fetch",
  async ({ page = 1, searchTerm = "" }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/teams`, {
        params: {
          page,
          searchTerm,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createTeam = createAsyncThunk("teams/create", async (payload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/teams`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateTeam = createAsyncThunk("teams/update", async (payload) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/teams/${payload.id}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteTeam = createAsyncThunk("teams/delete", async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/teams/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});
