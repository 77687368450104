import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Table, Button, Filter, CardFilter } from "../../../ui";
import { useThunk } from "../../../../hooks/useThunk";
import { fetchSurveysNoDrafts } from "../../../../store";
import { ClockIcon, EditIcon, SuccessIcon, TIcon } from "../../../../icons";
import { convertTextToDate } from "../../../../utils/textFormat";

import "./styles.scss";

const SurveysReview = ({ localUserData }) => {
  const navigate = useNavigate();
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [doFetchSurveysNoDrafts] = useThunk(fetchSurveysNoDrafts);

  const surveysState = useSelector((state) => state?.surveys)?.surveys;

  const [usersSurvey, setUsersSurveys] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [reducedSurveys, setReducedSurveys] = useState({
    completed: [],
    uncompleted: [],
    overdue: [],
    underdue: [],
  });
  const [selectedFilter, setSelectedFilter] = useState(null);
  useEffect(() => {
    doFetchSurveysNoDrafts();
  }, []);

  useEffect(() => {
    if (!!surveysState) {
      setUsersSurveys(
        surveysState.filter((survey) =>
          survey?.receiver.includes(localUserData?.team)
        )
      );
    }
  }, [surveysState]);

  useEffect(() => {
    surveyReducer();
    setSurveys(usersSurvey);
  }, [usersSurvey]);

  useEffect(() => {
    selectedFilter !== null
      ? setSurveys(reducedSurveys[Object.keys(reducedSurveys)[selectedFilter]])
      : setSurveys(usersSurvey);
  }, [selectedFilter]);

  const surveyReducer = () => {
    const mappedSurveys = {
      completed: [],
      uncompleted: [],
      overdue: [],
      underdue: [],
    };

    usersSurvey?.forEach((survey) => {
      let respondents = [];
      survey?.questions.forEach((questions) => {
        respondents = respondents.concat(
          questions?.answers.map((answer) => answer?.user)
        );
      });
      if (!respondents.includes(localUserData?.id)) {
        mappedSurveys.uncompleted = mappedSurveys.uncompleted.concat(survey);
      } else {
        mappedSurveys.completed = mappedSurveys.completed.concat(survey);
      }
    });

    mappedSurveys.uncompleted.forEach((survey) => {
      if (convertTextToDate(survey?.dueDate) < new Date()) {
        mappedSurveys.overdue = mappedSurveys.overdue.concat(survey);
      } else {
        mappedSurveys.underdue = mappedSurveys.underdue.concat(survey);
      }
    });

    setReducedSurveys(mappedSurveys);
  };

  const surveyCompletionChecker = (id) => {
    if (reducedSurveys.completed.map((survey) => survey?.id).includes(id)) {
      return <div className="progress completed">Completed</div>;
    } else if (
      reducedSurveys.overdue.map((survey) => survey?.id).includes(id)
    ) {
      return <div className="progress overdue">Overdue</div>;
    } else if (
      reducedSurveys.underdue.map((survey) => survey?.id).includes(id)
    ) {
      return <div className="progress underdue">Continue</div>;
    }
  };

  const doabilityChecker = (id) => {
    if (reducedSurveys.underdue.map((survey) => survey?.id).includes(id)) {
      return (
        <Button variant="action" handleClick={() => navigate("/user-surveys")}>
          {/* TODO: kada se napravi link koji ce ukljucivati i id survey-a, dodati u navigate */}
          <EditIcon />
        </Button>
      );
    }
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(parseInt(e.target.value));
  };

  const handleResetFilter = () => {
    setSelectedFilter(null);
  };

  const surveysTableData = {
    rows: surveys.map((survey) => {
      return {
        ...survey,
        due_date: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <ClockIcon />
            {survey?.dueDate}
          </div>
        ),
        progress: surveyCompletionChecker(survey?.id),
        actions: doabilityChecker(survey?.id),
      };
    }),
    columns: [
      {
        id: 1,
        label: "Title",
        slug: "title",
      },
      {
        id: 2,
        label: "Ending date",
        slug: "due_date",
      },
      {
        id: 3,
        label: "Progress",
        slug: "progress",
      },
      {
        id: 4,
        label: "",
        slug: "actions",
      },
    ],
  };

  const pieData = {
    labels: [],
    datasets: [
      {
        label: "Number of Surveys",
        data: [
          reducedSurveys.completed.length,
          reducedSurveys.uncompleted.length,
        ],
        backgroundColor: ["#A9CE5B", "#F07171"],
        borderColor: ["#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="surveys">
        <div className="survey-table">
          <div className="title-line">
            <h3 className="title">Surveys</h3>
            <div className="filter-wrapper">
              <Filter
                filters={Object.keys(reducedSurveys).map(
                  (key) => key[0].toUpperCase() + key.slice(1)
                )}
                checked={selectedFilter}
                onRadioChange={handleFilterChange}
                onReset={handleResetFilter}
              />
            </div>
          </div>
          <Table
            rows={surveysTableData.rows}
            columns={surveysTableData.columns}
          />
        </div>
        <div className="chart">
          <div className="card-filters">
            <CardFilter
              borderColor="#109095"
              backgroundColor="#14B4BA"
              textColor="#fff"
              title="Received Surveys"
              count={usersSurvey.length}
              icon={<TIcon />}
            />
            <CardFilter
              borderColor="#86A347"
              backgroundColor="#A9CE5B"
              textColor="#fff"
              title="Completed Surveys"
              count={reducedSurveys.completed.length}
              icon={<SuccessIcon />}
            />
            <CardFilter
              borderColor="#B35656"
              backgroundColor="#F07171"
              textColor="#fff"
              title="Uncompleted Surveys"
              count={reducedSurveys.uncompleted.length}
              icon={
                <div className="uncompleted">
                  <ClockIcon />
                </div>
              }
            />
          </div>
          <div className="pie">
            <h4>My survey stats</h4>
            <Pie data={pieData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveysReview;
