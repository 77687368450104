const ChatBubbleIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="chat_bubble_FILL0_wght400_GRAD0_opsz48 1">
      <path
        id="Vector"
        d="M1.33301 14.6666V2.33325C1.33301 2.06659 1.43301 1.83325 1.63301 1.63325C1.83301 1.43325 2.06634 1.33325 2.33301 1.33325H13.6663C13.933 1.33325 14.1663 1.43325 14.3663 1.63325C14.5663 1.83325 14.6663 2.06659 14.6663 2.33325V10.9999C14.6663 11.2666 14.5663 11.4999 14.3663 11.6999C14.1663 11.8999 13.933 11.9999 13.6663 11.9999H3.99967L1.33301 14.6666ZM3.56634 10.9999H13.6663V2.33325H2.33301V12.3333L3.56634 10.9999Z"
        fill="#0F2A3C"
      />
    </g>
  </svg>
);
export default ChatBubbleIcon;
