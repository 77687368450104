import React, { useEffect, useState } from "react";
import "./Goals.css";
import { useParams } from "react-router-dom";
import StarBorderOutlineIcon from "@material-ui/icons/StarBorderOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import db from "../../firebase";
import Message from "../Message/Message";
import SidebarOption from "../Sidebar/SidebarOption/SidebarOption";

function Goals() {
    const data = [
        { name: "6000 new customers", email: "Bojan's team", role: "21/12/2022", active: true },
        { name: "New Ugofy", email: "Marko's team", role: "20/08/2022", active: true },
        { name: "New PR Event", email: "Vlada's team", role: "02/12/2022", active: true },
        { name: "Sanitation of electrical damage", email: "Milos's team", role: "21/09/2022", active: true },
    ];
    const columns = [
        {
            title: "Goal Name",
            field: "name",
        },
        {
            title: "Team",
            field: "email",
        },
        {
            title: "Deadline",
            field: "age",
        },
    ];

    return (
        <h1>Goals page</h1>
    );
}

export default Goals;
