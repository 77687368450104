import { useEffect, useState } from "react";
import "./SelectList.scss";

import { PlusIcon, CloseIcon } from "../../icons";
import { Input } from "../ui";

const SelectList = ({
  label,
  addLabel,
  placeholder,
  name,
  onChange,
  preselected,
}) => {
  const [selectedList, setSelectedList] = useState(preselected || []);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (!!inputValue) {
      onChange({ name, value: [...selectedList, inputValue] });
    }
  }, [inputValue]);

  const handleSelect = () => {
    if (!inputValue) {
      return;
    }
    setSelectedList([...selectedList, inputValue]);
    onChange({ name, value: [...selectedList, inputValue] });
    setInputValue("");
  };

  const handleRemove = (index) => {
    let reducedList = selectedList.filter((item, i) => i !== index);
    setSelectedList(reducedList);
  };

  return (
    <div className="select-list">
      <label>{label}</label>
      {!!selectedList.length && (
        <ul className="selected-items">
          {selectedList.map((item, index) => (
            <li key={index}>
              {item.includes("https:") || item.includes("http:") ? (
                <a href={item} target="_blank" rel="noopener noreferrer">
                  {item}
                </a>
              ) : (
                item
              )}
              <span className="remove-item" onClick={() => handleRemove(index)}>
                <CloseIcon />
              </span>
            </li>
          ))}
        </ul>
      )}
      <Input
        type="input"
        placeholder={placeholder}
        name={name}
        value={inputValue}
        handleChange={handleChange}
      />
      <div className="add-label" onClick={handleSelect}>
        <PlusIcon />
        <span>{addLabel}</span>
      </div>
    </div>
  );
};

export default SelectList;
