const ClockIcon = () => (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.875 0.791687H5.125V2.37502H9.875V0.791687ZM6.70833 11.0834H8.29167V6.33335H6.70833V11.0834ZM13.0654 5.85044L14.1896 4.72627C13.8492 4.32252 13.4771 3.94252 13.0733 3.61002L11.9492 4.73419C10.7221 3.75252 9.17833 3.16669 7.5 3.16669C3.56542 3.16669 0.375 6.3571 0.375 10.2917C0.375 14.2263 3.5575 17.4167 7.5 17.4167C11.4425 17.4167 14.625 14.2263 14.625 10.2917C14.625 8.61335 14.0392 7.0696 13.0654 5.85044ZM7.5 15.8334C4.43625 15.8334 1.95833 13.3554 1.95833 10.2917C1.95833 7.22794 4.43625 4.75002 7.5 4.75002C10.5638 4.75002 13.0417 7.22794 13.0417 10.2917C13.0417 13.3554 10.5638 15.8334 7.5 15.8334Z"
      fill="#A1A1A1"
    />
  </svg>
);

export default ClockIcon;
