import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const getUsersStats = createAsyncThunk(
  "/reports/fetch/users/meetings",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reports/users/meetings`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUsersTrends = createAsyncThunk(
  "/reports/fetch/users/meetings/trends",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reports/users/meetings/trends`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserTrends = createAsyncThunk(
  "/reports/fetch/meetings/trends/single",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reports/users/meetings/trends/weekly/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getTeamsStats = createAsyncThunk(
  "/reports/fetch/teams/meetings",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reports/teams/meetings`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getTeamsTrends = createAsyncThunk(
  "/reports/fetch/teams/meetings/trends",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reports/teams/meetings/trends`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserChatTrends = createAsyncThunk(
  "/reports/fetch/users/chat/trends",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reports/users/chats/trends`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getChatTrends = createAsyncThunk(
  "/reports/fetch/chat/trends/single",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reports/users/chats/trends/weekly/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
