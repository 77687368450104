import React, { useState } from "react";
import UserCard from "./UserCard";
import { personalPerformanceViews } from "./views";
import { Button } from "../ui";

const PersonalPerformance = () => {
  const [expandedViews, setExpandedViews] = useState([]);
  const handleToggleView = (id) => {
    setExpandedViews((prev) =>
      prev.includes(id)
        ? prev.filter((childId) => childId !== id)
        : [...prev, id]
    );
  };

  return (
    <div className="personal-performance-wrapper">
      <div className="personal-performance-content">
        <div className="personal-performance-header">
          <UserCard />
          <div className="header-info">
            <textarea rows="4" value="About me: "></textarea>
            <div className="button-group">
              <Button variant="secondary">Assign review</Button>
              <Button>Search</Button>
            </div>
          </div>
        </div>
        <div className="personal-performance-user-info">
          <table>
            <thead>
              <tr>
                <td>Name Surname</td>
                <td>Position/Seniority</td>
                <td>Department/Team</td>
                <td>Mentor</td>
                <td>Mentrees</td>
                <td>Current time in company</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>User Name Surname</td>
                <td>Front end developer/Medior</td>
                <td>Development/Product team</td>
                <td>Name Surname</td>
                <td>Name Surname</td>
                <td>Since: 2022-12-09</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="personal-performance-views">
          {personalPerformanceViews?.map((view) => (
            <React.Fragment key={`view-${view?.id}`}>
              <div
                className="performance-view"
                onClick={() => handleToggleView(view?.id)}
              >
                <div className="view-icon">{view?.icon}</div>
                <h3>{view?.label}</h3>
              </div>
              <div className="expandable-content">
                {view?.component({
                  isExpanded: expandedViews.includes(view?.id),
                  viewId: view?.id,
                })}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalPerformance;
