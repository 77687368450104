import React, { useState } from "react";
import { Button, Checkbox, OutsideClick } from "../index";
import { ResetIcon } from "../../../icons";
import FilterIcon from "../../../icons/FilterIcon";
import "./styles.scss";

const Filter = ({
  filters = [],
  checked = null,
  showResetButton = true,
  onRadioChange = () => {},
  onReset = () => {},
  value = "value",
}) => {
  const [isFiltersShown, setIsFiltersShown] = useState(false);

  const handleFilterBtnClick = () => {
    setIsFiltersShown(!isFiltersShown);
  };

  return (
    <OutsideClick
      onOutsideClick={() => {
        isFiltersShown && setIsFiltersShown(false);
      }}
    >
      <div className="filter">
        <div className="controls">
          {showResetButton && (
            <div className="reset-wrapper">
              {checked !== null && (
                <Button
                  variant="control"
                  handleClick={onReset}
                  buttonStyles={{ gap: "10px" }}
                >
                  Reset filters
                  <ResetIcon />
                </Button>
              )}
            </div>
          )}
          <Button
            variant="control"
            handleClick={handleFilterBtnClick}
            buttonStyles={{ gap: "10px" }}
          >
            Filter
            <FilterIcon />
          </Button>
        </div>
        <div className={`filters-container ${isFiltersShown ? "" : "hidden"}`}>
          <div className="filters">
            {filters.map((filter, index) => (
              <Checkbox
                key={index}
                type="radio"
                labelSide="right"
                checked={checked === filter[value] || checked === index}
                value={filter[value] || index}
                onChange={onRadioChange}
              >
                {filter?.label || filter}
              </Checkbox>
            ))}
          </div>
        </div>
      </div>
    </OutsideClick>
  );
};

export default Filter;
