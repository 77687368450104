import { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Input, Modal } from "../../../ui";

import { useSelector } from "react-redux";
import { fetchPermissions } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

const RolesModal = ({ showModal, handleModalClose, handleSave }) => {
  const [role, setRole] = useState(null);
  const [doFetchPermissions] = useThunk(fetchPermissions);

  const formData = useSelector((state) => state.form)?.data;
  const formType = useSelector((state) => state.form)?.type;
  const permissionsState = useSelector(
    (state) => state.permissions
  )?.permissions;

  useEffect(() => {
    doFetchPermissions();
  }, [doFetchPermissions]);

  useEffect(() => {
    if (formData) {
      setRole(formData);
    }
  }, [formData]);

  const roleLevels = [
    { value: 1, label: "Junior" },
    { value: 2, label: "Middle" },
    { value: 3, label: "Senior" },
  ];

  const handleChange = (event, customName) => {
    if (customName) {
      setRole({ ...role, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setRole({ ...role, [name]: value });
  };

  const handleMultiselectChange = (collection, name, isFullData) => {
    if (isFullData) {
      setRole({
        ...role,
        [name]: collection || null,
      });
      return;
    }
    setRole({
      ...role,
      [name]: collection ? collection?.map((item) => item.value) : null,
    });
  };

  const modalTitle = formType === "create" ? "Add Role" : "Edit Role";

  return (
    <Modal
      show={showModal}
      handleClose={handleModalClose}
      title={modalTitle}
      handleConfirm={() => handleSave(role)}
      confirmButtonLabel="Save Changes"
      rejectButtonLabel="Close"
    >
      <Form>
        <div className="form-group">
          <Input
            label="Type"
            type="input"
            placeholder="Enter type/name"
            name="name"
            value={role?.name || ""}
            handleChange={handleChange}
          />
        </div>
        {formType === "edit" && !role ? (
          <></>
        ) : (
          <div className="form-group">
            <label>Level</label>
            <Select
              name="level"
              placeholder="Select Level"
              options={roleLevels}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(event) => handleChange(event, "level")}
              defaultValue={roleLevels?.find(
                (item) => item?.value === role?.level
              )}
            />
          </div>
        )}
        <div className="form-group">
          <Input
            type="number"
            label="Experience (in years)"
            placeholder="Enter experience"
            name="experience"
            range="1-40"
            value={role?.experience}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group">
          <Input
            type="number"
            label="Time in role (in years)"
            placeholder="Enter number of years in role"
            name="time_in_role"
            range="1-40"
            value={role?.time_in_role}
            handleChange={handleChange}
          />
        </div>
        {formType === "edit" && !role ? (
          <></>
        ) : (
          <div className="form-group">
            <label>Permissions</label>
            <Select
              defaultValue={role?.permissions?.map((permission) => ({
                value: permission.id,
                label: permission.value,
                isFixed: true,
              }))}
              isMulti
              name="permissions"
              options={permissionsState?.map((permission) => ({
                value: permission.id,
                label: permission.value,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(event) =>
                handleMultiselectChange(event, "permissions")
              }
            />
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default RolesModal;
