const PencilIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5417 4.12497L11.875 1.45831L12.75 0.583305C12.9861 0.347194 13.2812 0.232611 13.6354 0.239555C13.9896 0.2465 14.2847 0.368028 14.5208 0.604139L15.4167 1.49997C15.6528 1.73608 15.7708 2.02775 15.7708 2.37497C15.7708 2.72219 15.6528 3.01386 15.4167 3.24997L14.5417 4.12497ZM13.6667 4.99997L3.16667 15.5H0.5V12.8333L11 2.33331L13.6667 4.99997Z"
      fill="#666666"
    />
  </svg>
);
export default PencilIcon;
