import { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

import { Form, Input, Modal } from "../../../ui";

import { CalendarIcon } from "../../../../icons";

import { useSelector } from "react-redux";
import { fetchUsers, fetchGoals } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

const AchievementModal = ({ showModal, handleModalClose, handleSave }) => {
  const [achievement, setAchievement] = useState(null);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doFetchGoals] = useThunk(fetchGoals);

  const formData = useSelector((state) => state.form)?.data;
  const formType = useSelector((state) => state.form)?.type;
  const usersState = useSelector((state) => state.users)?.data;
  const goalsState = useSelector((state) => state.goals)?.allGoals;

  useEffect(() => {
    doFetchUsers({});
    doFetchGoals();
  }, [doFetchUsers, doFetchGoals]);

  useEffect(() => {
    if (formData) {
      setAchievement({
        ...formData,
        users: formData?.users?.map((user) => ({
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
        })),
        goals: formData?.goals?.map((goal) => ({
          value: goal.id,
          label: goal.name,
        })),
      });
    }
  }, [formData]);

  const achievementsTypes = [
    { value: 1, label: "Personal" },
    { value: 2, label: "Team" },
    { value: 3, label: "Company" },
  ];
  const achievementsStatuses = [
    { value: 1, label: "Not started", color: "rest" },
    { value: 2, label: "Working", color: "purple" },
    { value: 3, label: "Parked", color: "yellow" },
    { value: 4, label: "Blocked", color: "red" },
    { value: 5, label: "Completed", color: "green" },
  ];

  const handleChange = (event, customName) => {
    if (customName) {
      setAchievement({ ...achievement, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setAchievement({ ...achievement, [name]: value });
  };

  const handleMultiselectChange = (collection, name, isFullData) => {
    if (isFullData) {
      setAchievement({
        ...achievement,
        [name]: collection || null,
      });
      return;
    }
    setAchievement({
      ...achievement,
      [name]: collection ? collection?.map((item) => item.value) : null,
    });
  };

  const setDate = (date) => {
    setAchievement({
      ...achievement,
      due_date: moment(date).format("DD.MM.yyyy"),
    });
  };

  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const modalTitle =
    formType === "create" ? "Add Achievement" : "Edit Achievement";

  return (
    <Modal
      show={showModal}
      handleClose={handleModalClose}
      title={modalTitle}
      handleConfirm={() =>
        handleSave({
          ...achievement,
          users: achievement?.users?.map((user) => user.value),
          goals: achievement?.goals?.map((goal) => goal.value),
        })
      }
      confirmButtonLabel="Save Changes"
      rejectButtonLabel="Close"
    >
      <Form>
        <div className="form-group">
          <Input
            type="input"
            label="Name"
            placeholder="Enter name"
            name="name"
            value={achievement?.name}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group">
          <Input
            type="input"
            label="Description"
            placeholder="Enter description"
            name="description"
            value={achievement?.description}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Type</label>
          <Select
            name="type"
            placeholder="Select Type"
            options={achievementsTypes}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => handleChange(event, "type")}
            value={achievementsTypes?.find(
              (item) => item?.value === achievement?.type
            )}
          />
        </div>
        <div className="form-group">
          <label>Status</label>
          <Select
            name="status"
            label="Status"
            placeholder="Select Status"
            options={achievementsStatuses}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => handleChange(event, "status")}
            value={achievementsStatuses?.find(
              (item) => item?.value === achievement?.status
            )}
          />
        </div>
        <div className="form-group">
          <label>Users</label>
          <Select
            value={achievement?.users?.map((user) => ({
              value: user.value,
              label: user.label,
              isFixed: true,
            }))}
            isMulti
            name="users"
            options={usersState?.optionData?.map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => handleMultiselectChange(event, "users", true)}
          />
        </div>
        <div className="form-group">
          <label>Goals</label>
          <Select
            value={achievement?.goals?.map((goal) => ({
              value: goal.value,
              label: goal.label,
              isFixed: true,
            }))}
            isMulti
            name="goals"
            options={goalsState?.optionData?.map((goal) => ({
              value: goal.id,
              label: goal.name,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => handleMultiselectChange(event, "goals", true)}
          />
        </div>
        <div className="date-picker-holder">
          <label>Due date</label>
          <DatePicker
            onChange={setDate}
            dateFormat="dd.MM.yyyy"
            dateFormatCalendar={"MMM yyyy"}
            placeholderText="Enter due date"
            minDate={subMonths(new Date(), 256)}
            maxDate={addMonths(new Date(), 256)}
            showMonthDropdown
            showYearDropdown
            value={achievement?.due_date ? achievement?.due_date : ""}
          />
          <CalendarIcon />
        </div>
      </Form>
    </Modal>
  );
};

export default AchievementModal;
