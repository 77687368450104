const CircleClockIcon = () => (
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 0H6.75V2.25H13.5V0ZM8.99937 15.875H11.2494V7.875H8.99937V15.875ZM18.0338 7.18875L19.6312 5.59125C19.1475 5.0175 18.6187 4.4775 18.045 4.005L16.4475 5.6025C14.7038 4.2075 12.51 3.375 10.125 3.375C4.53375 3.375 0 7.90875 0 13.5C0 19.0912 4.5225 23.625 10.125 23.625C15.7275 23.625 20.25 19.0912 20.25 13.5C20.25 11.115 19.4175 8.92125 18.0338 7.18875ZM10.125 21.375C5.77125 21.375 2.25 17.8538 2.25 13.5C2.25 9.14625 5.77125 5.625 10.125 5.625C14.4788 5.625 18 9.14625 18 13.5C18 17.8538 14.4788 21.375 10.125 21.375Z"
      fill="white"
    />
    <path d="M8.99937 20.125H11.2494V17.875H8.99937V20.125Z" fill="white" />
  </svg>
);

export default CircleClockIcon;
