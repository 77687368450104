import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchGoals = createAsyncThunk(
  "goals/fetch/all",
  async (page = 1) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/goals/page/${page}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUsersGoals = createAsyncThunk(
  "goals/fetch",
  async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/goals/user/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createGoal = createAsyncThunk("goals/create", async (payload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/goals`, payload, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateGoal = createAsyncThunk("goals/update", async (payload) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/goals/${payload.id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const createGoalSettings = createAsyncThunk(
  "goals/create/settings",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/goals/${payload.goalId}/settings`,
        payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateGoalSettings = createAsyncThunk(
  "goals/update/settings",
  async (payload) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/goals/${payload.goalId}/settings`,
        payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
