import { useEffect, useState, useRef } from "react";
import MeetingsReview from "./MeetingsReview";
import ConferenceReview from "./ConferenceReview";
import "./styles.scss";

const MeetingsAndConferences = ({ isExpanded }) => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);
  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view">
        <MeetingsReview />
        <hr />
        <ConferenceReview userId={localUserData?.id} />
      </div>
    </div>
  );
};

export default MeetingsAndConferences;
