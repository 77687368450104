import { useState, useEffect } from "react";

import SelectList from "../../../SelectList/SelectList";

import { CloseIcon, PlusIcon } from "../../../../icons";

import { createTask, updateTask } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

import { useSelector } from "react-redux";
import { Input, Modal } from "../../../ui";

const TaskForm = ({
  showModal,
  handleCloseTaskModal,
  taskFormType,
  isTaskLoading,
}) => {
  const [taskData, setTaskData] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [existingAttachments, setExistingAttachments] = useState([]);

  const [doCreateTask] = useThunk(createTask);
  const [doUpdateTask] = useThunk(updateTask);

  const singleTaskState = useSelector((state) => state.tasks)?.singleTask;

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setTaskData({ ...taskData, [name]: value });
    return;
  };

  const saveTask = () => {
    if (taskFormType === "edit") {
      doUpdateTask({ ...taskData, type: 2, privacy: 2 });
    } else {
      doCreateTask({ ...taskData, type: 2, privacy: 2 });
    }
    handleCloseTaskModal();
  };

  const createAttachment = () => {
    const formData = new FormData();
    formData.append("resource", attachment[0]);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/create`,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      setExistingAttachments([...existingAttachments, data.data]);
    });
  };

  const changeHandler = (event) => {
    setAttachment(event.target.files);
  };

  useEffect(() => {
    if (!attachment.length) {
      return;
    }
    createAttachment();
  }, [attachment]);

  useEffect(() => {
    const fileCollection = existingAttachments.map((file) => file.id);
    setTaskData({ ...taskData, resources: fileCollection });
  }, [existingAttachments]);

  const handleRemoveFile = (id) => {
    const requestOptions = {
      method: "DELETE",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/${id}`,
      requestOptions
    );
    const reducedFiles = existingAttachments.filter((file) => file.id !== id);
    setExistingAttachments(reducedFiles);
  };

  useEffect(() => {
    if (taskFormType === "edit" && singleTaskState) {
      setTaskData({
        ...taskData,
        id: singleTaskState?.id,
        title: singleTaskState?.title,
        description: singleTaskState?.description,
        type: 2,
        privacy: 2,
        results: singleTaskState?.results,
        assets: singleTaskState?.assets,
        resources: singleTaskState?.resources,
        notes: singleTaskState?.notes,
      });
      if (Array.isArray(singleTaskState?.resources)) {
        setExistingAttachments([...singleTaskState?.resources]);
      }
    }
  }, [singleTaskState]);

  return (
    <Modal
      show={showModal}
      handleClose={handleCloseTaskModal}
      title="Create Task"
      confirmButtonLabel="Save"
      handleConfirm={saveTask}
    >
      <form>
        <div className="form-group">
          <Input
            type="input"
            label="Task Title"
            placeholder="Enter name"
            name="title"
            value={taskData?.title || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group">
          <Input
            type="input"
            label="Task Description"
            placeholder="Enter description"
            name="description"
            value={taskData?.description || ""}
            handleChange={handleChange}
          />
        </div>
        {/* <div className="input-holder">
            <div className="form-group">
              <label>Type</label>
              <DropdownSelect
                name="type"
                background="dark"
                options={types}
                handleSelect={handleChange}
                type="primary"
                preselected={types.find(
                  (type) => type.value === parseInt(singleTaskState?.type)
                )}
              />
            </div>

            <div className="form-group">
              <label>Privacy settings</label>
              <DropdownSelect
                name="privacy"
                background="dark"
                options={privacy}
                handleSelect={handleChange}
                type="primary"
                preselected={privacy.find(
                  (item) => item.value === singleTaskState?.privacy
                )}
              />
            </div>
          </div> */}
        {!isTaskLoading ? (
          <>
            <SelectList
              label="What is your key result?"
              addLabel="Add another key result"
              placeholder="Enter text"
              name="results"
              onChange={handleChange}
              preselected={taskData?.results || ""}
            />

            <SelectList
              label="Assets"
              addLabel="Add another asset"
              placeholder="Enter link"
              name="assets"
              onChange={handleChange}
              preselected={taskData?.assets || ""}
            />
          </>
        ) : null}
        <div>
          <label>Resources</label>
          {!!existingAttachments?.length && (
            <ul className="selected-files">
              {existingAttachments.map((file, index) => (
                <li key={index}>
                  <a
                    href={`${process.env.REACT_APP_BASE_API_URL}${file.path}`}
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    {file.title}
                  </a>
                  <span onClick={() => handleRemoveFile(file.id)}>
                    <CloseIcon />
                  </span>
                </li>
              ))}
            </ul>
          )}
          <div className="file-select">
            {!existingAttachments.length && (
              <div className="file-select-empty">Add resource</div>
            )}
            <label>
              <PlusIcon />
              Add another resource
            </label>
            <input type="file" name="image" multiple onChange={changeHandler} />
          </div>
        </div>

        <div className="form-group">
          <Input
            type="input"
            label="Notes"
            placeholder="Enter description"
            name="notes"
            value={taskData?.notes || ""}
            handleChange={handleChange}
          />
        </div>
      </form>
    </Modal>
  );
};

export default TaskForm;
