import React, { useEffect, useState } from "react";
import { PencilIcon } from "../../icons";
import "./Settings.scss";
import { Table, Modal, Input, Form } from "../ui";
import { removeUnderScore } from "../../utils/textFormat";

function Settings({ screenWidth }) {
  const [settings, setSettings] = useState([]);
  const [setting, setSetting] = useState(null);
  const [editSetting, setEditSetting] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const getSettings = fetch(
    `${process.env.REACT_APP_BASE_API_URL}/settings/`
  ).then(function (response) {
    return response.json();
  });

  useEffect(async () => {
    let totalSettings = getSettings.then(function (jsonData) {
      return jsonData;
    });
    totalSettings.then(function (result) {
      //console.log(result);
      setSettings(result);
    });
  }, []);

  const handleEditSetting = (row) => {
    setEditSetting(true);
    setSetting(row);
  };

  const handleSettingClose = () => {
    setEditSetting(false);
  };

  const handleChangeSetting = (e) => {
    const { name, value, checked, type } = e.target;
    const newVal = type === "checkbox" ? checked : value;
    setSetting({ ...setting, [name]: newVal });
  };

  const saveSetting = (event, setting) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", setting.name);
    formData.append("description", setting.description);
    formData.append("value", setting.value);
    if (typeof setting.id !== "undefined") {
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      fetch(
        `${process.env.REACT_APP_BASE_API_URL}/settings/` +
          setting.id +
          "/edit",
        requestOptions
      );
      handleSettingClose();
      setTimeout(() => {
        forceUpdate();
        fetch(`${process.env.REACT_APP_BASE_API_URL}/settings/`)
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonData) {
            return jsonData;
          })
          .then(function (result) {
            setSettings(result);
          });
      }, 1000);
    }
  };

  const tableData = {
    columns: [
      { id: 1, label: "Name", slug: "name" },
      { id: 2, label: "Value", slug: "value" },
      { id: 3, label: "Description", slug: "description" },
      { id: 4, label: "" },
    ],
    rows: settings.map((setting) => {
      return {
        id: setting.id,
        name: removeUnderScore(setting.name),
        value: setting.value,
        description: setting.description,
      };
    }),
  };

  const tableActions = [
    {
      label: "Edit",
      action: (event, row) => handleEditSetting(event, row),
      element: <PencilIcon />,
    },
  ];

  return (
    <div className="table-wrapper" style={{ maxHeight: "calc(100vh - 90px)" }}>
      <h2>Ugofy settings</h2>
      <Table
        columns={tableData.columns}
        rows={tableData.rows}
        handleEdit={handleEditSetting}
        customActions={tableActions}
      />
      <Modal
        show={editSetting}
        handleClose={handleSettingClose}
        title={"Settings"}
        confirmButtonLabel="Save Changes"
        rejectButtonLabel="Close"
        handleConfirm={(event) => saveSetting(event, setting)}
      >
        <Form>
          <div className="form-group" controlId="formBasicFirstName">
            <Input
              type="input"
              label="Value"
              placeholder="Enter value"
              name="value"
              value={setting?.value}
              handleChange={handleChangeSetting}
            />
          </div>
          <div className="form-group" controlId="formBasicFirstName">
            <Input
              label="Description"
              rows={3}
              name="description"
              type="textarea"
              value={setting?.description}
              handleChange={handleChangeSetting}
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default Settings;
