import { Routes, Route } from "react-router-dom";
import Projects from "../components/Projects";
import TasksOverview from "../components/Projects/modules/tasksOverview/TasksOverview";
import ProjectsOverview from "../components/Projects/modules/projectsOverview/ProjectsOverview";
import SingleProject from "../components/Projects/modules/projectsOverview/SingleProject";
import ProjectForm from "../components/Projects/modules/projectsOverview/ProjectForm";

const ProjectsRoutes = () => (
  <Routes>
    <Route path="/" element={<Projects />}>
      <Route path="my-tasks-overview" element={<TasksOverview />} />
      <Route path="projects-overview" element={<ProjectsOverview />}>
        <Route path="single-project" element={<SingleProject />} />
      </Route>

      <Route path="project-form" element={<ProjectForm />} />
    </Route>
  </Routes>
);

export default ProjectsRoutes;
