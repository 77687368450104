import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import UsersSidebar from "./UsersSidebar/UsersSidebar";
import UserHeader from "./UserHeader/UserHeader";
import Messages from "./Messages/Messages";
import { SearchIcon, CloseIcon, Avatar } from "../../icons";
import { Offcanvas, UserInfo, UserIcon, Button, Modal, Input } from "../ui";
import Select from "react-select";
import "./Chat.css";
import db from "../../firebase";
import ChatInput from "../Chat/ChatInput/ChatInput";
import { useNavigate } from "react-router-dom";

import { useStateValue } from "../../StateProvider";

import { useSelector } from "react-redux";
import { fetchUsers } from "../../store";
import { useThunk } from "../../hooks/useThunk";

function Chat() {
  const [activeUser, setActiveUser] = useState(null);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showUserHeader, setShowUserHeader] = useState(false);
  const [showSearchUsers, setShowSearchUsers] = useState(false);
  const [showGroupUsers, setShowGroupUsers] = useState(false);
  const [showAddPeople, setShowAddPeople] = useState(false);
  const [privateChannels, setPrivateChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [allChannels, setAllChannels] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [{ user }] = useStateValue();
  const { roomIdParam, roomNameParam, bidParam } = useParams();
  const [roomId, setRoomId] = useState("");
  const [bid, setBid] = useState(null);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [roomDetails, setRoomDetails] = useState(null);
  const [roomMessages, setRoomMessages] = useState([]);
  const [otherUser, setOtherUser] = useState(null);
  const [members, setMembers] = useState([]);
  const [searchMembersTerm, setSearchMembersTerm] = useState("");
  const [searchMembers, setSearchMembers] = useState([]);
  const [totalMembers, setTotalMembers] = useState("");
  let localUserData = JSON.parse(localStorage.getItem("authUser"));
  const [selectedMember, setSelectedMember] = useState(null);
  const [viewMember, setViewMember] = useState(false);
  const [selectedPeopleToAdd, setSelectedPeopleToAdd] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const handleCloseMember = () => setViewMember(false);
  const [reducedSelectedUser, setReducedSelectedUser] = useState(null);

  const [doFetchUsers] = useThunk(fetchUsers);

  const usersState = useSelector((state) => state.users)?.data;

  const navigate = useNavigate();

  const handleActiveUser = (selectedUser) => {
    setActiveUser(selectedUser);
    setRoomId(selectedUser.uuid);
    selectedUser.public ? setBid(selectedUser.bid) : setBid("");
    setShowUserHeader(true);
    navigate(`/chat/${selectedUser.uuid}/${selectedUser.name}`);
  };

  useEffect(() => {
    if (!!roomIdParam && !!roomNameParam) {
      const selectedUserParams = {
        uuid: roomIdParam,
        name: roomNameParam,
      };
      handleActiveUser(selectedUserParams);
    }
    if (!!bidParam) {
      setBid(bidParam);
    }
  }, []);

  const handleUserInfo = () => {
    setShowUserInfo(!showUserInfo);
  };
  const handleProfileClose = () => {
    setShowUserInfo(false);
  };

  const handleCloseSearchUsers = (event) => {
    setShowSearchUsers(false);
  };
  const getUsers = () =>
    fetch(`${process.env.REACT_APP_BASE_API_URL}/users?page=1`)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setAllUsers(data.optionData);
      });

  const getUserChats = fetch(
    `${process.env.REACT_APP_BASE_API_URL}/chat/` + user?.id
  ).then(function (response) {
    return response.json();
  });

  const saveAddedUsers = (params) => {
    const userValues = params.users.map((user) => {
      return { value: user.value };
    });
    const requestData = {
      chat_id: params.chatId,
      users: userValues,
    };
    const formData = new FormData();
    formData.append("chat_id", requestData.chat_id);
    formData.append("users", JSON.stringify(requestData.users));
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/chat/members/add`,
      requestOptions
    );
  };

  useEffect(() => {
    doFetchUsers({});
    getUsers();
  }, []);

  useEffect(() => {
    db.collection("users")
      .where("idd", "!=", user.uid)
      .onSnapshot((snapshot) => {
        setPrivateChannels(
          snapshot.docs.map((doc) => ({
            label: doc.data().name,
            isFixed: true,
            value:
              user.uid.charAt(0) > doc.data().idd.charAt(0)
                ? `${user.uid + doc.data().idd}`
                : `${doc.data().idd + user.uid}`,
          }))
        );
      });
    getUserChats.then((data) => {
      setAllChannels(
        data.optionData.map((res) => ({
          value: res.uuid,
          label: res.name,
          bid: res.id,
          isFixed: true,
        }))
      );
    });
  }, [user?.id]);
  const selectUser = (event, selectedUser) => {
    if (selectedUser.bid !== undefined) {
      selectChannel(event, selectedUser);
    } else {
      const formData = new FormData();
      const userObject = {
        name: selectedUser.label,
        uuid: selectedUser.value,
      };
      formData.append("chat_name", selectedUser.label);
      formData.append("chat_uuid", selectedUser.value);
      formData.append("user_id", user?.id);
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      fetch(
        `${process.env.REACT_APP_BASE_API_URL}/chat/private`,
        requestOptions
      );
      handleActiveUser(userObject);
      setShowSearchUsers(false);
      window.location.replace(
        `/chat/${selectedUser.value}/${selectedUser.label}`
      );
    }
  };
  const selectChannel = (event, selectedChannel) => {
    const channelObject = {
      id: selectedChannel.bid,
      name: selectedChannel.label,
      uuid: selectedChannel.value,
    };
    const formData = new FormData();
    formData.append("chat_name", selectedChannel.label);
    formData.append("chat_id", selectedChannel.value);
    formData.append("user_id", user?.id);
    const requestOptions = {
      method: "PUT",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/chat/` +
        user?.id +
        "/" +
        selectedChannel.bid,
      requestOptions
    ).then((response) => {
      handleActiveUser(channelObject);
      setShowSearchUsers(false);
    });
    window.location.reload(false);
  };
  const showMember = (event, id) => {
    event.preventDefault();
    let member = fetch(
      `${process.env.REACT_APP_BASE_API_URL}/users/` + id
    ).then(function (response) {
      return response.json();
    });
    let locUser = member.then(function (jsonData) {
      return jsonData;
    });
    locUser.then(function (result) {
      setSelectedMember(result); // "Some User token"
    });
    setViewMember(true);
  };

  useEffect(() => {
    if (bid !== "") {
      fetch(`${process.env.REACT_APP_BASE_API_URL}/chat/members/` + bid)
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          setTotalMembers(data.length);
          setMembers(
            data.map((res) => ({
              value: res.id,
              name: res.name,
              image: res.image,
              role: res.role_name,
              availability: res.availability,
              status: res.status,
            }))
          );
        });
    }
    if (roomId !== "") {
      db.collection("rooms")
        .doc(roomId)
        .onSnapshot((snapshot) => setRoomDetails(snapshot.data()));

      let sel = roomId.replace(localUserData.uid, "");

      db.collection("rooms")
        .doc(roomId)
        .collection("messages")
        .orderBy("timestamp", "asc")
        .onSnapshot((snapshot) =>
          setRoomMessages(snapshot.docs.map((doc) => doc.data()))
        );
      db.collection("users")
        .doc(sel)
        .onSnapshot((snapshot) => setOtherUser(snapshot.data()));
    }
  }, [roomId, bid]);

  useEffect(() => {
    setShowUserInfo(false);
  }, [activeUser]);

  const handleGroupUsers = () => {
    setShowGroupUsers(true);
  };

  const handleCloseGroupUsers = () => {
    setShowGroupUsers(false);
  };

  const handleSearchMembers = (event) => {
    setSearchMembersTerm(event.target.value);
  };

  useEffect(() => {
    setSearchMembers(members);
  }, [members]);

  useEffect(() => {
    if (!allUsers || allUsers.length === 0) return;

    const userIds = members?.map((member) => member.value);
    const filteredUsers = allUsers?.filter(
      (item) => !userIds.includes(item.id)
    );
    const reducedUsers = filteredUsers?.map((user) => {
      return {
        value: user.id,
        name: `${user.first_name} ${user.last_name}`,
        image: user.image,
        role: user.role_name,
        availability: user.active,
      };
    });
    setReducedSelectedUser(reducedUsers);
  }, [allUsers, showAddPeople]);

  useEffect(() => {
    const filteredMembers = members?.filter((member) => {
      return member.name
        .toLowerCase()
        .includes(searchMembersTerm.toLowerCase());
    });
    setSearchMembers(filteredMembers);
  }, [searchMembersTerm]);

  const handleCloseAddPeople = () => {
    setShowAddPeople(false);
  };

  const handleToggleAddPeople = () => {
    setShowAddPeople(!showAddPeople);
    handleCloseGroupUsers();
  };

  const handleSelectAddPeople = (selected) => {
    setSelectedPeopleToAdd(selected);
  };

  const handleAddPeople = (event) => {
    event.preventDefault();
    setMembers([...members, selectedPeopleToAdd]);
    setSelectedPeopleToAdd(null);
    handleCloseAddPeople();
    setShowGroupUsers(true);
  };

  const handleSaveMembers = (event, activeUser) => {
    event.preventDefault();
    const params = {
      chatId: activeUser.id,
      users: members,
    };
    saveAddedUsers(params);
    setShowGroupUsers(false);
  };

  const handleGroupId = (id) => {
    setBid(id);
  };

  return (
    <div className="main-chat-wrapper">
      <div className="table-search grid-item">
        <div
          className="search-wrapper"
          onClick={() => setShowSearchUsers(!showSearchUsers)}
        >
          <SearchIcon />
          <span>Search</span>
        </div>
      </div>
      {showUserHeader && (
        <UserHeader
          activeUser={activeUser}
          handleUserInfo={handleUserInfo}
          handleGroupUsers={handleGroupUsers}
        />
      )}
      <UsersSidebar
        handleActiveUser={handleActiveUser}
        activeUser={activeUser}
        handleGroupId={handleGroupId}
      />
      <div className="chat-main-content grid-item">
        <div className="chat-input-wrapper">
          <Messages messages={roomMessages} members={members} />
          {showUserHeader && (
            <ChatInput channelName={roomDetails?.name} channelId={roomId} />
          )}
        </div>
        <Offcanvas
          show={showUserInfo}
          position="end"
          scroll={true}
          backdrop={true}
          onHide={handleProfileClose}
          className="user-info-offcanvas"
        >
          <UserInfo
            activeUser={activeUser}
            handleClose={handleProfileClose}
            showUserInfo={showUserInfo}
          />
        </Offcanvas>

        <Modal
          show={showGroupUsers}
          handleClose={handleCloseGroupUsers}
          title={activeUser?.name}
          handleConfirm={(event) => handleSaveMembers(event, activeUser)}
          confirmButtonLabel="Save changes"
          rejectButtonLabel="Close"
        >
          <form>
            <div className="mb-3">
              <div className="users-search table-search">
                <Input
                  type="input"
                  placeholder="Find members"
                  handleChange={handleSearchMembers}
                  icon={<SearchIcon />}
                />
              </div>
            </div>
            <ul className="group-users-list">
              <li className="add-people" onClick={handleToggleAddPeople}>
                <Avatar />
                <h4>+ Add people</h4>
              </li>
              {searchMembers?.map((member, index) => (
                <li key={index}>
                  <UserIcon userImage={member?.image} userName={member.name} />
                  <div className="user-text">
                    <h4
                      className={`user-in-group ${
                        !!member?.availability ? "" : "unavailable"
                      }`}
                    >
                      {member.name}
                    </h4>
                    <span>{member.role}</span>
                  </div>
                </li>
              ))}
            </ul>
          </form>
        </Modal>
        <Modal
          show={showAddPeople}
          handleClose={handleCloseAddPeople}
          title={`Add people to ${activeUser?.name}`}
          handleConfirm={(event) => handleAddPeople(event)}
          confirmButtonLabel="Add"
        >
          <form>
            <Select
              name="users"
              options={reducedSelectedUser}
              getOptionLabel={(option) => option.name}
              className="basic-multi-select select-people"
              classNamePrefix="select"
              placeholder="ex. Nathalie, or james@acme.com"
              onChange={handleSelectAddPeople}
            />
          </form>
        </Modal>
      </div>
      <Modal
        show={showSearchUsers}
        handleClose={handleCloseSearchUsers}
        title={"Search users"}
        handleConfirm={(event) => selectUser(event, selectedUser)}
        confirmButtonLabel="Go to chat"
        rejectButtonLabel="Close"
      >
        <form>
          <div className="form-group">
            <label>Find user</label>
            <Select
              name="users"
              options={privateChannels}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedUser}
            />
          </div>
          <div className="form-group">
            <label>Find channels</label>
            <Select
              name="channels"
              options={allChannels}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedUser}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Chat;
