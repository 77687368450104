const TipOfArrow = ({ size = "22", variant = "up", fill = "#263E4E" }) => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(variant === "up" ? { style: { transform: "rotate(180deg)" } } : {})}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.86983 4.85938C7.69626 5.04687 7.41485 5.04687 7.24129 4.85938L4 1.35796L0.758714 4.85938C0.585147 5.04687 0.303741 5.04687 0.130174 4.85938C-0.0433912 4.67188 -0.0433912 4.36789 0.130174 4.1804L4 -3.49691e-07L7.86983 4.1804C8.04339 4.36789 8.04339 4.67188 7.86983 4.85938Z"
        fill={fill}
      />
    </svg>
  );
};

export default TipOfArrow;
