import React from "react";
import "./styles.scss";
const IndividualOverview = ({ question }) => {
  const questionType =
    (question?.type === 2 && "radio") || (question?.type === 3 && "checkbox");

  const deriveAnswers = () => {
    let derivedAnswers = [];
    question?.answers.forEach((answer) => {
      derivedAnswers = derivedAnswers.concat(answer?.response);
    });

    console.log({ derivedAnswers });

    return derivedAnswers;
  };

  return (
    <div className="survey-overview-question">
      <div className="survey-overview-question-content">
        <h3>
          {question?.content}
          <span>{question?.answers?.length} responses</span>
        </h3>
        {question?.answers?.length > 0 ? (
          <div className="individual-multichoice-wrapper">
            <ul className={`answer-list radio-list`}>
              {question?.choices?.map((choice, index) => (
                <li key={index}>
                  {console.log({ choice })}
                  <input
                    type={questionType}
                    value={choice}
                    checked={deriveAnswers().includes(choice)}
                    readOnly
                  />
                  <label>{choice}</label>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="no-answers">No answers</div>
        )}
        <div className="survey-overview-required">
          <span>Required</span>
        </div>
      </div>
    </div>
  );
};

export default IndividualOverview;
