import { createSlice } from "@reduxjs/toolkit";
import { fetchPermissions } from "./permissionsThunks";

const permissionsSlice = createSlice({
  name: "permissions",
  initialState: {
    permissions: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
    });
  },
});

export const permissionsReducer = permissionsSlice.reducer;
