const CircleTIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11ZM9.80799 8.12803V18H12.176V8.12803H15.52V6.16003H6.46399V8.12803H9.80799Z"
      fill="white"
    />
  </svg>
);

export default CircleTIcon;
