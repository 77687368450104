const SettingsIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.08366 17.3337L6.66699 14.7087C6.4031 14.6114 6.12533 14.4795 5.83366 14.3128C5.54199 14.1462 5.28505 13.9725 5.06283 13.792L2.60449 14.917L0.666992 11.5003L2.91699 9.85449C2.88921 9.72949 2.87185 9.58713 2.86491 9.42741C2.85796 9.26769 2.85449 9.12533 2.85449 9.00033C2.85449 8.87533 2.85796 8.73296 2.86491 8.57324C2.87185 8.41352 2.88921 8.27116 2.91699 8.14616L0.666992 6.50033L2.60449 3.08366L5.06283 4.20866C5.28505 4.0281 5.54199 3.85449 5.83366 3.68783C6.12533 3.52116 6.4031 3.39616 6.66699 3.31283L7.08366 0.666992H10.917L11.3337 3.29199C11.5975 3.38921 11.8788 3.51769 12.1774 3.67741C12.476 3.83713 12.7295 4.01421 12.9378 4.20866L15.3962 3.08366L17.3337 6.50033L15.0837 8.10449C15.1114 8.24338 15.1288 8.39269 15.1357 8.55241C15.1427 8.71213 15.1462 8.86144 15.1462 9.00033C15.1462 9.13921 15.1427 9.28505 15.1357 9.43783C15.1288 9.5906 15.1114 9.73644 15.0837 9.87533L17.3337 11.5003L15.3962 14.917L12.9378 13.792C12.7156 13.9725 12.4621 14.1496 12.1774 14.3232C11.8927 14.4969 11.6114 14.6253 11.3337 14.7087L10.917 17.3337H7.08366ZM9.00033 11.7087C9.75033 11.7087 10.3892 11.4448 10.917 10.917C11.4448 10.3892 11.7087 9.75033 11.7087 9.00033C11.7087 8.25033 11.4448 7.61144 10.917 7.08366C10.3892 6.55588 9.75033 6.29199 9.00033 6.29199C8.25033 6.29199 7.61144 6.55588 7.08366 7.08366C6.55588 7.61144 6.29199 8.25033 6.29199 9.00033C6.29199 9.75033 6.55588 10.3892 7.08366 10.917C7.61144 11.4448 8.25033 11.7087 9.00033 11.7087Z"
      fill="white"
    />
  </svg>
);
export default SettingsIcon;
