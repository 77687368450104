import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    activeModal: "",
  },
  reducers: {
    setActiveModal: (state, action) => {
      state.activeModal = action.payload;
    },
  },
});

export const { setActiveModal } = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
