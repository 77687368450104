import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFeedbackTasks,
  fetchTaskFeedbacks,
  createTaskFeedback,
  fetchFeedbacks,
  createFeedback,
  createRequireFeedback,
  createAnswerFeedback,
  fetchUserFeedbacks,
} from "./feedbackThunks";

const feedbackSlice = createSlice({
  name: "tasks",
  initialState: {
    feedbacks: [],
    feedbackTasks: [],
    feedbacksUser: [],
    taskFeedbacks: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetFeedbackMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFeedbackTasks.fulfilled, (state, action) => {
      state.feedbackTasks = action.payload;
    });
    builder.addCase(fetchUserFeedbacks.fulfilled, (state, action) => {
      state.feedbacksUser = action.payload;
    });
    builder.addCase(fetchTaskFeedbacks.fulfilled, (state, action) => {
      state.taskFeedbacks = action.payload;
    });
    builder.addCase(createTaskFeedback.fulfilled, (state, action) => {
      state.taskFeedbacks = [...state.taskFeedbacks, action.payload];
      state.message = {
        type: "success",
        title: "Sent",
        message: "Feedback sent successfully",
      };
    });
    builder.addCase(fetchFeedbacks.fulfilled, (state, action) => {
      state.feedbacks = action.payload;
    });
    builder.addCase(createFeedback.fulfilled, (state, action) => {
      state.feedbacks = [...state.feedbacks, action.payload.feedback];
      state.message = {
        type: "success",
        title: "Sent",
        message: "Feedback sent successfully",
      };
    });
    builder.addCase(createRequireFeedback.fulfilled, (state, action) => {
      state.feedbacks = [...state.feedbacks, action.payload];
    });
    builder.addCase(createAnswerFeedback.fulfilled, (state, action) => {
      const updatedState = state.feedbacks.map((feedback) => {
        return feedback.id === action.payload.id ? action.payload : feedback;
      });
      state.feedbacks = updatedState;
      state.message = {
        type: "success",
        title: "Sent",
        message: "Feedback sent successfully",
      };
    });
  },
});

export const { resetFeedbackMessage } = feedbackSlice.actions;

export const feedbackReducer = feedbackSlice.reducer;
