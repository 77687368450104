import { createSlice } from "@reduxjs/toolkit";
import {
  fetchRecognitions,
  createRecognition,
  updateRecognition,
  deleteRecognition,
} from "./recognitionsThunks";

const recognitionsSlice = createSlice({
  name: "recognitions",
  initialState: {
    recognitions: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchRecognitions.fulfilled, (state, action) => {
      state.recognitions = action.payload;
    });
    builder.addCase(createRecognition.fulfilled, (state, action) => {
      state.recognitions.recognitionData = [...state.data, action.payload.data];
    });
    builder.addCase(updateRecognition.fulfilled, (state, action) => {
      const updatedState = state?.recognitions?.recognitionData?.map(
        (recognition) => {
          return recognition.id === action.payload.data.id
            ? action.payload.data
            : recognition;
        }
      );
      state.recognitions.recognitionData = updatedState;
    });
    builder.addCase(deleteRecognition.fulfilled, (state, action) => {
      const filteredRecognitions = state?.recognitions?.recognitionData?.filter(
        (recognition) => recognition.id !== action.meta.arg
      );
      state.recognitions.recognitionData = filteredRecognitions;
    });
  },
});

export const recognitionsReducer = recognitionsSlice.reducer;
