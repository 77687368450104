export const initialState = {
  user: null,
};

export const actionTypes = {
  SET_USER: "SET_USER",
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION"
};

const reducer = (state, action) => {
  ////console.log(action);

  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.subscription,
      };
    default:
      return state;
  }
};

export default reducer;
