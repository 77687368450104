import React, { useState, useEffect } from "react";
import "./Meetings.scss";
import "./Calendar.scss";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Header from "./Header";
import Year from "./Year";
import { UserIcon } from "../ui";

import { useThunk } from "../../hooks/useThunk";
import { useToastMessage } from "../../hooks/useToastMessage";
import { useSelector } from "react-redux";
import { fetchMeetings } from "../../store";
import { resetMeetingsMessage } from "../../store/meetings/meetingsSlice";

import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);
localizer.formats.yearHeaderFormat = "YYYY";

const Meetings = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [view, setView] = useState("month");
  const [date, setDate] = useState(new Date());

  const [doFetchMeetings] = useThunk(fetchMeetings);
  const [doShowToastMessage] = useToastMessage(resetMeetingsMessage);

  const meetingsState = useSelector((state) => state.meetings)?.allMeetings;
  const messageState = useSelector((state) => state?.meetings)?.message;

  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  useEffect(() => {
    doFetchMeetings(localUserData.id);
  }, []);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const EventComponent = ({ event }) => {
    const formattedStartTime = moment(event.start).format("h:mm A");
    const formattedEndTime = moment(event.end).format("h:mm A");

    const startTime = moment(formattedStartTime, "h:mm A");
    const endTime = moment(formattedEndTime, "h:mm A");

    const duration = moment.duration(endTime.diff(startTime));
    const durationHours = duration.hours();

    return (
      <div className="custom-event-class">
        {view === "month" ? (
          <span className="custom-event-title">{event.title}</span>
        ) : (
          <>
            <div className="custom-event-text">
              {durationHours >= 1 && (
                <p className="custom-event-time">
                  {formattedStartTime} - {formattedEndTime}
                </p>
              )}
              <span className="custom-event-title">{event.title}</span>
            </div>
            {durationHours >= 1 && (
              <div className="event-users">
                {event?.users?.map((user, index) => (
                  <UserIcon
                    key={index}
                    userImage={user.image}
                    userName={user.name}
                    iconStyle={{
                      backgroundColor: "#0f2a3c",
                      padding: "2px",
                      fontSize: "14px",
                    }}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const handleNextMonth = () => {
    const nextMonth = moment(date).add(1, view).toDate();
    setDate(nextMonth);
  };

  const handlePrevMonth = () => {
    const prevMonth = moment(date).add(-1, view).toDate();
    setDate(prevMonth);
  };

  const handleDayClick = (date) => {
    const formattedDate = moment(date).toDate();
    setView("day");
    setDate(formattedDate);
  };

  const handleOpenMeetingForm = (meetingId = null) => {
    navigate(meetingId ? `/meetings/form/${meetingId}` : "/meetings/form");
  };

  const handleEventClick = (event) => {
    navigate("/meetings/form");
    handleOpenMeetingForm(event.id);
  };

  const handleDrillDown = (date) => {
    handleDayClick(date);
  };

  useEffect(() => {
    const formattedEvents = meetingsState?.map((meet) => {
      return {
        id: meet.id,
        start: moment(meet.meeting_date, "DD.MM.YYYY H:mm:ss").toDate(),
        end: moment(meet.meeting_end_date, "DD.MM.YYYY H:mm:ss").toDate(),
        title: meet.title,
        description: meet.description,
        users: meet.others,
        status: meet.status,
        type: meet.type,
        existing_attachments: meet.existing_attachments,
      };
    });
    setEvents(formattedEvents);
  }, [meetingsState]);

  return (
    <div className="Meetings">
      <div className="meetings-wrapper">
        <Header
          showForm={() => handleOpenMeetingForm()}
          onViewChange={setView}
          handleNext={handleNextMonth}
          handlePrev={handlePrevMonth}
          date={date}
          view={view}
        />
      </div>
      <div className="meetings-main">
        <div className="calendar-wrapper">
          <Calendar
            localizer={localizer}
            defaultDate={date}
            date={date}
            defaultView="month"
            toolbar={false}
            view={view}
            views={{
              day: true,
              week: true,
              month: true,
              year: Year,
            }}
            onView={() => {}}
            onNavigate={() => {}}
            handleSelectDay={handleDayClick}
            messages={{ year: "Year" }}
            events={events}
            style={{ height: "calc(100vh - 313px)" }}
            components={{ event: EventComponent }}
            onDrillDown={handleDrillDown}
            onSelectEvent={handleEventClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Meetings;
