import { useEffect, useState } from "react";

import {
  fetchRoles,
  createRole,
  updateRole,
  deleteRole,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";
import { Table, Pagination } from "../../../ui";
import RolesModal from "./RolesModal";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetRolesMessage } from "../../../../store/roles/rolesSlice";

const Roles = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;
  const [page, setPage] = useState(1);
  const [doFetchRoles] = useThunk(fetchRoles);
  const [doCreateRole, isLoading] = useThunk(createRole);
  const [doUpdateRole] = useThunk(updateRole);
  const [doDeleteRole] = useThunk(deleteRole);
  const [doShowToastMessage] = useToastMessage(resetRolesMessage);

  const rolesState = useSelector((state) => state.roles)?.roles;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state.roles)?.message;

  useEffect(() => {
    if (!isLoading) {
      doFetchRoles({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchRoles]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const roleLevels = [
    { value: 1, label: "Junior" },
    { value: 2, label: "Middle" },
    { value: 3, label: "Senior" },
  ];

  const tableData = {
    rows: rolesState?.roleData,
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Type",
        slug: "name",
      },
      {
        id: 3,
        label: "Level",
        slug: "level",
        colorOptions: roleLevels,
      },
      {
        id: 4,
        label: "Experience (in Years)",
        slug: "experience",
      },
      {
        id: 5,
        label: "Time in role (in Years)",
        slug: "time_in_role",
      },
    ],
  };

  const handleEdit = (roles) => {
    dispatch(setFormType("edit"));
    dispatch(setFormData(roles));
    dispatch(setActiveModal("Roles-modal"));
  };

  const handleDelete = (role) => {
    doDeleteRole(role.id);
  };

  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSave = (role) => {
    if (activeModalState?.type === "edit") {
      doUpdateRole(role);
      handleModalClose();
      return;
    }
    doCreateRole(role);
    handleModalClose();
  };
  return (
    <div>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        hasActions={true}
      />
      <Pagination
        currentPage={page}
        total={rolesState?.pageData?.total}
        onPageChange={setPage}
        limit={rolesState?.pageData?.perPage}
        position="center"
      />

      {activeModalState === "Roles-modal" ? (
        <RolesModal
          showModal={activeModalState === "Roles-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Roles;
