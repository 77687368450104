import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useThunk } from "../../../../hooks/useThunk";
import { setToastMessage } from "../../../../store/toastMessages/toastMessagesSlice";
import {
  createAnswers,
  createMood,
  createPriority,
  deletePriority,
  fetchPriorities,
  fetchQuestions,
  fetchUserCheckinsHistory,
  updatePriority,
} from "../../../../store";

import { Button, Checkbox, Input } from "../../../ui";

import { EditIcon, DeleteIcon, AddIcon } from "../../../../icons";

import "./checkins.scss";
import moment from "moment";

const CheckIns = () => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const today = moment(new Date()).format("DD.MM.YY");

  const dispatch = useDispatch();

  const [doCreateMood] = useThunk(createMood);
  const [doCreatePriority] = useThunk(createPriority);
  const [doFetchPriorities] = useThunk(fetchPriorities);
  const [doUpdatePriority] = useThunk(updatePriority);
  const [doDeletePriority] = useThunk(deletePriority);
  const [doFetchQuestions] = useThunk(fetchQuestions);
  const [doCreateAnswer] = useThunk(createAnswers);
  const [doFetchHistory] = useThunk(fetchUserCheckinsHistory);

  const prioritiesState = useSelector((state) => state?.checkins)?.priorities;
  const questionsState = useSelector((state) => state?.checkins)?.questions;
  const checkinsHistoryState = useSelector(
    (state) => state?.checkins
  )?.userHistory;

  const [mood, setMood] = useState({
    user_id: localUserData?.id,
    grade: 0,
  });
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedCheckin, setSelectedCheckin] = useState(null);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [isCurrentCheckinHidden, setIsCurrentCheckinHidden] = useState(false);
  const [priorityEditActive, setPriorityEditActive] = useState(null);
  const [newPriority, setNewPriority] = useState(null);
  const [editedPriority, setEditedPriority] = useState(null);
  const [showFinishedPriorities, setShowFinishedPriorities] = useState(false);
  const [viewPriorityButton, setViewPriorityButton] = useState("View finished");
  const [viewHistoryButton, setViewHistoryButton] = useState({
    text: "View all",
    limit: 5,
    state: false,
  });

  useEffect(() => {
    doFetchPriorities(localUserData?.id);
    doFetchQuestions();
    doFetchHistory(localUserData?.id);
  }, []);

  // Zakomentarisano za podtrebe demo-a

  // useEffect(() => {
  //   if (checkinsHistoryState.length > 0) {
  //     setSelectedCheckin(
  //       checkinsHistoryState.find((history) => {
  //         if (history?.date === today) {
  //           setIsCurrentCheckinHidden(true);
  //           return history;
  //         }
  //         setIsCurrentCheckinHidden(false);
  //         return null;
  //       })
  //     );
  //   }
  // }, [checkinsHistoryState]);

  useEffect(() => {
    if (!!selectedCheckin) {
      setIsEditDisabled(true);
      setQuestions(selectedCheckin?.questions);
      return;
    }
    setQuestions(questionsState);
    setIsEditDisabled(false);
  }, [selectedCheckin, questionsState]);

  useEffect(() => {
    if (!!questionsState) {
      setAnswers(
        questionsState.map((question) => ({
          question: question?.id,
          answer: "",
          user_id: localUserData?.id,
        }))
      );
    }
  }, [questionsState]);

  const handleMoodClick = (value) => {
    setMood({ ...mood, grade: value });
  };

  const handleCheckboxChange = (priority, check) => {
    let status = 0;
    if (check) {
      status = 1;
    } else {
      status = 0;
    }
    const payload = {
      id: priority?.id,
      user: localUserData?.id,
      text: priority?.text,
      status: status,
    };
    if (payload.status !== null) {
      doUpdatePriority(payload);
    }
  };

  const handleAddPriorityChange = (e) => {
    setNewPriority(e?.target?.value);
  };

  const handleActionButtonClick = async (action, id, text = "") => {
    switch (action) {
      case "edit":
        setEditedPriority(text);
        setPriorityEditActive(id);
        break;
      case "delete":
        doDeletePriority(id);
        break;
      case "save":
        const editedPayload = {
          id: id,
          user: localUserData?.id,
          text: editedPriority,
          status: 0,
        };
        doUpdatePriority(editedPayload);
        setPriorityEditActive(null);
        setEditedPriority(null);
        break;
      case "cancel":
        setPriorityEditActive(null);
        break;
      case "add":
        const addedPayload = {
          user: localUserData?.id,
          text: newPriority,
          status: 0,
        };
        !!addedPayload?.text && doCreatePriority(addedPayload);
        setNewPriority(null);
        break;
      default:
        break;
    }
  };

  const handleFinishedPrioritiesClick = () => {
    setShowFinishedPriorities(!showFinishedPriorities);
    showFinishedPriorities
      ? setViewPriorityButton("View finished")
      : setViewPriorityButton("View unfinished");
  };

  const handleAnswerChange = (id, value) => {
    setAnswers((prev) =>
      prev.map((answerObj) => {
        if (answerObj?.question === id) {
          return { ...answerObj, answer: value };
        } else {
          return answerObj;
        }
      })
    );
  };

  const handleSubmit = () => {
    let errors = [];
    if (mood?.grade === 0) {
      errors.push(
        new Error("You have to tell us how you feel on a scale from 1 to 5.")
      );
    }
    for (let i = 0; i < answers.length; i++) {
      if (!answers[i]?.answer) {
        errors.push(new Error("You have to answer all the questions."));
        break;
      }
    }
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(
          setToastMessage({
            title: "Error",
            type: "error",
            message: error.message,
          })
        );
      });
      return;
    }

    doCreateMood(mood);

    answers.forEach((answerObj) => {
      doCreateAnswer(answerObj);
    });
    doFetchHistory(localUserData?.id);
  };

  const hadnleHistoryCurrent = () => {
    setSelectedCheckin(null);
  };

  const handleHistoryClick = (index) => {
    setSelectedCheckin(checkinsHistoryState[index]);
    setIsEditDisabled(true);
  };

  const handleViewHistoryClick = () => {
    viewHistoryButton?.state
      ? setViewHistoryButton({
          text: "View all",
          limit: 5,
          state: false,
        })
      : setViewHistoryButton({
          text: "View less",
          limit: null,
          state: true,
        });
  };

  return (
    <div className="check-ins-wrapper">
      <div className="check-ins-view">
        <h2>{`My check-in: ${selectedCheckin?.date || today}`}</h2>
        <div className="mood">
          <h3>How did you feel at work since last Check-in</h3>
          <div className="mood-selector">
            <span>Awful</span>
            {[1, 2, 3, 4, 5].map((num, index) => (
              <Button
                key={index}
                variant={`action ${
                  (selectedCheckin?.grades[0]?.grade === num ||
                    mood?.grade === num) &&
                  "checked"
                }`}
                handleClick={() => handleMoodClick(num)}
                disabled={isEditDisabled}
              >
                {num}
              </Button>
            ))}
            <span>Amazing!</span>
          </div>
        </div>
        <div className="priorities">
          <h3>Priorities</h3>
          <div className="description">
            <p>Mark priorities from your past Check-ins as complete</p>
            <Button
              variant="control"
              buttonStyles={{
                textDecoration: "underline",
                color: "#6D28D9",
              }}
              handleClick={handleFinishedPrioritiesClick}
            >
              {viewPriorityButton}
            </Button>
          </div>
          <div className="priorities-list">
            {prioritiesState
              .filter((priority) => priority?.status === showFinishedPriorities)
              .map((priority, index) => (
                <div className="priority-item" key={index}>
                  <div className="item">
                    {priority?.id === priorityEditActive ? (
                      <div className="edit-input">
                        <Input
                          value={editedPriority || ""}
                          handleChange={(e) =>
                            setEditedPriority(e.target.value)
                          }
                        />
                        <div className="action-buttons">
                          <Button
                            variant="control"
                            buttonStyles={{
                              textDecoration: "underline",
                              color: "#6D28D9",
                            }}
                            handleClick={() =>
                              handleActionButtonClick("save", priority?.id)
                            }
                          >
                            Save
                          </Button>
                          <Button
                            variant="control"
                            buttonStyles={{
                              textDecoration: "underline",
                              color: "#6D28D9",
                            }}
                            handleClick={() =>
                              handleActionButtonClick("cancel", priority?.id)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <Checkbox
                        labelSide="right"
                        type="checkbox"
                        checked={priority?.status}
                        onChange={(e) =>
                          handleCheckboxChange(priority, e?.target?.checked)
                        }
                      >
                        {priority?.text}
                      </Checkbox>
                    )}
                  </div>
                  <div className="action-buttons">
                    <Button
                      variant="action"
                      handleClick={() =>
                        handleActionButtonClick(
                          "edit",
                          priority?.id,
                          priority?.text
                        )
                      }
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      variant="action"
                      handleClick={() =>
                        handleActionButtonClick("delete", priority?.id)
                      }
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                </div>
              ))}
          </div>
          <div className="new-priority">
            <Input
              label="What do you intend to accomplish between now and your next Check-in?"
              placeholder="Add new priority"
              value={newPriority || ""}
              handleChange={(e) => handleAddPriorityChange(e)}
            />
            <div className="add-button">
              <Button
                buttonStyles={{
                  padding: "10px",
                  height: "48px",
                  gap: "6px",
                }}
                handleClick={() => handleActionButtonClick("add")}
              >
                <AddIcon /> Add
              </Button>
            </div>
          </div>
        </div>
        <div className="questions">
          <h3>Questions</h3>
          {!!questions
            ? questions.map((question, index) => (
                <Input
                  key={index}
                  disabled={isEditDisabled}
                  label={question?.question_text}
                  placeholder="Add new answer"
                  value={
                    answers.find((answer) => answer?.question === question?.id)
                      ?.answer || question?.answer_text
                  }
                  handleChange={(e) =>
                    handleAnswerChange(question?.id, e?.target?.value)
                  }
                />
              ))
            : "No current questions"}
        </div>
        <Button handleClick={handleSubmit} disabled={isEditDisabled}>
          Submit
        </Button>
      </div>
      <div className="check-in-history">
        <div className="title">
          <h3>My recent check ins</h3>
          <Button
            variant="control"
            buttonStyles={{
              textDecoration: "underline",
              color: "#6D28D9",
            }}
            handleClick={handleViewHistoryClick}
          >
            {viewHistoryButton?.text}
          </Button>
        </div>
        <div className="history">
          <div
            className="history-item"
            onClick={hadnleHistoryCurrent}
            hidden={isCurrentCheckinHidden}
          >
            <h3>{today}</h3>
            <span>Current</span>
          </div>
          {checkinsHistoryState
            .slice(
              0,
              viewHistoryButton?.limit
                ? viewHistoryButton?.limit
                : checkinsHistoryState.length
            )
            .map((checkin, index) => (
              <div
                key={index}
                className="history-item"
                onClick={() => handleHistoryClick(index)}
              >
                <h3>{checkin.date}</h3>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CheckIns;
