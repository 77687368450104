import React, { useState, useMemo, useEffect } from "react";
import "./DropdownSelect.scss";
import { OutsideClick } from "../ui";

const DropdownSelect = ({
  type,
  options = [],
  handleSelect,
  preselected = [],
  background = "light",
  name = "",
  placeholder = "Choose",
  isMultiselect = false,
  idKey = "value",
  labelKey = "label",
  isDisabled = false,
  selectListPosition = "bottom",
  customStyle = { width: "100%", maxWidth: "460px" },
  resetFlag,
}) => {
  const [show, setShow] = useState(false);
  const [initialPreselected] = useState(preselected);
  const [selected, setSelected] = useState(preselected);

  useEffect(() => {
    if (JSON.stringify(preselected) !== JSON.stringify(initialPreselected)) {
      setSelected(preselected);
    }
  }, [preselected, initialPreselected]);

  useEffect(() => {
    setSelected([]);
  }, [resetFlag]);

  const handleShow = () => {
    if (isDisabled) {
      return;
    }
    setShow(!show);
  };

  const handleClick = (item) => {
    if (isDisabled) {
      return;
    }
    if (isMultiselect) {
      let updatedSelected = [...selected];
      if (selected.some((val) => val[idKey] === item[idKey])) {
        updatedSelected = selected.filter(
          (selectedItem) => selectedItem[idKey] !== item[idKey]
        );
      } else {
        updatedSelected.push(item);
      }

      if (name) {
        handleSelect({
          name,
          value: updatedSelected.map((item) => item[idKey]),
        });
      } else {
        handleSelect(updatedSelected.map((item) => item[idKey]));
      }
      setSelected(updatedSelected);
    } else {
      let updatedSelected = selected;
      updatedSelected = item;

      setSelected(updatedSelected);
      if (name) {
        handleSelect({
          name,
          value: updatedSelected[idKey],
        });
      } else {
        handleSelect(updatedSelected[idKey]);
      }
      setShow(false);
    }
  };

  const memoizedLabel = useMemo(() => {
    if (!isMultiselect && selected && options) {
      const selectedItem = options?.find(
        (option) => option[idKey] === selected[idKey]
      );
      return selectedItem ? selectedItem[labelKey] : placeholder;
    }

    if (isMultiselect && selected.length > 0) {
      return selected.map((item) => {
        const selectedItem = options?.find(
          (option) => option[idKey] === item[idKey]
        );
        return selectedItem ? selectedItem[labelKey] : null;
      });
    }

    return placeholder;
  }, [selected, options, idKey, labelKey, isMultiselect, placeholder]);

  return (
    <OutsideClick
      onOutsideClick={() => setShow(false)}
      customStyle={customStyle}
    >
      <div
        className={`dropdown ${type} ${background} ${
          isDisabled && "disabled"
        } ${selectListPosition === "top" && "top-position"}`}
      >
        <div className="dropdown-selected" onClick={handleShow}>
          {isMultiselect &&
          memoizedLabel.length > 0 &&
          Array.isArray(memoizedLabel) ? (
            memoizedLabel.map((item, index) => <span key={index}>{item}</span>)
          ) : (
            <span>{memoizedLabel}</span>
          )}
          {!memoizedLabel && !memoizedLabel.length && placeholder}
        </div>
        {show && !!options.length && (
          <ul className="dropdown-list">
            {isMultiselect
              ? options.map((option, index) => (
                  <li
                    className={`${
                      selected.some((item) => item[idKey] === option[idKey])
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleClick(option)}
                    key={index}
                  >
                    {option[labelKey]}
                  </li>
                ))
              : options.map((option, index) => (
                  <li
                    className={`${
                      selected[idKey] === option[idKey] ? "active" : ""
                    }`}
                    onClick={() => handleClick(option)}
                    key={index}
                  >
                    {option[labelKey]}
                  </li>
                ))}
          </ul>
        )}
        {show && !options.length && (
          <ul className="dropdown-list">
            <li>No options</li>
          </ul>
        )}
      </div>
    </OutsideClick>
  );
};

export default DropdownSelect;
