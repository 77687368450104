import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchProjects = createAsyncThunk(
  "projects/fetch",
  async ({
    page = 1,
    searchTerm = "",
    status = null,
    sort = "asc",
    userId = null,
    followedBy = null,
    startDate,
    endDate,
  }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects`, {
        params: {
          page,
          searchTerm,
          status,
          sort,
          user_id: userId,
          followed_by: followedBy,
          from: startDate,
          to: endDate,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleProject = createAsyncThunk(
  "projects/fetch/single",
  async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createProject = createAsyncThunk(
  "projects/create",
  async (payload) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/projects`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateProject = createAsyncThunk(
  "projects/update",
  async (payload) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/projects/${payload.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteProject = createAsyncThunk("projects/delete", async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/projects/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const followProject = createAsyncThunk(
  "projects/follow",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/projects/follow/${payload.id}`,
        { user_id: payload.userId }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const unfollowProject = createAsyncThunk(
  "projects/unfollow",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/projects/unfollow/${payload.id}`,
        { data: { user_id: payload.userId } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
