const EditImageIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_695)">
      <path
        d="M7.33301 2.66699H2.66634C2.31272 2.66699 1.97358 2.80747 1.72353 3.05752C1.47348 3.30756 1.33301 3.6467 1.33301 4.00033V13.3337C1.33301 13.6873 1.47348 14.0264 1.72353 14.2765C1.97358 14.5265 2.31272 14.667 2.66634 14.667H11.9997C12.3533 14.667 12.6924 14.5265 12.9425 14.2765C13.1925 14.0264 13.333 13.6873 13.333 13.3337V8.66699"
        stroke="#6A6565"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.333 1.66617C12.5982 1.40095 12.9579 1.25195 13.333 1.25195C13.7081 1.25195 14.0678 1.40095 14.333 1.66617C14.5982 1.93138 14.7472 2.29109 14.7472 2.66617C14.7472 3.04124 14.5982 3.40095 14.333 3.66617L7.99967 9.9995L5.33301 10.6662L5.99967 7.9995L12.333 1.66617Z"
        stroke="#6A6565"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_695">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EditImageIcon;
