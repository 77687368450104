import { createSlice } from "@reduxjs/toolkit";
import {
  fetchEmployees,
  createEmployee,
  deleteEmployee,
  updateEmployee,
} from "./employeesThunks";

const employeesSlice = createSlice({
  name: "employees",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetEmployeesMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(createEmployee.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Employee successfully created",
      };
    });
    builder.addCase(deleteEmployee.fulfilled, (state, action) => {
      const filteredEmployees = state.data.filter(
        (employee) => employee.id !== action.meta.arg
      );
      state.data = filteredEmployees;
      state.message = {
        type: "success",
        title: "Success",
        message: "Employee successfully deleted",
      };
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      const updatedState = state.data.map((employee) => {
        return employee.id === action.payload.id ? action.payload : employee;
      });
      state.data = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Employee successfully updated",
      };
    });
  },
});

export const { resetEmployeesMessage } = employeesSlice.actions;

export const employeesReducer = employeesSlice.reducer;
