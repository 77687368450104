import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSupportCases,
  createSupportCase,
  updateSupportCase,
  deleteSupportCase,
} from "./supportCasesThunks";

const supportCasesSlice = createSlice({
  name: "supportCases",
  initialState: {
    supportCases: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchSupportCases.fulfilled, (state, action) => {
      state.supportCases = action.payload;
    });
    builder.addCase(createSupportCase.fulfilled, (state, action) => {
      state.supportCases.caseData = [
        ...state.supportCases.caseData,
        action.payload.data,
      ];
    });
    builder.addCase(updateSupportCase.fulfilled, (state, action) => {
      const updatedState = state?.supportCases?.caseData?.map((supportCase) => {
        return supportCase.id === action.payload.data.id
          ? action.payload.data
          : supportCase;
      });
      state.supportCases.caseData = updatedState;
    });
    builder.addCase(deleteSupportCase.fulfilled, (state, action) => {
      const filteredSupportCases = state?.supportCases?.caseData?.filter(
        (supportCase) => supportCase.id !== action.meta.arg
      );
      state.supportCases.caseData = filteredSupportCases;
    });
  },
});

export const supportCasesReducer = supportCasesSlice.reducer;
