const CareerIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.91699 16.4998C1.58366 16.4998 1.29199 16.3748 1.04199 16.1248C0.791992 15.8748 0.666992 15.5832 0.666992 15.2498V5.24984C0.666992 4.9165 0.791992 4.62484 1.04199 4.37484C1.29199 4.12484 1.58366 3.99984 1.91699 3.99984H5.66699V1.9165C5.66699 1.58317 5.79199 1.2915 6.04199 1.0415C6.29199 0.791504 6.58366 0.666504 6.91699 0.666504H11.0837C11.417 0.666504 11.7087 0.791504 11.9587 1.0415C12.2087 1.2915 12.3337 1.58317 12.3337 1.9165V3.99984H16.0837C16.417 3.99984 16.7087 4.12484 16.9587 4.37484C17.2087 4.62484 17.3337 4.9165 17.3337 5.24984V15.2498C17.3337 15.5832 17.2087 15.8748 16.9587 16.1248C16.7087 16.3748 16.417 16.4998 16.0837 16.4998H1.91699ZM6.91699 3.99984H11.0837V1.9165H6.91699V3.99984Z"
      fill="white"
    />
  </svg>
);
export default CareerIcon;
