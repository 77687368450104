import { useState } from "react";
import { Form, Input, Modal } from "../../../ui";
import { QuestionIcon } from "../../../../icons";
import "./styles.scss";

import { createAnswerFeedback } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

const AnswerFeedbackForm = ({
  showAnswerFeedbackModal,
  handleModalClose,
  selectedFeedback,
}) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const [answer, setAnswer] = useState("");

  const [doCreateAnswerFeedback] = useThunk(createAnswerFeedback);

  const handleChange = (event) => {
    console.log(event);
    setAnswer(event.target.value);
    return;
  };

  const handeAnswerFeedback = () => {
    const payload = {
      data: {
        answer,
      },
      feedbackId: selectedFeedback?.id,
    };
    doCreateAnswerFeedback(payload);
    handleModalClose();
  };

  return (
    <Modal
      show={showAnswerFeedbackModal}
      handleClose={handleModalClose}
      handleConfirm={handeAnswerFeedback}
      title={"Give Feedback"}
      confirmButtonLabel="Save Changes"
      rejectButtonLabel="Close"
    >
      <Form>
        <div className="form-group">
          <Input
            type="input"
            label={selectedFeedback?.question}
            placeholder="Type your answer..."
            name="answer"
            value={answer || ""}
            handleChange={handleChange}
            disabled={selectedFeedback?.receiver?.id === localUserData?.id}
          />
        </div>

        <div className="feedback-note">
          <div>
            <QuestionIcon />
          </div>
          <p>
            {`This feedback will be seen by ${selectedFeedback?.sender?.name} and his manager`}
          </p>
        </div>
      </Form>
    </Modal>
  );
};

export default AnswerFeedbackForm;
