import { Routes, Route } from "react-router-dom";
import OngoingSurvey from "../components/Survey/modules/ongoingSurvey/OngoingSurvey";
import Completed from "../components/Survey/modules/completed/Completed";
import Drafts from "../components/Survey/modules/drafts/Drafts";
import Archived from "../components/Survey/modules/archived/Archived";
import Survey from "../components/Survey/Survey";
import NewSurvey from "../components/Survey/newSurvey/NewSurvey";
import SurveyCreator from "../components/Survey/newSurvey/SurveyCreator";
import Overview from "../components/Survey/Overview/Overview";
import NonRespondents from "../components/Survey/NonRespondents/NonRespondents";
import ClientSurvey from "../components/Survey/ClientSurvey/ClientSurvey";

const SurveyRoutes = () => (
  <Routes>
    <Route path="/" element={<Survey />}>
      <Route path="ongoing" element={<OngoingSurvey />} />
      <Route path="completed" element={<Completed />} />
      <Route path="drafts" element={<Drafts />} />
      <Route path="archived" element={<Archived />} />
      <Route path="new-survey" element={<NewSurvey />} />
      <Route path="questions" element={<SurveyCreator />} />
      <Route path="overview" element={<Overview />} />
      <Route path="non-respondents" element={<NonRespondents />} />
      {/* <Route path="user-surveys" element={<ClientSurvey />} /> */}
    </Route>
  </Routes>
);

export default SurveyRoutes;
