import React, { useState, useEffect } from "react";

import { fetchSinglePosition, resetCareerGrowth } from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { Modal } from "../../ui";
import MiniMatrixNode from "./MiniMatrixNode";

import ReactFlow, { useNodesState, useEdgesState, Background } from "reactflow";
import { useNavigate } from "react-router-dom";

const nodeTypes = { miniMatrixNode: MiniMatrixNode };
const nodeOrigin = [0.5, 0.5];

const MiniMatrix = ({ user, matrix }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState(
    matrix?.career_path?.matrix?.edges || []
  );

  const [doFetchSinglePosition] = useThunk(fetchSinglePosition);
  const [doResetCareerGrowth] = useThunk(resetCareerGrowth);

  useEffect(() => {
    const updatedNodes = matrix?.career_path?.matrix?.nodes.map((node) => ({
      ...node,
      type: "miniMatrixNode",
    }));
    setNodes(updatedNodes);
  }, []);

  const singlePositionState = useSelector(
    (state) => state.positions
  )?.singlePosition;

  const isHigherPosition = () => {
    const userPosition = matrix.career_path.matrix.find(
      (item) => item.id === user?.position_id
    );
    return selectedPosition.positionY < userPosition.positionY;
  };

  const handleShowPosition = (id) => {
    console.log("id", id);
    doFetchSinglePosition(id);
    navigate("/your-career/position-details");
  };

  const isActivePosition = (blockId) => {
    if (!matrix?.active_position) {
      return false;
    }
    if (!!matrix?.active_position && blockId === matrix?.active_position) {
      return "active";
    }
    return "disabled";
  };

  const handleModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleResetCareerPath = () => {
    doResetCareerGrowth(user?.id);
    handleModalClose();
  };

  return (
    <div className="mini-matrix-container">
      <div className="mini-matrix-wrapper">
        <div style={{ width: "100%", height: "400px" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            nodeOrigin={nodeOrigin}
            fitView
          >
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
        </div>
      </div>

      <Modal
        show={showConfirmModal}
        handleClose={handleModalClose}
        title="Confirm reset"
        confirmButtonLabel="Reset"
        handleConfirm={handleResetCareerPath}
      >
        <label>
          Are you sure? Your business tasks and goals will be removed.
        </label>
      </Modal>
    </div>
  );
};

export default MiniMatrix;
