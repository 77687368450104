const SendIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_430)">
      <path
        d="M17.1878 0.253916L0.937755 9.62501C-0.405995 10.4024 -0.277089 12.3906 1.16041 12.9805L5.62526 14.8281V18.125C5.62526 19.9375 7.93776 20.6836 9.00807 19.2422L10.719 16.9336L15.0901 18.7383C15.3206 18.832 15.5628 18.8789 15.8049 18.8789C16.1253 18.8789 16.4417 18.7969 16.7268 18.6367C17.2268 18.3555 17.5706 17.8555 17.6604 17.2891L19.9807 2.16407C20.219 0.597666 18.5393 -0.523428 17.1878 0.253916ZM7.50026 18.125V15.6016L8.92994 16.1914L7.50026 18.125ZM15.8049 17.0039L9.79713 14.5234L15.2737 6.6211C15.6917 6.01563 14.9026 5.31251 14.3479 5.79298L6.08619 12.9922L1.87525 11.25L18.1253 1.87501L15.8049 17.0039Z"
        fill="#6A6565"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_430">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SendIcon;
