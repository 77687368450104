import { useState, useEffect } from "react";

import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import { Button, OutsideClick } from "../../../ui";
import "./organisation.scss";

const BlockSelect = ({
  positionsState,
  selectPosition,
  selectedBlock,
  selectConnection,
  handleRemoveBlock,
  positionY,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const handleSelectPosition = (positionId) => {
    const currentPosition = positionsState?.find(
      (position) => position.id === positionId
    );
    selectPosition(currentPosition);
  };

  const handleSelectConnection = (positionsId) => {
    selectConnection(positionsId);
  };

  const availablePositions = () => {
    return positionsState;
  };

  const handleRemove = (selectedBlock) => {
    handleRemoveBlock(selectedBlock.id);
  };

  const handleTogglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <OutsideClick onOutsideClick={handleClosePopup}>
      <Button handleClick={handleTogglePopup}>Add Position</Button>
      {showPopup && (
        <div className="career-form-wrapper">
          <form>
            <div className="carrer-form-group">
              <label>Position</label>
              <DropdownSelect
                options={availablePositions()}
                handleSelect={handleSelectPosition}
                type="primary"
                placeholder="Choose position"
                idKey="id"
                labelKey="title"
                preselected={!!selectedBlock ? selectedBlock : []}
                selectListPosition={positionY > 500 ? "top" : "bottom"}
              />
            </div>
            {/* {!!positionsCollection.length && (
              <div className="carrer-form-group">
                {positionsCollection.length > 1 && (
                  <>
                    <label>Connect to</label>
                    <DropdownSelect
                      options={
                        selectedBlock
                          ? positionsCollection.filter(
                              (position) => position.id !== selectedBlock.id
                            )
                          : positionsCollection
                      }
                      handleSelect={handleSelectConnection}
                      type="primary"
                      placeholder="Choose position"
                      idKey="id"
                      labelKey="title"
                      isMultiselect={true}
                      selectListPosition={positionY > 470 ? "top" : "bottom"}
                    />
                  </>
                )}

                <div className="carrer-form-footer">
                  {!!selectedBlock && (
                    <>
                      <Button
                        buttonStyles={{
                          padding: "10px 20px",
                          marginTop: "20px",
                        }}
                        handleClick={handleClosePopup}
                      >
                        OK
                      </Button>
                      <Button
                        buttonStyles={{
                          padding: "10px 20px",
                          marginTop: "20px",
                        }}
                        handleClick={() => handleRemove(selectedBlock)}
                      >
                        Remove
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )} */}
          </form>
        </div>
      )}
    </OutsideClick>
  );
};

export default BlockSelect;
