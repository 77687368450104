const ThumbsUpOrDown = ({ size = "22", variant = "up", fill = "#263E4E" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(variant === "up" ? { style: { transform: "rotate(180deg)" } } : {})}
    >
      <path
        d="M7.62313 21.0919C8.19562 21.0316 8.73798 20.6399 9.0393 20.0674C9.431 19.284 12.3236 13.4687 12.8961 12.8962C13.4686 12.3237 14.2219 12.0525 15.0655 12.0525L15.0655 -8.15623e-06L4.51961 -9.07818e-06C3.88685 -9.1335e-06 3.34449 0.391697 3.10344 0.934059C3.10344 0.934059 -7.91122e-05 9.70225 -7.9186e-05 10.5459C-7.92597e-05 11.3896 0.662808 12.0525 1.50648 12.0525L6.02617 12.0525C6.86985 12.0525 7.53273 12.8058 7.53273 13.5591C7.53273 14.3123 6.35762 18.3198 6.11657 19.1032C5.87552 19.8866 6.26722 20.7303 7.05063 21.0015C7.26155 21.0617 7.41221 21.122 7.62313 21.0919ZM21.0918 12.0525L21.0918 -7.62939e-06L18.0787 -7.89281e-06L18.0787 12.0525L21.0918 12.0525Z"
        fill={fill}
      />
    </svg>
  );
};

export default ThumbsUpOrDown;
