import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUsersGoals,
  createGoal,
  updateGoal,
  fetchGoals,
  createGoalSettings,
  updateGoalSettings,
} from "./goalsThunks";

const goalsSlice = createSlice({
  name: "goals",
  initialState: {
    allGoals: [],
    usersGoals: [],
    selectedGoal: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    setSelectedGoal: (state, action) => {
      state.selectedGoal = action.payload;
    },
    resetGoalsMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchGoals.fulfilled, (state, action) => {
      state.allGoals = action.payload;
    });
    builder.addCase(fetchUsersGoals.fulfilled, (state, action) => {
      state.usersGoals = action.payload;
    });
    builder.addCase(createGoal.fulfilled, (state, action) => {
      state.usersGoals = [...state.usersGoals, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Goal successfully created",
      };
    });
    builder.addCase(updateGoal.fulfilled, (state, action) => {
      const updatedState = state.usersGoals.map((goal) => {
        return goal.id === action.payload.id ? action.payload : goal;
      });
      state.usersGoals = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Goal successfully updated",
      };
    });
    builder.addCase(createGoalSettings.fulfilled, (state, action) => {
      const updatedGoalsState = state.usersGoals.map((goal) => {
        return goal.id === action.payload.goalId
          ? { ...goal, settings: action.payload }
          : goal;
      });
      state.usersGoals = updatedGoalsState;
    });
    builder.addCase(updateGoalSettings.fulfilled, (state, action) => {
      const updatedGoalsState = state.usersGoals.map((goal) => {
        return goal.id === action.payload.goalId
          ? { ...goal, settings: action.payload }
          : goal;
      });
      state.usersGoals = updatedGoalsState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Goal successfully updated",
      };
    });
  },
});

export const { setSelectedGoal, resetGoalsMessage } = goalsSlice.actions;

export const goalsReducer = goalsSlice.reducer;
