import { useEffect, useRef } from "react";

function OutsideClick({ children, onOutsideClick, customStyle }) {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div ref={ref} className="outside-click" style={customStyle}>
      {children}
    </div>
  );
}

export default OutsideClick;
