import { Modal } from "../../../ui";
import { useSelector } from "react-redux";

const UserChatStatsModal = ({ showUser, handleCloseUser, handleClose }) => {
  const formType = useSelector((state) => state.form)?.type;
  const formData = useSelector((state) => state.form)?.data;

  return (
    <Modal
      show={showUser}
      handleClose={handleClose}
      hasFooter={false}
      className="modal-user"
      title="Chat stats"
    >
      <div className="col-md-12 border-1">User: {formData?.user}</div>
      <div className="col-md-12">
        {formType === "view-chat-stats" ? (
          <div className="col-md-12">
            Most messages with:
            {formData.trends &&
              Object.entries(formData.trends).map(([name, value], index) => (
                <div className="col-md-12 bolder" key={index}>
                  {name}: {value}
                </div>
              ))}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="col-md-12">User average online: {"100%"}</div>
    </Modal>
  );
};

export default UserChatStatsModal;
