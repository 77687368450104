import { useState, useEffect } from "react";
import "./styles.scss";

const Offcanvas = ({
  children,
  className,
  position,
  show,
  scroll,
  backdrop,
  onHide,
}) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  const scrollClass = scroll ? "_offcanvas-scroll" : "";
  const backdropClass = backdrop ? "_offcanvas-backdrop" : "";
  const onRenderAnimation = shouldRender
    ? position === "start"
      ? "render-left"
      : "render-right"
    : "";

  return (
    shouldRender && (
      <div className={`${show ? backdropClass : ""}`} onClick={onHide}>
        <div
          className={`_offcanvas ${position} ${className} ${scrollClass} ${
            show ? "show" : ""
          } ${
            shouldRender
              ? position === "start"
                ? "render-left"
                : "render-right"
              : ""
          } ${onRenderAnimation}`}
          onAnimationEnd={onAnimationEnd}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default Offcanvas;
