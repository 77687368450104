//TODO: Update component actions onClick

import { useState } from "react";
import { DotsIcon } from "../../../icons";
import { OutsideClick } from "../../ui";
import "./styles.scss";

const PopupActions = ({ actions, popupPosition = "left" }) => {
  const [showPopup, setShowPopup] = useState(false);
  const toggleShow = () => {
    setShowPopup(!showPopup);
  };
  return (
    <OutsideClick onOutsideClick={() => setShowPopup(false)}>
      <div className="popup-wrapper">
        <button className="popup-button" onClick={toggleShow}>
          <DotsIcon />
        </button>
        {showPopup ? (
          <ul className="popup-list-wrapper">
            {actions.map((action, index) => (
              <li key={`popup-${action.id}`} onClick={action?.action}>
                {action.label}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
    </OutsideClick>
  );
};

export default PopupActions;
