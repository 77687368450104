import React, { useState, useEffect, useCallback } from "react";
import "./UsersSidebar.scss";
import db, { fetchToken } from "../../../firebase";
import Select from "react-select";
import { actionTypes } from "../../../reducer";
import { useNavigate, useLocation } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";
import { PlusIcon } from "../../../icons";
import SingleChatItem from "./SingleChatItem";
import GroupChatItem from "./GroupChatItem";
import { Modal, Button, Input, Form } from "../../ui";

function UsersSidebar({ handleActiveUser, activeUser, handleGroupId }) {
  const [state, dispatch] = useStateValue();
  const [allChannels, setAllChannels] = useState(null);
  const [visibleChannels, setVisibleChannels] = useState(null);
  const [visiblePrivateChannels, setVisiblePrivateChannels] = useState(null);
  const [channels, setChannels] = useState([]);
  const [privateChannels, setPrivateChannels] = useState([]);
  const [showChannels, setShowChannels] = useState(true);
  const [showGroups, setShowGroups] = useState(true);
  const [showDirect, setShowDirect] = useState(true);
  const [showStarred, setShowStarred] = useState(true);

  const [newChat, setNewChat] = useState({});
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const [unreadGroupMessages, setUnreadGroupMessages] = useState([]);
  const [unreadPrivateMessages, setUnreadPrivateMessages] = useState([]);
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [modalType, setModalType] = useState("channel");
  const [activeChat, setActiveChat] = useState(null);
  const [showSearchUsers, setShowSearchUsers] = useState(false);
  const [showSearchChannels, setShowSearchChannels] = useState(false);
  const [showUpdateStatusForm, setShowUpdateStatusForm] = useState(false);
  const [userOnline, setUserOnline] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const handleCloseChat = () => setShowChat(false);
  const [showChatActions, setShowChatActions] = useState(false);
  const [chatActionId, setChatActionId] = useState(null);
  const [selectedUserActions, setSelectedUserActions] = useState(null);
  const [starredChats, setStarredChats] = useState([]);
  const [starredGroups, setStarredGroups] = useState([]);
  const location = useLocation();
  const handleClick = (user, roomid) => {
    handleActiveUser(user);
    handleGroupId(user.id);
    // navigate(`/chat/${roomid}`);
  };
  const handleChangeChat = (event, selectEvent) => {
    if (event.target) {
      const { name, value, checked, type } = event.target;
      const newVal = type === "checkbox" ? checked : value;

      setNewChat({ ...newChat, [name]: newVal });
      return;
    }

    const name = selectEvent.name;
    const value = event.value;

    if (name === "type") {
      if (value === 2) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
    setNewChat({ ...newChat, [name]: event.value });
  };

  const getUserChats = fetch(
    `${process.env.REACT_APP_BASE_API_URL}/chat/` + localUserData?.id
  ).then(function (response) {
    return response.json();
  });
  const [{ user }] = useStateValue();
  const [selectedGroupUsers, setSelectedGroupUsers] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const chatTypes = [
    { value: 1, label: "Group", isFixed: true },
    { value: 2, label: "Channel", isFixed: true },
  ];
  const chatPublic = [
    { value: 2, label: "No", isFixed: true },
    { value: 1, label: "Yes", isFixed: true },
  ];
  const getUsers = fetch(
    `${process.env.REACT_APP_BASE_API_URL}/users?page=1`
  ).then(function (response) {
    return response.json();
  });
  const getUnread = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/chat/unread/` + user?.id
      );
      const chats = await res.json();
      setUnreadGroupMessages(
        chats.groups.map((res) => ({
          id: res.id,
          uuid: res.uuid,
          name: res.name,
          type: res.type,
          public: res.public,
        }))
      );
      setUnreadPrivateMessages(
        chats.private.map((private_chat) => ({
          id: private_chat.id,
          name: private_chat.name,
          uuid: private_chat.uuid,
          uid: private_chat.uuid.split("/")[0].replace(user.uid, ""),
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const intervalCall = setInterval(() => {
      getUnread();
    }, 10000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  const getAllChats = () => {
    let totalUsers = getUsers.then(function (jsonData) {
      return jsonData;
    });
    totalUsers.then(function (result) {
      setUsersOptions(
        result.optionData.map((res) => ({
          value: res.id,
          label: res.first_name + " " + res.last_name,
          isFixed: true,
        }))
      );
      let allUs = [];
      for (let i = 0; i < result.optionData.length; ++i) {
        if (
          result.optionData[i].uid !== user.uid &&
          result.optionData[i].uid !== ""
        ) {
          allUs[result.optionData[i].uid] = [];
          allUs[result.optionData[i].uid]["status"] = [];
          allUs[result.optionData[i].uid]["online"] = [];
          allUs[result.optionData[i].uid]["status"] =
            result.optionData[i].status;
          allUs[result.optionData[i].uid]["online"] =
            result.optionData[i].online;
        }
      }
      setAllUsers(allUs);

      //console.log(user);
    });
    let allChats = [];
    let visibleChats = [];
    let visiblePrivateChats = [];
    getUserChats.then((data) => {
      setAllChannels(
        data.optionData.map((res) => ({
          value: res.uuid,
          label: res.name,
          bid: res.id,
          isFixed: true,
        }))
      );
      data.optionData.forEach((chat) => {
        allChats.push(chat);
      });

      data.visible?.forEach((visible_chat) => {
        visibleChats.push(visible_chat);
      });
      if (data.visible_private !== null) {
        setVisiblePrivateChannels(
          data.visible_private.map((private_chat) => ({
            id: private_chat.id,
            name: private_chat.name,
            uuid: private_chat.uuid,
            uid: private_chat.uuid.split("/")[0].replace(user.uid, ""),
            star: private_chat.star,
            image: private_chat.image,
            emoji: private_chat.custom_emoji,
          }))
        );
      }
      // data.visible_private.forEach((private_chat) => {
      //   visiblePrivateChats.push(private_chat);
      //   setVisiblePrivateChats(private_chat);
      // });
    });
    setVisibleChannels(visibleChats);

    db.collection("rooms")
      .where("private", "==", false)
      .onSnapshot((snapshot) => {
        setChannels(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            private: doc.data().private,
          }))
        );
      });
    db.collection("users")
      .where("idd", "!=", user.uid)
      .onSnapshot((snapshot) => {
        setPrivateChannels(
          snapshot.docs.map((doc) => ({
            label: doc.data().name,
            isFixed: true,
            value:
              user.uid.charAt(0) > doc.data().idd.charAt(0)
                ? `${user.uid + doc.data().idd}`
                : `${doc.data().idd + user.uid}`,
          }))
        );
      });
    let currentUrl = window.location.href.split("/");
    let lastPart = currentUrl[currentUrl.length - 1];
    if (currentUrl[currentUrl.length - 2] === "room") {
      lastPart = currentUrl[currentUrl.length - 1].replace("%20", "");
    }
    setActiveChat(lastPart);
  };
  useEffect(() => {
    getAllChats();
  }, []);

  //console.log(allChannels);
  const handleShowChat = (type) => {
    setShowChat(true);
    setModalType(type);
  };
  const handleSearchUsers = (event) => {
    setShowSearchUsers(true);
  };
  const handleCloseSearchUsers = (event) => {
    setShowSearchUsers(false);
  };
  const handleSearchChannels = (event) => {
    setShowSearchChannels(true);
  };
  const handleCloseSearchChannels = (event) => {
    setShowSearchChannels(false);
  };
  const addChannel = (event, newChat) => {
    //console.log(newChat);
    //console.log(selectedGroupUsers);
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let channelId = " ";
    const charactersLength = 20;
    for (let i = 0; i < charactersLength; i++) {
      channelId += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    let channelName = newChat.name;
    let fullChannel = {};
    fullChannel.name = newChat.name;
    fullChannel.uuid = channelId;
    fullChannel.type = newChat.type;
    if (newChat.type == 1) {
      fullChannel.public = 1;
    } else {
      fullChannel.public = parseInt(newChat.public);
    }
    const formData = new FormData();
    formData.append("name", newChat.name);
    formData.append("uuid", channelId);
    formData.append("type", modalType === "channel" ? 2 : 1);
    // if (newChat.type == 1) {
    //   formData.append("public", 1);
    // } else {
    //   formData.append("public", parseInt(newChat.public));
    // }
    formData.append(
      "public",
      modalType === "group" ? 1 : parseInt(newChat.public)
    );

    let error = null;
    let users = selectedGroupUsers;
    let currentUser = new Object();
    currentUser.value = localUserData.id;
    currentUser.label =
      localUserData.first_name + " " + localUserData.last_name;
    currentUser.isFixed = true;
    users.unshift(currentUser);

    formData.append("users", JSON.stringify(users));
    //console.log(formData);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(`${process.env.REACT_APP_BASE_API_URL}/chat/`, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        // check for error response
        if (response.status == 409) {
          // get error message from body or default to response status
          setNewChat({});
          setSelectedGroupUsers(null);
          return Promise.reject(data);
        } else {
          db.collection("rooms").add({
            name: channelName,
            id: channelId,
            timestamp: Date.now(),
            private: false,
          });
          setTimeout(() => {
            //console.log("force");
            forceUpdate();
            let allChats = [];
            fetch(`${process.env.REACT_APP_BASE_API_URL}/chat/` + user?.id)
              .then(function (response) {
                return response.json();
              })
              .then(function (jsonData) {
                return jsonData;
              })
              .then(function (result) {
                //console.log("tu sam");
                setAllChannels(
                  result.optionData.map((res) => ({
                    value: res.uuid,
                    label: res.name,
                    bid: res.id,
                    isFixed: true,
                  }))
                );
                setVisibleChannels(result.visible);
                setVisiblePrivateChannels(result.visible_private);
              });
          }, 1000);
          setNewChat({});
          setSelectedGroupUsers(null);
          handleClick(fullChannel, channelId);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    handleCloseChat();
    setTimeout(() => {
      //console.log("force");
      forceUpdate();
    }, 1000);
  };
  const selectUser = (event, selectedUser) => {
    const formData = new FormData();
    formData.append("chat_name", selectedUser.label);
    formData.append("chat_uuid", selectedUser.value);
    formData.append("user_id", user?.id);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(`${process.env.REACT_APP_BASE_API_URL}/chat/private`, requestOptions);
    window.location.replace(
      `/chat/${selectedUser.value}/${selectedUser.label}`
    );
    //console.log("force");
  };
  const handleShowUpdateStatus = (event) => {
    setShowUpdateStatusForm(true);
  };
  const handleCloseUpdateStatus = (event) => {
    setShowUpdateStatusForm(false);
  };

  const validate = () => {
    if (userOnline != null && userStatus != null) {
      return 1;
    } else {
      return 0;
    }
  };

  const updateStatus = (event) => {
    setShowUpdateStatusForm(false);
    const formData = new FormData();
    const requestOptions = {
      method: "GET",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/user/` +
        user?.id +
        "/availability/" +
        userOnline.value +
        "/" +
        userStatus.value,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      //console.log(response);
      // check for error response
      if (response.status == 409) {
        // get error message from body or default to response status
        return Promise.reject(data);
      } else {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/users/` + user?.id)
          .then((res) => res.json())
          .then((json) => {
            //console.log(json);
            user.id = json.id;
            user.birthday = json.birthday;
            user.position = json.position;
            user.team = json.team;
            user.phone = json.phone;
            user.image = json.image;
            //console.log(json);
            localStorage.removeItem("authUser");
            localStorage.removeItem("authUserData");
            localStorage.removeItem("last_active_date");
            localStorage.setItem("authUser", JSON.stringify(user));
            localStorage.setItem("authUserData", JSON.stringify(json));
            localStorage.setItem("last_active_date", json.last_login);
            dispatch({
              type: actionTypes.SET_USER,
              user: user,
            });
          });
        //console.log("force");
        forceUpdate();
      }
    });
    setTimeout(() => {
      //console.log("force");
      forceUpdate();
    }, 1000);
  };
  const selectChannel = (event, selectedChannel) => {
    const formData = new FormData();
    formData.append("chat_name", selectedChannel.label);
    formData.append("chat_id", selectedChannel.value);
    formData.append("user_id", user?.id);
    const requestOptions = {
      method: "PUT",
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/chat/` +
        user?.id +
        "/" +
        selectedChannel.bid,
      requestOptions
    ).then((response) => {
      window.location.replace(
        `/chat/${selectedChannel.value}/${selectedChannel.label}/${selectedChannel.bid}`
      );
    });
  };

  const handleToggleChannels = () => {
    setShowChannels(!showChannels);
  };

  const handleToggleGroups = () => {
    setShowGroups(!showGroups);
  };

  const handleToggleDirect = () => {
    setShowDirect(!showDirect);
  };

  const handleToggleStarred = () => {
    setShowStarred(!showStarred);
  };

  const handleOpenChatActions = (e, customer) => {
    e.preventDefault();
    setSelectedUserActions(customer);
    setChatActionId(customer.id);
    setShowChatActions(true);
  };

  useEffect(() => {
    if (!visibleChannels?.length || !visiblePrivateChannels?.length) {
      return;
    }
    const filteredChats = visiblePrivateChannels?.filter(
      (chat) => !!chat?.star
    );
    const filteredGroups = visibleChannels?.filter((chat) => !!chat?.star);
    setStarredChats(filteredChats);
    setStarredGroups(filteredGroups);
  }, [visibleChannels, visiblePrivateChannels]);

  return (
    <>
      <Modal
        show={showSearchUsers}
        handleClose={handleCloseSearchUsers}
        title="Search users"
        confirmButtonLabel="Save Changes"
        handleConfirm={(event) => selectUser(event, selectedUser)}
      >
        <Form>
          <div className="form-group">
            <label>Find user</label>
            <Select
              name="users"
              options={privateChannels}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedUser}
            />
          </div>
        </Form>
      </Modal>
      <Modal
        show={showSearchChannels}
        handleClose={handleCloseSearchUsers}
        title="Search channels"
        confirmButtonLabel="Save Changes"
        handleConfirm={(event) => selectChannel(event, selectedChannel)}
      >
        <Form>
          <div className="form-group">
            <label>Find channel/group</label>
            <Select
              name="users"
              options={allChannels}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedChannel}
            />
          </div>
        </Form>
      </Modal>
      <Modal
        show={showChat}
        handleClose={handleCloseChat}
        title={`Add ${modalType}`}
        confirmButtonLabel="Save Changes"
        handleConfirm={(event) => addChannel(event, newChat)}
      >
        <Form>
          <div className="form-group" controlId="formBasicFirstName">
            <Input
              type="input"
              label="Name"
              placeholder="Enter name"
              name="name"
              value={newChat?.name || ""}
              handleChange={handleChangeChat}
            />
          </div>
          {/* <div className="form-group" controlId="formBasicPhone">
                <label>Type</label>
                <Select
                  name="type"
                  placeholder="Select Type"
                  options={chatTypes}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChangeChat}
                  defaultValue={chatTypes?.find(
                    (item) => item?.value === newChat?.type
                  )}
                />
              </div> */}

          {modalType === "channel" && (
            <div className="form-group" controlId="formBasicPhone">
              <label>Is this a private channel?</label>
              <Select
                name="public"
                placeholder="Select"
                options={chatPublic}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChangeChat}
                defaultValue={chatTypes?.find(
                  (item) => item?.value === newChat?.public
                )}
              />
            </div>
          )}
          <div className="form-group" controlId="formBasicPhone">
            <label>Users in group</label>
            <Select
              defaultValue={selectedGroupUsers}
              isMulti
              name="users"
              options={usersOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedGroupUsers}
            />
          </div>
        </Form>
      </Modal>

      <div className="chat-wrapper chat-users-wrapper grid-item">
        {(!!starredChats?.length || !!starredGroups?.length) && (
          <div className="chat-sidebar-section">
            <div className={`chat-control ${!showDirect ? "closed" : ""}`}>
              <h5 onClick={handleToggleStarred}>Starred messages</h5>
            </div>
            {showStarred && (
              <>
                <div className="chat-users-list">
                  {starredChats?.map((chat, index) => (
                    <div key={index}>
                      <SingleChatItem
                        chat={chat}
                        handleOpenChatActions={handleOpenChatActions}
                        handleClick={handleClick}
                        allUsers={allUsers}
                        showChatActions={showChatActions}
                        chatActionId={chatActionId}
                        selectedUserActions={selectedUserActions}
                        setShowChatActions={setShowChatActions}
                        activeUser={activeUser}
                        getAllChats={getAllChats}
                      />
                    </div>
                  ))}
                </div>

                <div className="chat-channels-list">
                  {visibleChannels?.map(
                    (chat, index) =>
                      !!chat.star && (
                        <div key={index}>
                          <GroupChatItem
                            chat={chat}
                            handleClick={handleClick}
                            handleOpenChatActions={handleOpenChatActions}
                            showChatActions={showChatActions}
                            chatActionId={chatActionId}
                            setShowChatActions={setShowChatActions}
                            selectedUserActions={selectedUserActions}
                            getAllChats={getAllChats}
                          />
                        </div>
                      )
                  )}
                </div>
              </>
            )}
          </div>
        )}
        <div className="chat-sidebar-section">
          <div className={`chat-control ${!showDirect ? "closed" : ""}`}>
            <h5 onClick={handleToggleDirect}>Direct messages</h5>
          </div>
          {showDirect && (
            <div className="chat-users-list">
              {visiblePrivateChannels?.map(
                (chat, index) =>
                  !chat.star && (
                    <div key={index}>
                      <SingleChatItem
                        chat={chat}
                        handleOpenChatActions={handleOpenChatActions}
                        handleClick={handleClick}
                        allUsers={allUsers}
                        showChatActions={showChatActions}
                        chatActionId={chatActionId}
                        selectedUserActions={selectedUserActions}
                        setShowChatActions={setShowChatActions}
                        activeUser={activeUser}
                        getAllChats={getAllChats}
                      />
                    </div>
                  )
              )}
            </div>
          )}
        </div>

        <div className="chat-sidebar-section">
          <div className={`chat-control ${!showChannels ? "closed" : ""}`}>
            <h5 onClick={handleToggleChannels}>Channels</h5>
            <Button
              variant="control"
              handleClick={() => handleShowChat("channel")}
            >
              <PlusIcon />
            </Button>
          </div>
          {showChannels && (
            <div className="chat-channels-list">
              {visibleChannels
                ?.filter((channel) => channel.type === 2)
                .map(
                  (chat, index) =>
                    !chat.star && (
                      <div key={index}>
                        <GroupChatItem
                          chat={chat}
                          handleClick={handleClick}
                          handleOpenChatActions={handleOpenChatActions}
                          showChatActions={showChatActions}
                          chatActionId={chatActionId}
                          setShowChatActions={setShowChatActions}
                          selectedUserActions={selectedUserActions}
                          activeUser={activeUser}
                          getAllChats={getAllChats}
                          groupType="channel"
                        />
                      </div>
                    )
                )}
            </div>
          )}
        </div>

        <div className="chat-sidebar-section">
          <div className={`chat-control ${!showGroups ? "closed" : ""}`}>
            <h5 onClick={handleToggleGroups}>Groups</h5>
            <Button
              variant="control"
              handleClick={() => handleShowChat("group")}
            >
              <PlusIcon />
            </Button>
          </div>
          {showGroups && (
            <div className="chat-channels-list">
              {visibleChannels
                ?.filter((channel) => channel.type === 1)
                .map(
                  (chat, index) =>
                    !chat.star && (
                      <div key={index}>
                        <GroupChatItem
                          chat={chat}
                          handleClick={handleClick}
                          handleOpenChatActions={handleOpenChatActions}
                          showChatActions={showChatActions}
                          chatActionId={chatActionId}
                          setShowChatActions={setShowChatActions}
                          selectedUserActions={selectedUserActions}
                          activeUser={activeUser}
                          getAllChats={getAllChats}
                          groupType="group"
                        />
                      </div>
                    )
                )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UsersSidebar;
