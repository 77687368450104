const FeedbacksAndTasksIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0175 23.2244C13.8765 23.1374 13.7943 23.0218 13.7957 22.8464C13.8024 22.0122 13.7795 21.176 13.8144 20.3422C13.8665 19.1261 14.7093 18.1389 15.8948 17.8456C15.9254 17.8379 15.9569 17.8226 16.0171 17.8006C15.1958 17.0796 14.8278 16.1856 14.9664 15.1167C15.0663 14.345 15.4438 13.71 16.0625 13.2307C17.2767 12.2866 19.0078 12.4485 20.0199 13.5858C21.0386 14.7292 21.0759 16.5874 19.682 17.803C19.747 17.8236 19.8001 17.8408 19.8531 17.8542C21.04 18.1614 21.8901 19.247 21.8963 20.4736C21.901 21.2414 21.892 22.0083 21.901 22.7757C21.903 22.9669 21.8566 23.1164 21.6965 23.2239C19.1378 23.2244 16.5772 23.2244 14.0175 23.2244ZM14.4895 22.5655C16.7329 22.5655 18.9849 22.5655 21.2507 22.5655C21.2507 21.908 21.2526 21.2625 21.2507 20.6184C21.2488 19.3383 20.3773 18.4376 19.101 18.4142C18.6442 18.4056 18.1879 18.4385 17.7306 18.4395C17.3269 18.4409 16.9231 18.4094 16.5203 18.4175C15.4954 18.4395 14.6233 19.1256 14.5082 20.1334C14.4164 20.9294 14.4895 21.7436 14.4895 22.5655ZM17.8453 17.7681C18.865 17.8432 20.0954 16.9711 20.1083 15.53C20.1197 14.2743 19.0905 13.2656 17.8305 13.2733C16.5819 13.2804 15.5857 14.2882 15.5909 15.5439C15.5981 16.9148 16.795 17.8403 17.8453 17.7681Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75147 23.2245C3.59092 23.1175 3.54457 22.9679 3.54696 22.7763C3.55556 22.0232 3.54792 21.2692 3.55078 20.5152C3.55604 19.2232 4.39319 18.1615 5.65225 17.8442C5.68378 17.8361 5.71436 17.8213 5.7674 17.8002C4.99954 17.1256 4.61681 16.2918 4.6966 15.2802C4.75824 14.5038 5.09463 13.8477 5.6857 13.3355C6.87213 12.3053 8.60519 12.3865 9.6994 13.5104C10.7415 14.5802 10.8968 16.5254 9.44759 17.7969C9.47817 17.8141 9.50302 17.8342 9.53169 17.8423C10.8347 18.1892 11.6274 19.1926 11.647 20.5425C11.658 21.2965 11.6461 22.051 11.6518 22.805C11.6527 22.9923 11.5916 23.1299 11.4306 23.2245C8.87134 23.2245 6.31117 23.2245 3.75147 23.2245ZM10.9919 22.5708C10.9919 21.8947 10.9929 21.2353 10.9919 20.5769C10.9886 19.3345 10.1161 18.4377 8.8699 18.4138C8.40021 18.4052 7.92907 18.4367 7.45794 18.4381C7.04128 18.4396 6.62318 18.4042 6.20796 18.4219C5.17395 18.4639 4.33632 19.207 4.23646 20.2328C4.16718 20.9395 4.20588 21.6572 4.19728 22.3706C4.19632 22.4351 4.20301 22.5001 4.20636 22.5713C6.46932 22.5708 8.71509 22.5708 10.9919 22.5708ZM7.60081 17.7682C8.67495 17.8385 9.85709 16.8953 9.85661 15.5249C9.85613 14.2768 8.84745 13.2724 7.59746 13.2738C6.34939 13.2753 5.32876 14.283 5.33975 15.5287C5.35265 17.0023 6.61888 17.8466 7.60081 17.7682Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81374 2.52977C9.91551 2.57851 10.0221 2.61865 10.1167 2.67742C10.4449 2.88145 10.6141 3.18248 10.6174 3.56761C10.6222 4.14004 10.6184 4.71151 10.6184 5.31405C10.8492 5.31405 11.0695 5.32551 11.2878 5.31261C12.2234 5.2548 12.9903 5.97822 12.9612 6.99168C12.944 7.60425 12.9597 8.2173 12.9573 8.83034C12.954 9.79698 12.3018 10.4511 11.3346 10.453C9.39516 10.4559 7.4552 10.454 5.51572 10.4535C5.17647 10.453 5.07995 10.3599 5.07995 10.0263C5.07947 8.5986 5.07947 7.17039 5.07995 5.74313C5.07995 5.40913 5.17551 5.315 5.51429 5.315C6.22147 5.31405 6.92817 5.3107 7.63534 5.31739C7.77726 5.31883 7.84797 5.27869 7.90053 5.14299C8.12511 4.56004 8.36593 3.98379 8.59386 3.4018C8.74533 3.01333 8.95796 2.68507 9.36841 2.5293C9.51749 2.52977 9.66609 2.52977 9.81374 2.52977ZM7.35582 9.79459C7.39022 9.79889 7.41554 9.80558 7.44087 9.80558C8.76683 9.80606 10.0933 9.81036 11.4192 9.80367C11.9052 9.80128 12.2803 9.43766 12.2903 8.95123C12.3046 8.24453 12.3046 7.53735 12.2912 6.83066C12.2817 6.32082 11.9037 5.96866 11.3934 5.96436C11.0637 5.9615 10.7335 5.96436 10.4038 5.96389C10.0708 5.96341 9.96903 5.8645 9.96855 5.53337C9.96712 4.9208 9.96855 4.30823 9.96712 3.69566C9.96712 3.61587 9.96091 3.53368 9.94275 3.45627C9.905 3.29286 9.78268 3.1863 9.62882 3.19156C9.52991 3.1949 9.4181 3.28521 9.34356 3.36596C9.2738 3.44146 9.24178 3.55327 9.20165 3.65266C8.93407 4.31253 8.67031 4.97336 8.40464 5.63323C8.28805 5.92279 8.22928 5.96389 7.92825 5.96436C7.73664 5.96484 7.54408 5.96436 7.35629 5.96436C7.35582 7.25926 7.35582 8.52263 7.35582 9.79459ZM6.67922 9.79985C6.67922 8.51164 6.67922 7.24302 6.67922 5.97583C6.35717 5.97583 6.05088 5.97583 5.74364 5.97583C5.74364 7.25735 5.74364 8.52549 5.74364 9.79985C6.05996 9.79985 6.36147 9.79985 6.67922 9.79985Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7262 13.7643C12.8457 13.5598 12.948 13.3878 13.0483 13.2138C13.3847 12.6314 13.7302 12.0546 14.0522 11.4641C14.1803 11.2294 14.3494 11.152 14.605 11.1539C15.878 11.1616 17.1504 11.1573 18.4243 11.1573C19.2256 11.1573 20.0269 11.1578 20.8282 11.1568C21.1464 11.1568 21.2511 11.0569 21.2511 10.7349C21.253 8.82217 21.253 6.90897 21.2511 4.99625C21.2511 4.67611 21.145 4.57481 20.8272 4.57433C17.7028 4.57433 14.5769 4.57433 11.4514 4.57338C11.3578 4.57338 11.2622 4.57338 11.17 4.55665C11.0109 4.52798 10.922 4.41904 10.912 4.26279C10.9019 4.10463 10.9822 3.98948 11.1351 3.94217C11.2101 3.91924 11.2933 3.91398 11.3731 3.9135C14.5391 3.91255 17.7047 3.91207 20.8698 3.9135C21.4862 3.9135 21.8961 4.32156 21.8961 4.93795C21.8985 6.89129 21.8985 8.84463 21.8961 10.7984C21.8961 11.4125 21.4838 11.8191 20.8674 11.82C18.8467 11.8205 16.826 11.8215 14.8057 11.8167C14.6595 11.8167 14.5783 11.8578 14.5052 11.9868C14.0345 12.8182 13.5548 13.6444 13.077 14.471C12.8548 14.8556 12.5939 14.8576 12.3731 14.4772C11.8982 13.6563 11.4199 12.8373 10.955 12.0112C10.8694 11.8587 10.7753 11.8162 10.6076 11.8167C8.61363 11.8224 6.61967 11.8205 4.6262 11.8205C3.94005 11.8205 3.54966 11.4316 3.54919 10.7454C3.54871 8.82552 3.54871 6.90611 3.54919 4.98669C3.54919 4.30149 3.94052 3.91398 4.62907 3.91398C5.57181 3.9135 6.51503 3.9135 7.45777 3.91398C7.52515 3.91398 7.59348 3.91111 7.65894 3.92258C7.83239 3.95316 7.9614 4.10176 7.94468 4.2604C7.927 4.43433 7.82761 4.54375 7.64938 4.56716C7.57675 4.57672 7.50173 4.57576 7.42767 4.57576C6.48493 4.57624 5.54218 4.57576 4.59896 4.57672C4.30797 4.57672 4.19711 4.68232 4.19616 4.97714C4.19377 6.90372 4.19377 8.82982 4.19616 10.7564C4.19616 11.0598 4.30606 11.1592 4.61664 11.1592C6.68466 11.1597 8.7522 11.164 10.8202 11.1554C11.1045 11.1535 11.277 11.25 11.4151 11.5008C11.7974 12.1966 12.204 12.8784 12.6006 13.5655C12.635 13.6195 12.6713 13.6749 12.7262 13.7643Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1699 6.61327C16.2807 6.61327 15.3915 6.61327 14.5018 6.61327C14.4349 6.61327 14.367 6.61709 14.3006 6.60849C14.1362 6.58842 14.0173 6.45559 14.0211 6.29217C14.0254 6.11299 14.1229 6.00548 14.2968 5.97107C14.3551 5.95961 14.4177 5.96629 14.4784 5.96629C16.2903 5.96629 18.1026 5.96629 19.9145 5.96629C19.9485 5.96629 19.9814 5.96582 20.0154 5.96677C20.2356 5.97442 20.3675 6.10009 20.3637 6.29838C20.3608 6.48808 20.2328 6.6104 20.0197 6.61136C19.3058 6.6147 18.5919 6.61279 17.8776 6.61279C17.642 6.61327 17.406 6.61327 17.1699 6.61327Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1865 8.18743C16.2509 8.18743 15.3139 8.18934 14.3773 8.18695C14.094 8.186 13.9201 7.92845 14.0682 7.71439C14.1265 7.63029 14.2608 7.55049 14.3621 7.55002C16.2475 7.53998 18.1335 7.54237 20.02 7.5438C20.2311 7.5438 20.3597 7.66756 20.364 7.85725C20.3688 8.05603 20.2369 8.18647 20.0156 8.18695C19.0719 8.18934 18.1302 8.18743 17.1865 8.18743Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7703 9.1184C16.2281 9.1184 16.6863 9.11745 17.1441 9.11936C17.3739 9.11984 17.5268 9.25506 17.5215 9.44714C17.5168 9.63254 17.3696 9.76251 17.1512 9.76299C16.229 9.76442 15.3059 9.7649 14.3842 9.76299C14.161 9.76251 14.0229 9.63636 14.0205 9.44428C14.0181 9.24503 14.1601 9.11936 14.3961 9.11888C14.8539 9.11792 15.3116 9.1184 15.7703 9.1184Z"
        fill="white"
      />
    </svg>
  );
};

export default FeedbacksAndTasksIcon;
