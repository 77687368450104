import React, { useState, useEffect } from "react";
import { Checkbox, Input, Switcher } from "../../ui";
import "./styles.scss";
import DropdownSelect from "../../DropdownSelect/DropdownSelect";
import { DeleteIcon, CloseIcon, AddIcon } from "../../../icons";

const Question = ({ question, handleRemoveQuestion, handleUpdateQuestion }) => {
  const initialChoices = question.type !== 1 ? [""] : [];
  const [currentQuestion, setCurrentQuestion] = useState({
    ...question,
    choices: question.choices || initialChoices,
  });

  const questionTypes = [
    { value: 1, label: "Short answer" },
    { value: 2, label: "Single choice" },
    { value: 3, label: "Multi choice" },
  ];

  const handleSelectType = (event) => {
    setCurrentQuestion((prevQuestion) => ({
      ...prevQuestion,
      type: event,
      choices:
        event === 1
          ? []
          : prevQuestion.choices.length
          ? prevQuestion.choices
          : [""],
    }));
  };

  const handleAddAnswer = (event, choiceIndex) => {
    event.stopPropagation();
    const newValue = event.target.value;
    setCurrentQuestion((prevQuestion) => {
      const newChoices = [...prevQuestion.choices];
      newChoices[choiceIndex] = newValue;
      return { ...prevQuestion, choices: newChoices };
    });
  };

  const handleRemoveAnswer = (index) => {
    setCurrentQuestion((prevQuestion) => ({
      ...prevQuestion,
      choices: prevQuestion.choices.filter((_, i) => i !== index),
    }));
  };

  const handleAddChoice = () => {
    setCurrentQuestion((prevQuestion) => ({
      ...prevQuestion,
      choices: [...prevQuestion.choices, ""],
    }));
  };

  const chosenQuestion = () => {
    if (currentQuestion?.type === 1) {
      return null;
    }

    const RenderTypeChoice = () => {
      switch (currentQuestion?.type) {
        case 2:
          return <Checkbox checked={true} type="radio" />;
        case 3:
          return <Checkbox checked={true} type="checkbox" />;
        default:
          return "";
      }
    };

    return (
      <ul className="answer-list">
        {currentQuestion?.choices?.map((choice, index) => (
          <li key={`choice-${index}`}>
            <RenderTypeChoice />
            <Input
              placeholder="Answer..."
              value={choice}
              handleChange={(event) => handleAddAnswer(event, index)}
            />
            {index !== 0 && (
              <div
                className="remove-answer"
                onClick={() => handleRemoveAnswer(index)}
              >
                <CloseIcon />
              </div>
            )}
          </li>
        ))}
        <div className="add-choice" onClick={handleAddChoice}>
          <AddIcon />
        </div>
      </ul>
    );
  };

  const handleChange = (event, customName) => {
    if (customName) {
      setCurrentQuestion({ ...currentQuestion, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setCurrentQuestion({ ...currentQuestion, [name]: value });
  };

  const handleIsRequired = () => {
    setCurrentQuestion({
      ...currentQuestion,
      isRequired: !currentQuestion.isRequired,
    });
  };

  useEffect(() => {
    handleUpdateQuestion(question.id, currentQuestion);
  }, [currentQuestion]);

  return (
    <div className="question-wrapper">
      <div className="question-header">
        <div className="question-inputs">
          <Input
            placeholder="Question..."
            name="content"
            value={currentQuestion?.content || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="question-dropdown">
          <DropdownSelect
            background="dark"
            options={questionTypes}
            handleSelect={handleSelectType}
            preselected={
              questionTypes?.find(
                (item) => item.value === currentQuestion?.type
              ) || questionTypes[0]
            }
          />
        </div>
      </div>
      <div className="question-answer">{chosenQuestion()}</div>
      <div className="question-footer">
        <label>Required</label>
        <Switcher
          checked={currentQuestion?.isRequired}
          handleChange={handleIsRequired}
        />
        <span onClick={() => handleRemoveQuestion(question.id)}>
          <DeleteIcon />
        </span>
      </div>
    </div>
  );
};

export default Question;
