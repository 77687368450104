import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../store/toastMessages/toastMessagesSlice";

export function useThunk(thunk) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!error) {
      console.log(error);
      dispatch(
        setToastMessage({
          title: error?.code,
          message: error?.message,
          type: "error",
        })
      );
    }
  }, [error]);

  const runThunk = useCallback(
    (arg) => {
      setIsLoading(true);
      dispatch(thunk(arg))
        .unwrap()
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    },
    [dispatch, thunk]
  );

  return [runThunk, isLoading, error];
}
