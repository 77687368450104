import React from "react";
import "./styles.scss";
import { dummyCompensation } from "./dummyData";
import { CircleCheckIcon } from "../../../../icons";
import { TimelineList } from "../../../ui";

const CompensationReview = () => {
  const compensation = dummyCompensation;

  const requirementsLength = compensation?.review?.requirements.length;

  return (
    <div className="compensation-review">
      <div className="current-compensation">
        <div className="title-line">
          <h2 className="title">Compensation review</h2>
        </div>
        <p>Current compensation review requirements</p>
        <ul className="review-sumary">
          <li key={"sumary-head"}>
            <div className="column">Review sender</div>
            <div className="column">Current score</div>
            <div className="column">Required score</div>
            <div className="column">Review deadline</div>
          </li>
          <li key={"sumary-data"}>
            <div className="column">
              <div className="sender">{`From: ${compensation?.review?.sender}`}</div>
              <div>{compensation?.review?.description}</div>
            </div>
            <div className="column">{`${
              compensation?.review?.requirements.filter(
                (req) => req.completed === true
              ).length
            } / ${requirementsLength}`}</div>
            <div className="column">{`${compensation?.review?.requiredScore} / ${compensation?.review?.requirements.length}`}</div>
            <div className="column">{compensation?.review?.dueDate}</div>
          </li>
        </ul>
        <ul className="requirements">
          {compensation?.review?.requirements?.map((req, index) => (
            <li key={`req-${index}`}>
              <div className="requirement-info">
                <p className="requirement-title">{req?.title}</p>
                <p>{req?.description}</p>
              </div>
              <div className={req?.completed ? "finished" : "unfinished"}>
                <CircleCheckIcon />
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="compensation-history">
        <div className="title-line">
          <h2 className="title"> Bonus and salary raise dates</h2>
          <p>{`Current salary: ${compensation?.currentSalary} since ${compensation?.salaryEffectiveDate}`}</p>
        </div>
        <TimelineList
          data={compensation?.raiseDates.map(
            (raise) => `${raise?.event} ${raise?.date}`
          )}
        />
      </div>
    </div>
  );
};

export default CompensationReview;
