import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDailyPulse,
  fetchDailyPulseSingle,
  createDailyPulse,
} from "./dailypulseThunks";

import moment from "moment";
const today = moment().format("DD.MM.YY");

const dailypulseSlice = createSlice({
  name: "dailypulse",
  initialState: {
    data: [],
    usersDaily: [],
    singleUserData: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetDailyPulseMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDailyPulse.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchDailyPulseSingle.fulfilled, (state, action) => {
      state.singleUserData = action.payload;
    });

    builder.addCase(createDailyPulse.fulfilled, (state, action) => {
      state.usersDaily = [...state.usersDaily, action.payload];
      state.singleUserData = {
        ...state.singleUserData,
        [today]: {
          grades: [
            { grade: action.meta.arg.grade, user_id: action.meta.arg.user_id },
          ],
        },
      };

      state.message = {
        type: "success",
        title: "Success",
        message: "Daily pulse checked",
      };
    });
  },
});

export const dailypulseReducer = dailypulseSlice.reducer;
