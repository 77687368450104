export const dummyTimeline = [
  {
    date: "05.03.2024",
    title: "3 months",
    description: "1st performance review",
    status: 2,
  },
  {
    date: "05.06.2024",
    title: "6 months",
    description: "2nd performance review",
    status: 2,
  },
  {
    date: "05.09.2024",
    title: "9 months",
    description: "3rd performance review",
    status: 4,
  },
  {
    date: "05.12.2024",
    title: "12 months",
    description: "End of the year",
    status: 3,
  },
];

export const dummyReviewTimelineList = [
  "Upcoming performance review Dec. 2024",
  "Overdue performance review Sep. 2024",
  "Completed performance review Jun. 2024",
  "Completed performance review Mar. 2024",
  "Completed performance review Dec. 2023",
  "Completed performance review Sep. 2023",
  "Completed performance review Jun. 2023",
  "Completed performance review Mar. 2023",
];

export const dummyTimeOnPosition = [
  "Junior Designer 01.21.2020",
  "Medior Designer 01.21.2021",
  "Senior Designer 01.21.2022",
  "Designer Director 01.21.2024",
];
