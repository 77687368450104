import "./styles.scss";

const ShortAnswers = ({ question }) => {
  return (
    <div className="survey-overview-question short-question">
      <div className="survey-overview-question-content">
        <h3>
          {question?.content}
          <span>{question?.answers?.length} responses</span>
        </h3>
        {question?.answers?.length > 0 ? (
          <ul className="answers-list">
            {question?.answers?.map((answer) => (
              <li>{answer?.response}</li>
            ))}
          </ul>
        ) : (
          <div className="no-answers">No answers</div>
        )}
        {question?.isRequired ? (
          <div className="survey-overview-required">
            <span>Required</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ShortAnswers;
