import "./Reports.scss";
import { Outlet } from "react-router-dom";

const Reports = () => {
  return (
    <div className="reports-wrapper">
      <div className="module-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Reports;
