import moment from "moment";

export const shortenText = (str, maxLen) => {
  if (str.length <= maxLen) {
    return str;
  } else {
    return str.substring(0, maxLen) + "...";
  }
};

export const subMonths = (date, months) => {
  return new Date(date.setMonth(date.getMonth() - months));
};

export const addMonths = (date, months) => {
  return new Date(date.setMonth(date.getMonth() + months));
};

export const formatShortDate = (date) => {
  return moment(date).format("DD.MM.YYYY");
};
export const convertTextToDate = (date, dateFormat = "DD.MM.YYYY") => {
  let convertedDate = new Date();
  if (!!date) {
    convertedDate = moment(date, dateFormat).toDate();
  }
  return convertedDate;
};
export const removeUnderScore = (str) => {
  return (
    str.replace(/_/g, " ").charAt(0).toUpperCase() +
    str.replace(/_/g, " ").slice(1)
  );
};

export const getFirstAndLastWorkingDaysThisWeek = () => {
  let today = moment();
  let startOfWeek = moment().startOf("week").add(1, "days");
  let endOfWeek = moment().endOf("week").subtract(1, "days");

  let days = [];
  for (let i = 0; i < 5; i++) {
    days.push(
      moment()
        .startOf("week")
        .add(i + 1, "days")
    );
  }

  let workingWeekDays = days.map((day) => ({
    dayName: day.format("dd").charAt(0),
    dateDay: day.format("DD.MM.YY"),
  }));

  if (today.isoWeekday() === 6 || today.isoWeekday() === 7) {
    startOfWeek = moment().subtract(1, "week").startOf("week").add(1, "days");
    endOfWeek = moment().subtract(1, "week").endOf("week").subtract(1, "days");
  }

  let firstDay = today.isBefore(startOfWeek)
    ? null
    : startOfWeek.format("DD.MM.YYYY");

  let lastDay = today.isAfter(endOfWeek)
    ? today.format("DD.MM.YYYY")
    : endOfWeek.format("DD.MM.YYYY");

  return [firstDay, lastDay, workingWeekDays];
};
