import { Handle, Position } from "reactflow";
import { QuestionIcon } from "../../../../icons";

function CustomNode({ data, isConnectable }) {
  return (
    <div className="text-updater-node">
      <Handle
        type="source"
        position={Position.Top}
        id="a"
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
      <div className="custom-block">
        {data?.position?.title} <QuestionIcon />
      </div>
    </div>
  );
}

export default CustomNode;
