import { createSlice } from "@reduxjs/toolkit";
import { fetchCareer } from "./careerThunks";

const careerSlice = createSlice({
  name: "career",
  initialState: {
    employees: [],
  },
  extraReducers(builder) {
    builder.addCase(fetchCareer.fulfilled, (state, action) => {
      state.employees = action.payload;
    });
  },
});

export const careerReducer = careerSlice.reducer;
