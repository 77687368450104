import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchMatrices = createAsyncThunk("matrices/fetch", async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/career_path`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchMatrixByPosition = createAsyncThunk(
  "matrices/fetch/position",
  async (payload) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/career_path/position/${payload.positionId}/user/${payload.userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createMatrix = createAsyncThunk(
  "matrices/create",
  async (matrices) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/career_path/`,
        matrices
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateMatrix = createAsyncThunk(
  "matrices/update",
  async (matrix) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/career_path/${matrix.id}`,
        matrix
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteMatrix = createAsyncThunk("matrices/delete", async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/career_path/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const startCareerGrowth = createAsyncThunk(
  "matrices/create/path",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/${payload.userId}/start-career-path/${payload.careerPathId}`,
        {
          active_position: payload.positionId,
          settings: payload.settings,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const resetCareerGrowth = createAsyncThunk(
  "matrices/reset/path",
  async (userId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/user/${userId}/reset-career-path`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
