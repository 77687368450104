const ArrowIcon = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.29004 0.310547C8.17936 0.421224 8.12402 0.554036 8.12402 0.708984C8.12402 0.863933 8.17936 0.996745 8.29004 1.10742L11.5938 4.39453H1.08496C0.918945 4.39453 0.780599 4.44987 0.669922 4.56055C0.559245 4.67122 0.503906 4.80404 0.503906 4.95898C0.503906 5.11393 0.559245 5.24674 0.669922 5.35742C0.780599 5.4681 0.918945 5.52344 1.08496 5.52344H11.5938L8.29004 8.81055C8.17936 8.92122 8.12402 9.05404 8.12402 9.20898C8.12402 9.36393 8.17936 9.49674 8.29004 9.60742C8.40072 9.7181 8.53353 9.77344 8.68848 9.77344C8.84342 9.77344 8.97624 9.7181 9.08691 9.60742L13.3701 5.35742C13.4255 5.30208 13.4697 5.24121 13.5029 5.1748C13.5361 5.1084 13.5527 5.03646 13.5527 4.95898C13.5527 4.88151 13.5361 4.80957 13.5029 4.74316C13.4697 4.67676 13.4255 4.61589 13.3701 4.56055L9.08691 0.310547C8.97624 0.19987 8.84342 0.144531 8.68848 0.144531C8.53353 0.144531 8.40072 0.19987 8.29004 0.310547Z"
      fill="white"
    />
  </svg>
);
export default ArrowIcon;
